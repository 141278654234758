import { ContentTypeRouteMap } from '@/config/ContentType';

const contentTypeRouteMapValues = Object.values(ContentTypeRouteMap) as string[];

export const stripLinkHref = (linkHref: string) => {
  if (!linkHref) return;
  if (linkHref?.[0] === '/') {
    linkHref = linkHref.slice(1);
  }
  if (linkHref.startsWith('http')) return linkHref;

  const firstSlashIndex = linkHref.indexOf('/');
  if (firstSlashIndex !== 0 && contentTypeRouteMapValues.includes(linkHref.slice(0, firstSlashIndex)))
    return linkHref.slice(linkHref.indexOf('/') + 1);

  return linkHref;
};

// NOTE: `to`: has to be used with / if its internal eg.: `to: /k/some/path`
export const normalizeTo = (to: string) => (to.length > 1 ? '/' + to : to);

export const subcategoryRedirectUrl = (childSlugPath: string, parentSlug: string) => {
  const slugArr = childSlugPath.split('/');
  const parentSlugIndex = slugArr.indexOf(parentSlug);

  if (parentSlugIndex === -1) {
    throw new Error('This is not a parent slug');
  }

  if (parentSlugIndex + 1 === slugArr.length) {
    throw new Error('This is the same page');
  }

  const paths = [];
  for (let i = parentSlugIndex + 1; i < slugArr.length; i++) {
    paths.push(slugArr.slice(0, i + 1).join('/'));
  }
  // TODO: Use generateLinkHref when extracted to separate file
  return `/${ContentTypeRouteMap.CATEGORY}/${slugArr.slice(0, parentSlugIndex + 1).join('/')}?paths=${paths.join(',')}`;
};

export const replaceUrl = (url: string) => {
  return `/${ContentTypeRouteMap.REPLACE}/${url.startsWith('/') ? url.slice(1) : url}`;
};
