import { ArticleResponse, HasPermissions, PageResponse, StructureResponse } from '@/model/response';
import { ArticleModel } from '@/model/page/Article';
import { GenericAssetContent, GenericContent } from '@/model/page/Content';
import { commonFetch, learningPathDetailsFetch } from '@/utils/commonFetch';
import { mapContent } from '@/mapper/Content';
import usePermissions from '@/services/usePermissions';
import { LearningPathResponse } from '@/model/response/LearningPath';
import { ResponseErrorStatusCode } from '@/services/useError';
import { mapContentToText } from '@/services/useContent/mapContentToText';

interface UseContent {
  getTextContent: (article: ArticleModel) => string;
  resolveContent: <T = GenericAssetContent>(
    contentId: string,
    language: string,
    includeChildren?: boolean,
  ) => Promise<T>;
  mapResponseContent: (
    response: StructureResponse<PageResponse | ArticleResponse>,
    extraInfo: StructureResponse<LearningPathResponse>,
  ) => GenericContent;
}

const setup = (): UseContent => {
  const { checkPermissions } = usePermissions();

  const getTextContent = (article: ArticleModel): string =>
    article && article.children && article.children.length
      ? article.children
          .map((child) =>
            child && child.contentType && child.content && child.content
              ? mapContentToText(child.contentType, child.content)
              : '',
          )
          .join(' ')
      : '';

  const mapResponseContent = (
    response: StructureResponse<PageResponse | ArticleResponse>,
    extraInfo?: StructureResponse<PageResponse>,
  ) => {
    const structure = response?.structure;
    return mapContent(
      {
        ...structure?.data,
        ...(structure?.data.children
          ? {
              children: {
                ...structure?.data.children,
                data: structure?.data?.children?.data.filter(checkPermissions),
              },
            }
          : {}),
      },
      extraInfo as unknown as PageResponse,
    );
  };

  const fetchExtraData = (originalResponse, language): Promise<StructureResponse<PageResponse>> => {
    if (
      originalResponse.structure.data?.contentType === 'learning-path' &&
      originalResponse.structure?.data?.content?.data?.content?.learningPath?.id
    ) {
      return learningPathDetailsFetch(originalResponse.structure.data.content.data.content.learningPath.id, language);
    }
    return undefined;
  };

  const resolveContent = async <T = GenericAssetContent>(
    contentId: string,
    language: string,
    includeChildren = true,
  ): Promise<T> => {
    let genericContent: GenericAssetContent;

    try {
      const response = await commonFetch<PageResponse>(contentId, language, includeChildren);
      const additionalInfo = await fetchExtraData(response, language);

      if (response.structure.data) {
        if (!checkPermissions(response.structure.data as HasPermissions)) {
          genericContent = {
            contentType: 'error',
            id: ResponseErrorStatusCode.UNAUTHORIZED,
          };
        } else {
          genericContent = mapResponseContent(response, additionalInfo);
        }
      } else if (response.structure.error) {
        genericContent = {
          contentType: 'error',
          id: response.structure.error.status as unknown as string,
        };
      }
    } catch (error) {
      console.error(error);
      genericContent = {
        contentType: 'error',
        id: ResponseErrorStatusCode.INTERNAL_SERVER_ERROR,
      };
    }

    return genericContent as T;
  };

  return {
    getTextContent,
    resolveContent,
    mapResponseContent,
  };
};

export default setup;
