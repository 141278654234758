<template>
  <div
    v-text-direction:language="language"
    v-katex
    :class="['c-rich-text', `c-rich-text--${version}`]"
    v-html="parsedRichTextHTML"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CommonContentBlockInterface } from '@/model/component/general';

interface RichTextProps extends CommonContentBlockInterface {
  content: string;
  version?: RichTextVersion;
  language?: string;
}
const props = defineProps<RichTextProps>();

const selectorMap = {
  '<table>': '<div class="table-wrapper"><table>',
  '</table>': '</table></div>',
};

const selectorRegex = new RegExp(Object.keys(selectorMap).join('|'), 'gi');

const parsedRichTextHTML = computed(() => props.content?.replace(selectorRegex, (selector) => selectorMap[selector]));
</script>

<script lang="ts">
export enum RichTextVersion {
  CAPTION = 'caption',
  COPYRIGHT = 'copyright',
  SEARCH_ITEM = 'search-item',
  CARD = 'card',
  CARD_SIMPLE = 'card-simple',
  CARD_SEARCH = 'card-search',
  CHECKLIST = 'checklist',
  FACT_BOX = 'fact-box',
  HOME = 'home',
  SECTION = 'section',
}
</script>
