import { ref, Ref, unref } from 'vue';
import usePage from '@/services/usePage';
import { LearningPathProgress } from '@/model/Progression';
import useLRS from '@/services/useLRS';
import { query as lpQuery } from '@/queries/lrs/LearningPaths.query.lrs';
import { query } from '@/queries/lrs/General.query.lrs';
import { prepareLearningPathScore } from '@/utils/prepareLearningPathScore';
import { ResultScore } from '@xapi/xapi';
import { BasicPage } from '@/model/page/BasicPage';
import { getLearningPathId } from '@/queries/lrs/common';
import usePermissions from '@/services/usePermissions';
import { PageResponse } from '@/model/response';

export interface ProgressionState {
  id: string;
  state: LearningPathProgress;
}

export interface ProgressTracking {
  init: (learningPathId: string, learningPathUri: string) => Promise<void>;
  subscribe: () => Ref<ProgressionState[]>;
  emit: (progress: ProgressionState) => void;
  getScore: (contentId: string) => Promise<ResultScore>;
}

const { currentContent, currentLearningPath } = usePage();
const state = ref([]);
const learningPathId = ref();
const learningPathUri = ref();

const setup = (): ProgressTracking => {
  const { saveStatement, fetchStatements } = useLRS();
  const { isStudent } = usePermissions();

  const fetchState = async (uri: string) => {
    if (isStudent()) {
      const lrsState = await fetchStatements(uri);

      if (lrsState && lrsState.length) {
        return JSON.parse(lrsState[0].result.response).state;
      }
    }
    return currentLearningPath.value.children.map(({ id }) => ({ id, state: LearningPathProgress.NONE }));
  };

  const saveState = async (progress: ProgressionState) => {
    if (
      learningPathId.value === currentLearningPath.value.id && // Currently we are storing only information about content that is a part of learningPath
      isStudent() &&
      progress.id === currentContent.value.id
    ) {
      const currentContentSlugPath = (
        currentLearningPath.value.children.find((child) => child.id === progress.id) as PageResponse
      ).slugPath;

      await saveStatement(
        query({
          contentNamespaceId: (currentContent.value as BasicPage).namespaceId,
          // NOTE: Key is not present in standard page response
          contentSlugPath: currentContentSlugPath,
          contentId: currentContent.value.id,
          contentTitle: (currentContent.value as BasicPage).title,
          contentType: currentContent.value.contentType,
          learningPath: {
            id: currentLearningPath.value.contentId,
            title: currentLearningPath.value.title,
            structureId: currentLearningPath.value.id,
            structureNamespaceId: (currentLearningPath.value as unknown as BasicPage).namespaceId,
            structureSlugPath: currentLearningPath.value.slugPath,
          },
          verbId: progress.state === LearningPathProgress.FINISHED ? 'completed' : 'progressed',
        }),
      );
    }

    if (learningPathId.value === currentLearningPath.value.id && isStudent()) {
      const score = prepareLearningPathScore(state.value, currentLearningPath.value);
      const done = score.max === score.raw;

      await saveStatement(
        lpQuery({
          learningPath: {
            id: currentLearningPath.value.contentId,
            title: currentLearningPath.value.title,
            structureId: currentLearningPath.value.id,
            structureNamespaceId: (currentLearningPath.value as unknown as BasicPage).namespaceId,
            structureSlugPath: currentLearningPath.value.slugPath,
          },
          done,
          passed: done,
          selectedData: { state: unref(state), id: unref(learningPathId) },
          score,
          verbId: done ? 'completed' : 'progressed',
        }),
      );
    }
  };

  const init = async (id: string, contentId: string) => {
    if (!isStudent()) return;

    if (id && id !== learningPathId.value) {
      learningPathUri.value = getLearningPathId(contentId);
      learningPathId.value = id;
    }

    if (learningPathId.value === currentLearningPath.value.id) {
      await fetchState(learningPathUri.value).then((newState) => {
        state.value = newState;
      });
    }
  };

  const subscribe = () => state;

  const emit = async (progress: ProgressionState): Promise<void> => {
    if (
      currentLearningPath.value?.id === learningPathId.value &&
      currentLearningPath.value?.children?.some((item) => item.id === progress.id) &&
      state.value &&
      isStudent()
    ) {
      state.value = [...state.value.filter((oldProgress) => oldProgress.id !== progress.id), progress];
      return saveState(progress);
    }
  };

  const getScore = async (contentId: string) => {
    if (!isStudent()) return;

    const lrsState = await fetchStatements(getLearningPathId(contentId));
    if (lrsState && lrsState.length) {
      return lrsState[0].result.score;
    }

    throw new Error('No state provided');
  };

  return { emit, getScore, init, subscribe };
};

export default setup;
