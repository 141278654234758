import { ObjectiveResponseItem, ProjectResponse } from '@/model/response/Project';
import { ProjectModel } from '@/model/page/Project';
import { mapPage } from '@/mapper/Page';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapProject = (pageResponse: ProjectResponse): ProjectModel => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.content?.data?.content, response.content?.data?.localization);

  return {
    ...mapPage(pageResponse),
    metadataContentImage: {
      altText: content?.cardImage?.altText,
      assetId: content?.cardImage?.imageId,
      copyright: content?.cardImage?.copyright,
    },
    curriculumObjectives: getCurriculumObjectives(content),
  };
};

const getCurriculumObjectives = (content) => {
  const curriculumObjectives = content?.curriculum?.objectives;

  return [
    ...curriculumObjectives.map((objective) => ({
      id: objective._id,
      teacherOnly: objective.teacherOnly,
      title: objective.title,
      items: formatObjectiveItems(objective.items),
    })),
  ];
};

const formatObjectiveItems = (items: ObjectiveResponseItem[]) => {
  return [
    ...items.map((item) => ({
      id: item._id,
      contentId: item._id,
      contentType: item._type,
      content: {
        assetId: item.imageId,
        altText: item.altText,
        caption: item.caption,
        copyright: item.copyright,
        content: item.content,
      },
    })),
  ];
};
