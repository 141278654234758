<template>
  <BaseLabel :icon="icon" :color="iconColor" :title="label" :variant="variant">{{ label }}</BaseLabel>
</template>

<script lang="ts">
export enum CardLabelVariant {
  SMALLER_GAP = 'smaller-gap',
  DEFAULT = 'default',
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import ArticleSimpleIcon from '@/assets/icons/ArticleSimpleIcon.vue';
import ConceptSimpleIcon from '@/assets/icons/ConceptSimpleIcon.vue';
import TaskSimpleIcon from '@/assets/icons/TaskSimpleIcon.vue';
import DigitalTestSimpleIcon from '@/assets/icons/DigitalTestSimpleIcon.vue';
import DocumentSimpleIcon from '@/assets/icons/DocumentSimpleIcon.vue';
import ProjectSimpleIcon from '@/assets/icons/ProjectSimpleIcon.vue';
import MultimediaSimpleIcon from '@/assets/icons/MultimediaSimpleIcon.vue';
import ExternalLinkSimpleIcon from '@/assets/icons/ExternalLinkSimpleIcon.vue';
import BaseLabel from '@/elements/label/BaseLabel.vue';
import PathIcon from '@/assets/icons/PathIcon.vue';

interface CardLabelProps {
  contentType: string | boolean;
  categoryKeyword?: string;
  iconColor?: string;
  showIcon?: boolean;
  showLabel?: boolean;
  label: string;
  variant?: CardLabelVariant;
}

const props = withDefaults(defineProps<CardLabelProps>(), {
  categoryKeyword: 'default',
  showIcon: true,
  showLabel: true,
  variant: CardLabelVariant.DEFAULT,
});

const mapping = {
  article: ArticleSimpleIcon,
  concept: ConceptSimpleIcon,
  task: TaskSimpleIcon,
  test: DigitalTestSimpleIcon,
  document: DocumentSimpleIcon,
  project: ProjectSimpleIcon,
  'ct-vgs-multimedia': MultimediaSimpleIcon,
  externalLink: ExternalLinkSimpleIcon,
  'learning-path': PathIcon,
  'learning-path-advance': PathIcon,
};

const icon = computed(() => (props.contentType ? mapping[props.contentType as string] : mapping['default']));
</script>
