import { NamespaceFilters } from '@/model/response';
import { Filter } from '@/model/filter/Filter';
import { initFilterOptions } from '@/mapper/namespace/InitFilterOptions';

export const initFiltersObject = (filters: NamespaceFilters): Filter[] => {
  return (
    filters?.filterGroups.map((filterGroup) => {
      const groupId = filterGroup.groupId;
      const name = filterGroup.name;
      const list = initFilterOptions(filters.list.filter((filter) => filter.groupId === groupId) || []);

      return { groupId, name, list };
    }) || []
  );
};
