import { GoBackHandler } from '@/services/useCustomRouter/model';
import { ContentTypeRouteMap } from '@/config/ContentType';
import { getCurrentRoute } from '@/services/useCustomRouter/handlers/historyTools';

export const wasLearningPathHandler: GoBackHandler = {
  isSupported: (context) => {
    const lastHistoryItem = getCurrentRoute(context)?.path;

    return lastHistoryItem?.includes(`/${ContentTypeRouteMap['LEARNING-PATH']}/`);
  },
  handle: async (context) => {
    context.router.back();
  },
  order: 400,
  name: 'wasLearningPathHandler',
};
