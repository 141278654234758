<template>
  <div
    :class="[
      'c-learning-path-header',
      `c-learning-path-header--${variant}`,
      { 'c-learning-path-header--teacher-version': isInTeacherMode && elements },
    ]"
  >
    <div class="c-learning-path-header__text-wrapper" @click="toggleMenuDropdown">
      <div class="c-learning-path-header__metainfo">
        <LearningPathHeaderImage v-if="image" v-bind="image" />
        <div class="c-learning-path-header__text">
          <div class="c-learning-path-header__title">{{ title }}</div>
          <div class="c-learning-path-header__tasks">
            {{ $t('LEARNING_PATH.SHOW_CONTENT') }}
          </div>
        </div>
      </div>
      <ArrowIcon
        :class="[
          'c-learning-path-header__dropdown-icon',
          { 'c-learning-path-header__dropdown-icon--open': isLearningPathMenuOpen },
        ]"
      />
    </div>
    <template v-if="isInTeacherMode && elements">
      <StepsFraction
        class="c-learning-path-header__steps-fraction"
        :numerator="currentStep"
        :denominator="elements.length"
      />
    </template>
    <template v-if="elements.length && showElements && !isInTeacherMode">
      <LearningPathHeaderElements :elements="elements" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import LearningPathHeaderElements, {
  ElementStatus,
} from '@/components/learning-path-header/LearningPathHeaderElements.vue';
import LearningPathHeaderImage from '@/components/learning-path-header/LearningPathHeaderImage.vue';
import StepsFraction from '@/components/header-steps/StepsFraction.vue';
import { computed } from 'vue';
import { LearningPathElementStatus } from '@/model/component/LearningPathElementStatus';
import useUserMode, { UserModeType } from '@/services/useUserMode';
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';
import useLearningPathMenu from '@/services/useLearningPathMenu';

interface LearningPathHeaderProps {
  title?: string;
  image?: {
    src: string;
    alt: string;
  };
  elements?: ElementStatus[];
  showElements?: boolean;
  variant?: string;
  progress?: {
    max: number;
  };
}

const props = defineProps<LearningPathHeaderProps>();

const { mode } = useUserMode();
const isInTeacherMode = computed(() => mode.value === UserModeType.TEACHER);
const { toggleMenuDropdown, isLearningPathMenuOpen } = useLearningPathMenu();

const currentStep = computed(
  () =>
    props.elements?.findIndex((element) =>
      [LearningPathElementStatus.CURRENT, LearningPathElementStatus.CURRENT_DONE].includes(element.status),
    ) + 1,
);
</script>
