export interface UseScrollTo {
  createScrollIntoView: (selector: string) => VoidFunction;
  scrollToMain: VoidFunction;
}

const SCROLL_SETTINGS: ScrollToOptions = { top: 0, left: 0, behavior: 'smooth' };

const createScrollIntoView = (id: string) => () => {
  const elementScrollHeight = document.getElementById(id).getBoundingClientRect().top;
  const headerHeight = document.querySelector('header.c-header').scrollHeight;
  window.scroll({
    ...SCROLL_SETTINGS,
    top: window.scrollY + elementScrollHeight - headerHeight,
  });
};

const scrollToMain = () => {
  const target = document.getElementById('main-content');
  target.focus();
  window.scroll(SCROLL_SETTINGS);
};

export default (): UseScrollTo => ({ createScrollIntoView, scrollToMain });
