import { TextToSpeechContext, TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';
import { ChildContentType } from '@/constants/ChildContentType';
import { concatContent } from '@/services/useContent/helper/concatContent';
import { ChecklistModel } from '@/model/content';

class ChecklistMapper implements TextToSpeechMapper {
  mapContent(content: ChecklistModel, context: TextToSpeechContext) {
    return concatContent([
      context.translate('CONTENT_TYPE.CHECKBOX'),
      content?.heading,
      ...(content?.checklistItems
        ? content.checklistItems.map((item) => context.mapContentToText(ChildContentType.TEXT, item))
        : []),
    ]);
  }
}

export const checklistMapper = new ChecklistMapper();
