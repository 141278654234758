<template>
  <div class="c-search-field">
    <SearchFieldBar v-model="search" class="c-search-field__bar" />
    <MagnifierWithBackgroundIcon class="c-search-field__button" data-test="search-open-button" @click="openModal" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import SearchFieldBar from '@/components/search/field/SearchFieldBar.vue';
import MagnifierWithBackgroundIcon from '@/assets/icons/MagnifierWithBackgroundIcon.vue';

interface SearchFieldMainProps {
  filter?: boolean;
}

defineProps<SearchFieldMainProps>();
const emit = defineEmits(['update', 'openModal']);

const search = ref('');

const openModal = () => emit('openModal');
const onSearchChange = () => emit('update', search.value);

watch(search, onSearchChange);
</script>
