import {ref} from 'vue'
import {jwtDecode} from 'jwt-decode'

import auth from './auth'

const rolesHierarchy = [
  'internal',
  'admin',
  'organization',
  'institution-manager',
  'institution-owner',
  'institution',
  'content-manager',
  'teacher',
  'student',
  'anonymous'
]
const teacherRoles = rolesHierarchy.slice(0, 8);

const user = ref(null)
const userRole = ref(null)
const highestUserRole = ref(null);
const token = ref(null)
const instance = () => auth
const logout = () => {
  const redirectUrl = `${auth.logoutUrl}&token=${encodeURIComponent(auth.token)}`

  auth.logout(redirectUrl)
}
const login = () => {
  auth.authorize({
    'redirectUrl': window.location.origin
  })
}
const setToken = () => {
  token.value = auth.token
}

const findHighestRole = (decodedToken) => {
  return rolesHierarchy.find(role => decodedToken.roles.includes(role)) || 'anonymous';
}

const setUser = () => {
  const token = localStorage.getItem('token')

  user.value = auth.getUser()
  if (token) {
    const decodedToken = jwtDecode(token);
    highestUserRole.value = findHighestRole(decodedToken);
    const isTeacher = teacherRoles.some(tr => tr === highestUserRole.value)
    userRole.value = isTeacher ? 'teacher' : 'student'
    setUserRoleAttrToBody(userRole.value)
  }
}

const setUserRoleAttrToBody = role => {
  document.body.dataset.userRole = role
}

export default () => {
  return {
    user,
    token,
    userRole,
    highestUserRole,
    setUser,
    setToken,
    login,
    logout,
    instance,
    setUserRoleAttrToBody,
    rolesHierarchy
  }
}
