import { GoBackHandler } from '@/services/useCustomRouter/model';
import { PageName } from '@/constants/PageName';
import { ContentTypeRouteMap } from '@/config/ContentType';
import { getCurrentRoute, getPreviousRoute, matchRouteByName } from '@/services/useCustomRouter/handlers/historyTools';

export const fromErrorToLearningPathHandler: GoBackHandler = {
  isSupported: (context) => {
    const lastHistoryItem = getPreviousRoute(context)?.path;

    return (
      matchRouteByName(getCurrentRoute(context), PageName.ERROR) &&
      lastHistoryItem?.includes(`/${ContentTypeRouteMap['LEARNING-PATH']}/`) &&
      !!lastHistoryItem?.match(/\/\d+$/)?.length
    );
  },
  handle: async (context) => {
    const lastHistoryItem = getPreviousRoute(context)?.path;

    await context.router.push(lastHistoryItem.replace(/\/\d+$/, ''));
  },
  order: 200,
  name: 'fromErrorToLearningPathHandler',
};
