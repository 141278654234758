import { ref, Ref } from 'vue';
import usePermissions from '@/services/usePermissions';

const { isTeacher } = usePermissions();

export const UserModeType = {
  STUDENT: 'student',
  TEACHER: 'teacher',
} as const;

export type UserModeTypeUnion = (typeof UserModeType)[keyof typeof UserModeType];

export interface UserMode {
  mode: Ref<UserModeTypeUnion>;
  saveMode: (newMode: UserModeTypeUnion) => void;
  init: () => void;
}

export const USER_MODE_KEY = 'userMode';
const isValidUserMode = (userModeLike: string) => (Object.values(UserModeType) as string[]).includes(userModeLike);

const resolveUserMode = () => {
  const storedUserMode = localStorage.getItem(USER_MODE_KEY) as UserModeTypeUnion;
  return isValidUserMode(storedUserMode) ? storedUserMode : isTeacher() ? UserModeType.TEACHER : UserModeType.STUDENT;
};

const mode = ref<UserModeTypeUnion>(resolveUserMode());

const saveMode = (newMode: UserModeTypeUnion) => {
  localStorage.setItem(USER_MODE_KEY, newMode);
};

const init = () => {
  mode.value = resolveUserMode();
};

const setup = (): UserMode => ({
  mode,
  saveMode,
  init,
});

export default setup;
