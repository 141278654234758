<template>
  <div v-if="props.elements?.length" class="c-learning-path-header-elements">
    <BaseButton
      v-if="!hideLeftButton"
      :class="[
        'c-learning-path-header-elements__button',
        { 'c-learning-path-header-elements__button--disabled': hideLeftButton },
      ]"
      @click="slideLeft"
    >
      <ArrowIcon class="c-learning-path-header-elements__icon" />
    </BaseButton>
    <div
      ref="mask"
      :class="[
        'c-learning-path-header-elements__list-mask',
        { 'c-learning-path-header-elements__list-mask--left': !hideLeftButton && hideRightButton },
        { 'c-learning-path-header-elements__list-mask--right': !hideRightButton && hideLeftButton },
        { 'c-learning-path-header-elements__list-mask--left-right': !hideLeftButton && !hideRightButton },
      ]"
    >
      <ul ref="list" :class="['c-learning-path-header-elements__list-container']">
        <li v-for="element in elements" :key="element.index" class="c-learning-path-header-wrapper">
          <StepDot
            secondary
            :active="isElementCurrent(element)"
            :show-deco="isElementDone(element)"
            :done="isElementDone(element)"
          >
            <RouterLink class="c-learning-path-header-element__link" :to="element.to" replace>
              {{ element.index }}
            </RouterLink>
          </StepDot>
        </li>
        <li v-if="!showFinalPage" class="c-learning-path-header-wrapper c-learning-path-header-wrapper--last">
          <StepDot
            :class="[
              'c-learning-path-header-element',
              'c-learning-path-header-element--last',
              {
                'c-learning-path-header-element--last-done': elements.every(isElementDone),
              },
            ]"
          >
            <RouterLink class="c-learning-path-header-element__link" :to="{ name: PageName.LEARNING_LAST }" replace>
              <FlagIcon class="c-learning-path-header-element__flag" />
            </RouterLink>
          </StepDot>
        </li>
      </ul>
    </div>
    <BaseButton
      v-if="!hideRightButton"
      :class="[
        'c-learning-path-header-elements__button',
        { 'c-learning-path-header-elements__button--disabled': hideRightButton },
      ]"
      @click="slideRight"
    >
      <ArrowIcon class="c-learning-path-header-elements__icon c-learning-path-header-elements__icon--right" />
    </BaseButton>
  </div>
</template>

<script lang="ts">
export interface ElementStatus {
  status: LearningPathElementStatus;
  index: number;
  to: string;
}
</script>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { LearningPathElementStatus } from '@/model/component/LearningPathElementStatus';
import ArrowIcon from '@/assets/icons/LeftArrowIcon.vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import FlagIcon from '@/assets/icons/FlagIcon.vue';
import { PageName } from '@/constants/PageName';
import Slider from '@/utils/slider';
import usePermissions from '@/services/usePermissions';
import StepDot from '@/elements/step-dot/StepDot.vue';

interface LearningPathHeaderElementsProps {
  elements: ElementStatus[];
}

const props = defineProps<LearningPathHeaderElementsProps>();

const { isTeacher } = usePermissions();

const list = ref<HTMLUListElement>();
const slider = ref(null);
const hideLeftButton = computed(() => {
  return slider.value?.notVisibleNodes <= 0 || slider.value?.currentState <= slider.value?.minState;
});
const hideRightButton = computed(() => {
  return slider.value?.notVisibleNodes <= 0 || slider.value?.currentState >= slider.value?.maxState;
});
const showFinalPage = computed(() => !props.elements.length || isTeacher());

const isElementDone = (element: ElementStatus) => {
  return [LearningPathElementStatus.DONE, LearningPathElementStatus.CURRENT_DONE].includes(element.status);
};

const isElementCurrent = (element: ElementStatus) => {
  return [LearningPathElementStatus.CURRENT, LearningPathElementStatus.CURRENT_DONE].includes(element.status);
};

const activeStep = computed(() => props?.elements?.find(isElementCurrent)?.index);

const slideLeft = () => {
  slider.value.toLeft();
};

const slideRight = () => {
  slider.value.toRight();
};

watch(activeStep, (newValue: number) => {
  slider.value.setActive(newValue);
});

onMounted(() => {
  slider.value = Slider(list.value);
  slider.value.setActive(activeStep.value);
  slider.value.init();
});
</script>
