import { Ref, ref } from 'vue';
import useScss, { Breakpoint } from '@/services/useScss';

interface UseLearningPathMenu {
  toggleMenuDropdown: () => void;
  closeMenuDropdown: () => void;
  isLearningPathMenuOpen: Ref<boolean>;
  setHeaderHeight: (height: number) => void;
  getLearningPathMenuHeight: () => string;
}

const { useMediaQuery } = useScss();

const isLearningPathMenuOpen = ref(false);
const headerHeight = ref(0);

const toggleMenuDropdown = () => (isLearningPathMenuOpen.value = !isLearningPathMenuOpen.value);
const closeMenuDropdown = () => (isLearningPathMenuOpen.value = false);
const setHeaderHeight = (height: number) => (headerHeight.value = height);
const getLearningPathMenuHeight = () =>
  useMediaQuery(Breakpoint.XLARGE).value ? window.innerHeight - headerHeight.value + 'px' : 'fit-content';

export default (): UseLearningPathMenu => ({
  toggleMenuDropdown,
  isLearningPathMenuOpen,
  closeMenuDropdown,
  setHeaderHeight,
  getLearningPathMenuHeight,
});
