<template>
  <BaseCollapse ref="target" v-model="isOpen">
    <template #control>
      <button
        ref="button"
        :class="[
          'c-dropdown-button',
          {
            'c-dropdown-button--active': activeLink,
          },
        ]"
      >
        {{ name }}
        <ArrowIcon :class="['c-dropdown-button__arrow', { 'c-dropdown-button__arrow--open': isOpen }]" />
      </button>
    </template>
    <template #collapsible>
      <div ref="dropdown" :class="['c-dropdown-button__list', { 'c-dropdown-button__list--shown': isOpen }]">
        <NavigationItem
          v-for="(page, index) in children"
          :key="index"
          :name="page.name"
          :current="page"
          :children="page.children"
          :level="0"
          @link-active="(event) => setActiveChild(index, event)"
        />
      </div>
    </template>
  </BaseCollapse>
</template>

<script lang="ts">
import HeaderNavigation from '@/model/HeaderNavigation';
import useActiveLink from '@/services/useActiveLink';

export interface MenuDropdownButton {
  children: HeaderNavigation[];
  useOverlay?: boolean;
}
</script>

<script lang="ts" setup>
import { computed, onUpdated, provide, ref, watch } from 'vue';
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';
import BaseCollapse from '@/elements/collapse/BaseCollapse.vue';
import NavigationItem from '@/components/navigation-item/NavigationItem.vue';
import useOverlayService from '@/services/useOverlay';
import { onClickOutside } from '@vueuse/core';
import useScss, { Breakpoint } from '@/services/useScss';
import { autoUpdate, computePosition, shift } from '@floating-ui/dom';
import pxToRem from '@/utils/pxToRem';

interface MenuDropdownButtonProps {
  children?: HeaderNavigation[];
  name?: string;
  useOverlay?: boolean;
}

const props = defineProps<MenuDropdownButtonProps>();
const emit = defineEmits<{
  (event: 'on-dropdown-closed'): void;
}>();

const { activeLink, setActiveChild } = useActiveLink();
const { useMediaQuery } = useScss();
const isXLarge = useMediaQuery(Breakpoint.XLARGE);

const button = ref<HTMLButtonElement>(null);
const dropdown = ref<HTMLElement>(null);
const isOpen = ref<boolean>(false);
const overlay = useOverlayService();
const target = ref<HTMLElement>(null);

const useOverlayActive = computed(() => props.useOverlay && isXLarge.value);

const X_OFFSET = 8;

onClickOutside(target, () => isOpen.value && toggleMenuDropdown());

const toggleMenuDropdown = () => (isOpen.value = !isOpen.value);
const closeMenuDropdown = () => {
  isOpen.value = false;
  emit('on-dropdown-closed');
};

const updateTooltipPosition = async () => {
  if (!(button.value && dropdown.value) || !isXLarge.value) {
    return null;
  }

  const { x, y } = await computePosition(button.value, dropdown.value, {
    strategy: 'fixed',
    middleware: [shift()],
    placement: 'bottom-start',
  });

  Object.assign(dropdown.value.style, {
    left: pxToRem(x),
    top: pxToRem(y + X_OFFSET),
  });
};

const handleUpdate = () =>
  isXLarge.value ? autoUpdate(button.value, dropdown.value, updateTooltipPosition) : overlay.remove();

onUpdated(handleUpdate);

watch(isOpen, (n) => (n && useOverlayActive.value ? overlay.add() : overlay.remove()));
watch(isXLarge, handleUpdate);

provide('closeMenuDropdown', {
  closeMenuDropdown,
});
</script>
