<template>
  <BaseCollapse ref="target" v-model="isOpen">
    <template #control>
      <component
        :is="hasChildren ? 'div' : BaseLink"
        tag="button"
        :to="current.path"
        :class="[
          'c-navigation-item',
          { 'c-navigation-item--top-level': level === 0, 'c-navigation-item--open': isOpen && children },
          { 'c-navigation-item--active': activeLink },
        ]"
        @click="onClick"
      >
        {{ name }}
        <component
          :is="iconComponent"
          :class="['c-navigation-item__icon', { 'c-navigation-item__icon--leaf': !children }]"
        />
      </component>
    </template>
    <template v-if="children" #collapsible>
      <NavigationItem
        v-for="(page, index) in children"
        :key="index"
        :name="page.name"
        :current="page"
        :children="page.children"
        :level="level + 1"
        @link-active="(event) => setActiveChild(index, event)"
      />
    </template>
  </BaseCollapse>
</template>

<script lang="ts" setup>
import HeaderNavigation from '@/model/HeaderNavigation';
import { computed, inject, ref, watch } from 'vue';
import BaseCollapse from '@/elements/collapse/BaseCollapse.vue';
import ForwardArrowIcon from '@/assets/icons/ForwardArrowIcon.vue';
import DropdownIcon from '@/assets/icons/DropdownIcon.vue';
import BaseLink from '@/elements/link/BaseLink.vue';
import useOverlay from '@/services/useOverlay';
import useActiveLink from '@/services/useActiveLink';

interface NavigationItemProps {
  children?: HeaderNavigation[];
  current: HeaderNavigation;
  name: string;
  level: number;
}
const props = defineProps<NavigationItemProps>();
const emit = defineEmits<{
  (event: 'linkActive', value: boolean): void;
}>();

const overlay = useOverlay();
const { activeLink, setActiveChild } = useActiveLink({ to: props.current.path });

const { closeMenuDropdown } = inject<{ closeMenuDropdown: () => void }>('closeMenuDropdown');
const margin = `${props.level * 0.75}rem`;

const isOpen = ref(false);

const hasChildren = computed(() => props.children);
const iconComponent = computed(() =>
  props.children ? (isOpen.value ? DropdownIcon : DropdownIcon) : ForwardArrowIcon,
);

const onClick = () => {
  if (!hasChildren.value) {
    closeMenuDropdown();
    isOpen.value = false;
    overlay.remove();
  }
};

watch(activeLink, (value: boolean) => {
  emit('linkActive', value);
});
</script>

<style lang="scss" scoped>
.c-navigation-item {
  margin-left: v-bind(margin);
}
</style>
