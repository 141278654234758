export interface FlatTree<T> {
  node: T;
  id: string;
  order: number;
  parent: string;
}

export interface TreeStructure<T> {
  children?: T[];
}

export const buildTree = <T extends TreeStructure<T>>(nodes: FlatTree<T>[], id = null): T[] => {
  return nodes
    .filter((item) => item.parent === id)
    .sort(comparator)
    .map((item) => {
      const children = buildTree(nodes, item.id);
      return children.length ? { ...item.node, children } : item.node;
    });
};

const comparator = <T>(a: FlatTree<T>, b: FlatTree<T>): number => a.order - b.order;
