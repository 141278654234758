import { createRouter, NavigationGuardWithThis, RouteRecordRaw } from 'vue-router';
import { removeTokenFromUrl } from '@/utils/removeTokenFromUrl';
import useNamespace from '@/services/useNamespace';
import { generateBeforeEach } from '@/core/router';
import useHeader from '@/services/useHeader';
import useUserMode, { UserModeType } from '@/services/useUserMode';
import usePermissions from '@/services/usePermissions';
import { useCustomRouter } from '@/services/useCustomRouter';
import { PageName } from '@/constants/PageName';
import { ContentTypeRouteMap } from '@/config/ContentType';
import useLogin from '@/services/useLogin';
import usePage from '@/services/usePage';
import auth from '@/core/auth';
import { publicWebHistory } from '@/router/publicWebHistory';
import useError from '@/services/useError';

const contentPathMatch = ':pathMatch+';
const { currentContent, currentParent } = usePage();
const { namespace } = useNamespace();
const { hideError } = useError();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: PageName.HOME,
    component: () => import('@/views/content/HomePage.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showStandardHeader } = useHeader();
      showStandardHeader();
    },
  },
  {
    path: '/login',
    name: PageName.LOGIN,
    meta: {
      requiresAuth: false,
    },
    component: () => import('@/views/LoginPage.vue'),
    beforeEnter: () => {
      const { redirectUrl } = useLogin();

      auth.authorize({
        redirectUrl: redirectUrl.value,
        namespaceConfigId: namespace.configId,
        namespaceId: namespace.id,
      });
    },
  },
  {
    path: '/sok',
    name: PageName.SEARCH,
    component: () => import('@/views/SearchPage.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: '/begreper',
    name: PageName.CONCEPT,
    component: () => import('@/views/ConceptPage.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showStandardHeader } = useHeader();
      showStandardHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap['TEACHER-DASHBOARD']}`,
    name: PageName.TEACHER_DASHBOARD,
    component: () => import('@/views/teacher/TeacherDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { namespace } = useNamespace();
      const { showStandardHeader } = useHeader();
      const { isTeacher } = usePermissions();

      if (!isTeacher()) {
        return { name: PageName.ERROR, params: { id: '403' } };
      }

      if (!namespace.settings.useTeacherDashboard) {
        return { name: PageName.ERROR, params: { id: '404' } };
      }

      showStandardHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap['TEACHER-DASHBOARD']}/student/${contentPathMatch}`,
    name: PageName.TEACHER_STUDENT,
    component: () => import('@/views/teacher/TeacherStudentData.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { namespace } = useNamespace();
      const { showSubpageHeader } = useHeader();
      const { isTeacher } = usePermissions();

      if (!isTeacher()) {
        return { name: PageName.ERROR, params: { id: '403' } };
      }

      if (!namespace.settings.useTeacherDashboard) {
        return { name: PageName.ERROR, params: { id: '404' } };
      }

      showSubpageHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap.ARTICLE}/${contentPathMatch}`,
    name: PageName.ARTICLE,
    component: () => import('@/views/content/ArticlePage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap.CATEGORY}/${contentPathMatch}`,
    name: PageName.CATEGORY,
    component: () => import('@/views/content/CategoryPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to) => {
      const { mode } = useUserMode();
      const { showCategoryHeader } = useHeader();
      const { namespace } = useNamespace();
      showCategoryHeader();

      if (mode.value === UserModeType.TEACHER && namespace.settings.useTeacherDashboard) {
        return {
          path: `/${ContentTypeRouteMap['TEACHER-CATEGORY']}`,
          query: { ...to.query, paths: to.path.slice(3) },
          params: to.params,
          replace: true,
        };
      }
    },
  },
  {
    path: `/${ContentTypeRouteMap.REPLACE}/${contentPathMatch}`,
    name: PageName.REPLACE,
    component: () => import('@/views/content/ReplacePage.vue'),
  },
  {
    path: `/${ContentTypeRouteMap.TASK}/${contentPathMatch}`,
    name: PageName.TASK,
    component: () => import('@/views/content/TaskPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap.PROJECT}/${contentPathMatch}`,
    name: PageName.PROJECT,
    component: () => import('@/views/content/ProjectPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap.TEST}/${contentPathMatch}`,
    name: PageName.TEST,
    component: () => import('@/views/content/TestPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap['LEARNING-PATH']}/${contentPathMatch}`,
    name: PageName.LEARNING,
    component: () => import('@/views/content/LearningPath.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      hideFooter: true,
    },
    children: [
      {
        name: PageName.LEARNING_START,
        component: () => import('@/views/content/LearningPath/DescriptionPage.vue'),
        props: true,
        path: '0',
      },
      {
        path: ':step(\\d+)',
        component: () => import('@/views/content/LearningPath/StepPage.vue'),
        props: true,
        name: PageName.LEARNING_STEP,
      },
      {
        path: 'siste',
        component: () => import('@/views/content/LearningPath/FinalStepPage.vue'),
        props: true,
        name: PageName.LEARNING_LAST,
        beforeEnter: () => {
          const { isTeacher } = usePermissions();
          if (isTeacher()) {
            return { name: PageName.ERROR, params: { id: '404' } };
          }
        },
      },
      {
        name: PageName.LEARNING_INDEX,
        component: () => import('@/views/content/LearningPath/FallbackPage.vue'),
        props: true,
        path: '',
      },
    ],
    beforeEnter: () => {
      const { showLearningPathHeader } = useHeader();
      showLearningPathHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap.MODULE}/${contentPathMatch}`,
    name: PageName.MODULE,
    component: () => import('@/views/content/ModulePage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showCategoryHeader } = useHeader();
      showCategoryHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap['CT-VGS-MULTIMEDIA']}/${contentPathMatch}`,
    name: PageName.MULTIMEDIA,
    component: () => import('@/views/content/MultimediaPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    // We don't allow document pages yet
    path: `/${ContentTypeRouteMap.DOCUMENT}/${contentPathMatch}`,
    redirect: { name: PageName.HOME },
  },
  {
    path: `/${ContentTypeRouteMap['TEACHER-CATEGORY']}`,
    name: PageName.TEACHER_CATEGORY,
    component: () => import('@/views/teacher/TeacherCategoryPage.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { mode } = useUserMode();
      const { showCategoryHeader } = useHeader();
      const { isTeacher } = usePermissions();

      if (!isTeacher()) {
        return { name: PageName.ERROR, params: { id: '403' } };
      }
      if (mode.value === UserModeType.STUDENT) {
        return { name: PageName.ERROR, params: { id: '404' } };
      }

      showCategoryHeader();
    },
  },
  {
    path: `/${ContentTypeRouteMap['CATEGORY-PAGE-WITH-BLOCKS']}/${contentPathMatch}`,
    name: PageName.CATEGORY_PAGE_WITH_BLOCKS,
    component: () => import('@/views/content/CategoryPageWithBlocks.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
  {
    path: '/:id',
    name: PageName.ERROR,
    component: () => import('@/views/content/ErrorPage.vue'),
    props: true,
    meta: {
      requiresAuth: false,
    },
    beforeEnter: () => {
      const { showSubpageHeader } = useHeader();
      showSubpageHeader();
    },
  },
];

const router = createRouter({
  history: publicWebHistory,
  routes,
  scrollBehavior: (to, from, position) => ({ top: to.path === from.path ? position?.top : 0 }),
});

const customRouter = useCustomRouter(router, publicWebHistory, currentContent, currentParent);

export const isOpenAccess = (): boolean => {
  const { namespace } = useNamespace();

  return namespace?.allowOpenAccess;
};

export const goToParentRoute = async (): Promise<void> => {
  await customRouter.navigateBack();
};

export const redirectErrors: NavigationGuardWithThis<unknown> = (to) => {
  const { query } = to;
  if (query.code && query.error) {
    return { path: `/${query.code}` };
  }
};

router.beforeEach(removeTokenFromUrl);
router.beforeEach(redirectErrors);
router.beforeResolve(generateBeforeEach(isOpenAccess));

router.afterEach(() => {
  hideError();
});

export default router;
