import { GoBackHandler } from '@/services/useCustomRouter/model';
import { getPreviousRoute } from '@/services/useCustomRouter/handlers/historyTools';

export const defaultHandler: GoBackHandler = {
  isSupported: () => true,
  handle: async (context) => {
    if (getPreviousRoute(context)?.path === '/') {
      context.router.back();
    } else {
      await context.router.push('/');
    }
  },
  order: Number.MAX_SAFE_INTEGER,
  name: 'defaultHandler',
};
