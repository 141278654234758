<template>
  <div class="c-speed-control-popover">
    <button ref="button" class="c-speed-control-popover__button" v-on="events">
      <IconAdjust class="icon-adjust" />
    </button>
    <div
      ref="tooltip"
      class="c-speed-control-popover__content"
      :class="{ 'c-speed-control-popover__content--shown': isTooltipShown }"
    >
      <SpeedControl />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, provide, ref } from 'vue';
import { computePosition, Placement, flip, shift, autoUpdate, offset } from '@floating-ui/dom';
import pxToRem from '@/utils/pxToRem';
import IconAdjust from '@/assets/icons/IconAdjust.vue';
import SpeedControl from '@/components/multimedia/audio/speed-control/SpeedControl.vue';

interface SpeedControlPopoverProps {
  placement?: Placement;
  click?: boolean;
  hover?: boolean;
}

const props = withDefaults(defineProps<SpeedControlPopoverProps>(), {
  placement: 'bottom-end',
  click: true,
});

const button = ref<HTMLButtonElement>(null);
const tooltip = ref<HTMLElement>(null);
const isTooltipShown = ref(false);
const autoUpdateCleanup = ref(null);

const clickEvent = (event) => {
  const isTooltipClicked = (event.target as HTMLElement).closest('.c-speed-control-popover');

  if (!isTooltipClicked) {
    hideTooltip();
  }
};

const hideTooltip = () => (isTooltipShown.value = false);
const clickTooltip = () => {
  isTooltipShown.value = !isTooltipShown.value;
  if (isTooltipShown.value) {
    document.addEventListener('click', clickEvent);
  } else {
    document.removeEventListener('click', clickEvent);
  }
};

const events = {
  click: clickTooltip,
};

const updateTooltipPosition = async () => {
  const { x, y } = await computePosition(button.value, tooltip.value, {
    placement: props.placement,
    middleware: [shift(), flip(), offset(16)],
  });

  Object.assign(tooltip.value.style, {
    left: pxToRem(x),
    top: pxToRem(y),
  });
};

onMounted(async () => {
  autoUpdateCleanup.value = autoUpdate(button.value, tooltip.value, updateTooltipPosition);
});

onBeforeUnmount(() => {
  autoUpdateCleanup.value();
});

provide('isPopoverVisible', isTooltipShown);
</script>
