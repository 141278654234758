import { VerbDisplay, Verbs, VerbsDisplay } from '@/model/response/LRS';

export enum LRSObjectType {
  LEARNING_PATH = 'http://id.tincanapi.com/activitytype/playlist',
  ACTIVITY = 'http://adlnet.gov/expapi/activities/objective',
  ASSESSMENT = 'http://adlnet.gov/expapi/activities/assessment',
}

const COSS_URL = process.env.VUE_APP_COSS_URL;
const XAPI_EXTENSION_URL = 'https://id.edtech.fagbokforlaget.no/xapi/extension';

export const getVerbObject = (verbType: VerbDisplay) => ({
  id: Verbs[verbType.toUpperCase()],
  display: { 'en-US': VerbsDisplay[verbType.toUpperCase()] },
});

export const getObjectExtensions = (
  contentType: string,
  entity: string,
  id: string,
  slugPath?: string,
  namespaceId?: string,
) => ({
  [`${XAPI_EXTENSION_URL}/object-reference`]: getObjectReference(contentType, entity, id),
  ...(slugPath ? { [`${XAPI_EXTENSION_URL}/slug-path`]: slugPath } : {}),
  ...(namespaceId ? { [`${XAPI_EXTENSION_URL}/namespace-id`]: namespaceId } : {}),
});

export const getObjectReference = (type: string, entity: string, id: string) => ({ type, entity, id });

export const getStructureId = (namespaceId: string, id: string) => `${COSS_URL}/structures/${namespaceId}/nodes/${id}`;

export const getLearningPathId = (id: string) => `${COSS_URL}/learning-paths/${id}`;
