import { ActivityDefinition, ResultScore } from '@xapi/xapi';
import { AdjustedStatement, VerbDisplay } from '@/model/response/LRS';
import {
  getLearningPathId,
  getObjectExtensions,
  getStructureId,
  getVerbObject,
  LRSObjectType,
} from '@/queries/lrs/common';

interface QueryParams {
  learningPath: {
    id: string;
    title: string;
    structureId: string;
    structureSlugPath: string;
    structureNamespaceId: string;
  };
  definition?: ActivityDefinition;
  done?: boolean;
  passed?: boolean;
  selectedData?: Record<string, unknown>;
  verbId?: VerbDisplay;
  score: ResultScore;
  language?: string;
}

export const query = (options: QueryParams): AdjustedStatement => {
  const optionsDefault: QueryParams = {
    definition: {},
    done: false,
    passed: false,
    selectedData: {},
    verbId: 'progressed',
    ...options,
  };

  return {
    verb: getVerbObject(optionsDefault.verbId),
    object: {
      id: getLearningPathId(optionsDefault.learningPath.id),
      objectType: 'Activity',
      definition: {
        name: {
          'en-US': optionsDefault.learningPath.title,
        },
        type: LRSObjectType.LEARNING_PATH,
        ...optionsDefault.definition,
        extensions: getObjectExtensions('learning-path', 'edtech.learning-path', options.learningPath.id),
      },
    },
    result: {
      completion: optionsDefault.done,
      success: optionsDefault.passed,
      score: optionsDefault.score,
      response: JSON.stringify(optionsDefault.selectedData),
    },
    context: {
      ...(optionsDefault.language ? { language: optionsDefault.language } : {}),
      contextActivities: {
        parent: [
          {
            definition: {
              extensions: getObjectExtensions(
                'learning-path',
                'edtech.structure',
                options.learningPath.structureId,
                options.learningPath.structureSlugPath,
                options.learningPath.structureNamespaceId,
              ),
              type: LRSObjectType.LEARNING_PATH,
            },
            id: getStructureId(options.learningPath.structureNamespaceId, options.learningPath.structureId),
          },
        ],
      },
    },
  };
};
