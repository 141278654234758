export const SearchDebounceTimeMs = 500;
export const MinSearchInputLength = 3;

export const minSearchInputLength = (searchInputQuery: string): boolean | void => {
  if (typeof searchInputQuery !== 'string') return;
  return searchInputQuery.length === 0 || searchInputQuery.length >= MinSearchInputLength;
};

export const makeTagPath = (filterKey: string): string => `tags.${filterKey}`;

export const removeLastSlashFromPath = (path: string) => {
  return path[path.length - 1] === '/' ? path.slice(0, -1) : path;
};
