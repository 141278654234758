import { gql } from './fragments.gql';

const FILTER_TYPE_DIRECTORY = { type: 'DIRECTORY' };

const stringifyAndEscapeObject = (object: object) => JSON.stringify(JSON.stringify(object));

export const query = (page = 0, limit = 100, filter = stringifyAndEscapeObject(FILTER_TYPE_DIRECTORY)) => gql`
query queryAllStructure($locale: String!) {
  structures(page: ${page}, limit: ${limit}, filter: ${filter} ) {
    data {
      id
      parent
      slug
      name
      contentType
      contentId
      cover
      order
      localization(locale: $locale) {
        data {
          locale
          content
        }
      }
    }
  }
}`;
