<template>
  <div class="c-card__progression">
    <div
      :class="[
        'c-card-progression__label',
        {
          'c-card-progression__label--success': isDone,
          'c-card-progression__label--disabled': disabled,
        },
      ]"
    >
      <span v-if="isNotStarted">{{ $t('LEARNING_PATH.PROGRESS.NOT_STARTED') }}</span>
      <span v-if="isInProgress">
        {{ `${progress.raw} / ${progress.max}` }}
      </span>
      <span v-if="isDone">{{ $t('LEARNING_PATH.PROGRESS.FINISHED') }}</span>
    </div>
    <ProgressionBar
      :disabled="disabled"
      dim
      rounded
      class="c-card-progression__bar"
      :progress="progress.scaled * 100"
    />
    <BaseButton rounded fluid :primary="isDone" :outline="isDone" class="c-card-progression__button">
      <template v-if="progress.scaled">
        <template v-if="isDone">{{ $t('LEARNING_PATH.PROGRESS.VIEW') }}</template>
        <template v-else>
          {{ $t('LEARNING_PATH.PROGRESS.CONTINUE') }}
          <ForwardArrowIcon class="c-card-progression__button-icon"></ForwardArrowIcon>
        </template>
      </template>
      <template v-else>
        {{ $t('LEARNING_PATH.PROGRESS.START') }}
      </template>
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import ProgressionBar from '@/elements/progression-bar/ProgressionBar.vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import ForwardArrowIcon from '@/assets/icons/ForwardArrowIcon.vue';
import { ResultScore } from '@xapi/xapi';

interface CardProgressionProps {
  progress: ResultScore;
  disabled?: boolean;
}

const props = defineProps<CardProgressionProps>();

const isNotStarted = props.progress.scaled === 0;
const isInProgress = props.progress.scaled > 0 && props.progress.scaled < 1;
const isDone = props.progress.scaled === 1;
</script>
