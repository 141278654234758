import { dateFormatters, formatDuration, FormatDurationPart, FormatTypes, isToday, padTo2Digits } from '@/utils/date';

interface UseTime {
  formatContentTime: (initialTime: number, duration: string, format?: FormatDurationPart[]) => string;
  getDate: (timestamp: number) => string;
}

const formatContentTime = (initialTime: number, duration: string, format?: FormatDurationPart[]): string => {
  if (isNaN(initialTime)) {
    return duration;
  } else if (format) {
    return formatDuration(initialTime, format);
  }

  const initialTimeHours = dateFormatters[FormatTypes.HOURS](initialTime);

  switch (true) {
    case initialTimeHours >= 24:
      return formatDuration(initialTime, [
        { part: FormatTypes.DAYS },
        { part: FormatTypes.HOURS },
        { part: FormatTypes.MINUTES },
      ]);
    case initialTimeHours >= 1:
      return formatDuration(initialTime, [{ part: FormatTypes.HOURS }, { part: FormatTypes.MINUTES }]);
    case initialTimeHours < 1:
      return formatDuration(initialTime, [{ part: FormatTypes.MINUTES }]);
  }
};

export const getDate = (timestamp: number) => {
  const date = new Date(timestamp);
  let left: string;
  let right: string;
  let divider: string;

  if (isToday(timestamp)) {
    left = padTo2Digits(date.getHours());
    right = padTo2Digits(date.getMinutes());
    divider = ':';
  } else {
    left = padTo2Digits(date.getDate());
    right = padTo2Digits(date.getMonth() + 1);
    divider = '.';
  }
  return `${left}${divider}${right}`;
};

export default (): UseTime => ({
  formatContentTime,
  getDate,
});
