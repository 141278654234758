<template>
  <footer ref="footerElement" class="c-footer" data-test="footer">
    <FagbokforlagetIcon class="c-footer__logo" />
    <div class="c-footer__links">
      <a class="c-footer__link" href="https://www.fagbokforlaget.no/Personvern">
        <PrivacyIcon class="c-footer__icon" />
        <span class="c-footer__text">{{ $t('FOOTER.PRIVACY') }}</span>
      </a>
      <a class="c-footer__link" href="https://www.fagbokforlaget.no/kontakt">
        <ContactIcon class="c-footer__icon" />
        <span class="c-footer__text">{{ $t('FOOTER.CONTACT') }}</span>
      </a>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue';
import FagbokforlagetIcon from '@/assets/icons/FagbokforlagetIcon.vue';
import ContactIcon from '@/assets/icons/ContactIcon.vue';
import PrivacyIcon from '@/assets/icons/PrivacyIcon.vue';
import { useElementSize, useIntersectionObserver } from '@vueuse/core';
import pxToRem from '@/utils/pxToRem';
import useScss from '@/services/useScss';

const footerElement = ref<HTMLElement | null>(null);

const { setCssVariable } = useScss();
const { height } = useElementSize(footerElement);

const setFooterSizeCssVariable = (size: number) => {
  setCssVariable('--footer-size', pxToRem(size).toString());
};

const { stop } = useIntersectionObserver(footerElement, ([{ isIntersecting }]) => {
  isIntersecting ? setFooterSizeCssVariable(height.value) : setFooterSizeCssVariable(0);
});

onUnmounted(stop);
</script>
