<template>
  <SearchFieldMain data-test="standard-header-search" @update="onSearchUpdate" @open-modal="onOpenModal" />
  <SearchDropdown
    :is-open="dropdownOpen"
    :total="totalResults"
    :items="results"
    :loading="loading"
    @on-all-results="navigateToSearchPage"
    @close-dropdown="closeDropdown"
  />
</template>

<script lang="ts" setup>
import { markRaw, reactive, ref, toRef } from 'vue';
import SearchFieldMain from '@/components/search/field/SearchFieldMain.vue';
import SearchDropdown from '@/components/search/dropdown/SearchDropdown.vue';
import { searchHeaderQuery } from '@/queries/search/SearchHeader.query.elastic';
import useModal from '@/services/useModal';
import SearchFieldModal from '@/components/modal/SearchFieldModal.vue';
import { ModalType } from '@/types/modal/ModalType.enum';
import useSearch from '@/services/useSearch';
import { useRouter } from 'vue-router';
import { ALL_ID } from '@/config/Constants';
import { SearchStateInterface } from '@/types/search';
import { PageName } from '@/constants/PageName';

const router = useRouter();
const initialState: SearchStateInterface = {
  query: '',
  contentType: ALL_ID,
};
const state = reactive<SearchStateInterface>(initialState);
const dropdownOpen = ref<boolean>(false);
const modal = useModal();

const { results, totalResults, loading, search } = useSearch();

// Search for mobile screens
const onOpenModal = () => {
  modal.open(
    markRaw(SearchFieldModal),
    {
      modelValue: toRef(state, 'query'),
      totalResults,
      results,
      loading,
    },
    ModalType.DEFAULT,
    {
      'update:modelValue': (value) => onSearchUpdate(value),
    },
  );
};

const closeDropdown = () => (dropdownOpen.value = false);
const openDropdown = () => (dropdownOpen.value = true);

const navigateToSearchPage = () => {
  router.push({
    name: PageName.SEARCH,
    query: { query: state.query },
  });
  closeDropdown();
};

const onSearchUpdate = (query: string) => {
  state.query = query;

  if (query.length) {
    search(
      searchHeaderQuery({
        pageSize: 5,
      })(state),
    );
    openDropdown();
  } else {
    closeDropdown();
  }
};
</script>
