import { PageName } from '@/constants/PageName';
import { ResponseErrorStatusCode } from '@/services/useError';

import { GoBackHandler } from '@/services/useCustomRouter/model';
import { getCurrentRoute, getPreviousRoute, matchRouteByName } from '@/services/useCustomRouter/handlers/historyTools';

export const accessDeniedHandler: GoBackHandler = {
  isSupported: (context) => {
    const to = getCurrentRoute(context);
    return matchRouteByName(to, PageName.ERROR) && to.params.id === ResponseErrorStatusCode.UNAUTHORIZED;
  },
  handle: async (context) => {
    if (getPreviousRoute(context)?.path === '/') {
      context.router.back();
    } else {
      await context.router.push('/');
    }
  },
  order: 100,
  name: 'accessDeniedHandler',
};
