<template>
  <div :disabled="disabled" class="c-audio-player__wrapper">
    <section :class="['c-audio-player', `c-audio-player--${variant}`]">
      <AudioPlayerIcon :player-state="playerState" @play-or-pause="onPlayOrPause" />

      <template v-if="showControls">
        <div class="c-audio-player__details">
          <div :class="['c-audio-player__label', { 'c-audio-player__label--enabled': !disabled }]">
            <slot>{{ audioLabel ?? $t('AUDIO_PLAYER.LISTEN') }}</slot>
          </div>
          <ProgressBar :total="duration" :current="currentTime" :disabled="disabled" @update-progress="changeTime" />
        </div>
        <div class="c-audio-player__separator" />
        <SpeedControlPopover />
      </template>
    </section>
    <section v-if="timestamps" class="c-audio-player__chapters">
      <AudioChapters :timestamps="timestamps" @handle-click="changeTime" />
    </section>
    <BaseTranscription
      class="c-audio-player__transcription"
    />
  </div>
</template>

<script lang="ts">
export const Variants = {
  DEFAULT: 'default',
  THEMED: 'themed',
} as const;
</script>

<script lang="ts" setup>
import { computed, onUnmounted, provide, Ref } from 'vue';
import ProgressBar from '@/components/multimedia/audio/progress-bar/ProgressBar.vue';
import { PlayerState } from '@/types/text-to-speech/PlayerState.enum';
import useAudioControl from '@/components/multimedia/audio/audio-player/useAudioControl';
import { AudioFactory } from '@/types/text-to-speech/Audio.interface';
import SpeedControlPopover from '@/components/multimedia/audio/speed-control/SpeedControlPopover.vue';
import AudioPlayerIcon from '@/components/multimedia/audio/AudioPlayerIcon.vue';
import useGTM, { GtmEvent } from '@/services/useGTM';
import { Timestamp } from '@/model/content';
import AudioChapters from '@/components/multimedia/chapters/AudioChapters.vue';
import BaseTranscription from '@/components/multimedia/transcription/BaseTranscription.vue';

interface AudioPlayerProps {
  audioLabel?: string;
  audioFactory: AudioFactory;
  showControls?: boolean;
  variant?: (typeof Variants)[keyof typeof Variants];
  timestamps?: Timestamp[];
}

const props = withDefaults(defineProps<AudioPlayerProps>(), { showControls: true, variant: Variants.DEFAULT });

const emit = defineEmits<{
  speedRate: [value: Ref<number>];
  'change:transcriptionLanguage': [value: string];
}>();

const { pushGtmEvent } = useGTM();
const audioControl = useAudioControl();
const { play, pause, duration, currentTime, playerState, speedRate, changeTime } = audioControl.setup(
  props.audioFactory,
);

const disabled = computed(() => {
  return playerState.value === PlayerState.LOADING || playerState.value === PlayerState.NOT_INITIALIZED;
});

emit('speedRate', speedRate);
provide('speedRate', speedRate);

onUnmounted(() => {
  pause();
});

const onPlayOrPause = (event: KeyboardEvent | MouseEvent) => {
  event.preventDefault();

  if (playerState.value === PlayerState.PLAYING) {
    pause();
  } else {
    play();

    pushGtmEvent(GtmEvent.AUDIO_PLAY);
  }
};
</script>
