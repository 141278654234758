<template>
  <div ref="target" class="c-teacher-dashboard-menu">
    <TeacherDashboardPanelMenuButton
      :icon="CloseIcon"
      :label="$t('TEACHER_DASHBOARD.CLOSE')"
      close
      @click="closeMenu"
    />
    <TeacherDashboardPanelMenuButton
      :icon="HomeIcon"
      :active="isRouteActive(PageName.HOME)"
      :label="$t('TEACHER_DASHBOARD.HOME')"
      @click="goToHome"
    />
    <TeacherDashboardPanelMenuButton
      v-if="mode === UserModeType.TEACHER"
      :icon="PeopleIcon"
      :active="isRouteActive(PageName.TEACHER_DASHBOARD)"
      :label="$t('TEACHER_DASHBOARD.STUDENT_DATA')"
      @click="goToStudentData"
    />
    <template v-if="showOptions">
      <div class="c-teacher-dashboard-menu__separator"></div>
      <div class="c-teacher-dashboard-menu__view-options-wrapper">
        <span class="c-teacher-dashboard-menu__label">{{ $t('TEACHER_DASHBOARD.VIEW_AS') }}:</span>
        <div class="c-teacher-dashboard-menu__view-options">
          <label class="c-teacher-dashboard-menu__radio-control">
            <input v-model="mode" class="c-teacher-dashboard-menu__input" type="radio" :value="UserModeType.TEACHER" />
            <span class="c-teacher-dashboard-menu__input-label-text">{{ $t('TEACHER_DASHBOARD.TEACHER') }}</span>
          </label>
          <label class="c-teacher-dashboard-menu__radio-control">
            <input v-model="mode" class="c-teacher-dashboard-menu__input" type="radio" :value="UserModeType.STUDENT" />
            <span class="c-teacher-dashboard-menu__input-label-text">{{ $t('TEACHER_DASHBOARD.STUDENT') }}</span>
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import CloseIcon from '@/assets/icons/CloseIcon.vue';
import HomeIcon from '@/assets/icons/HomeIcon.vue';
import PeopleIcon from '@/assets/icons/PeopleIcon.vue';
import TeacherDashboardPanelMenuButton from '@/modules/teacher-dashboard-panel/menu/TeacherDashboardPanelMenuButton.vue';
import { useRoute, useRouter } from 'vue-router';
import { PageName } from '@/constants/PageName';
import useUserMode, { UserModeType } from '@/services/useUserMode';
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits<{
  close: [];
}>();

const router = useRouter();
const route = useRoute();
const target = ref<HTMLElement>(null);

const { mode, saveMode } = useUserMode();

watch(mode, () => {
  saveMode(mode.value);
  window.location.reload();
});

const isRouteActive = (routeName: PageName) => route.name === routeName;

const closeMenu = () => emit('close');
const goToHome = () => router.push({ name: PageName.HOME });
// TODO: change the route to student data
const goToStudentData = () => router.push({ name: PageName.TEACHER_DASHBOARD });

onClickOutside(target, closeMenu);

const hideOptionsOn = new Set([
  PageName.TEACHER_CATEGORY,
  PageName.CATEGORY,
  PageName.CONCEPT,
  PageName.SEARCH,
  PageName.TEACHER_DASHBOARD,
  PageName.TEACHER_STUDENT,
  PageName.CATEGORY_PAGE_WITH_BLOCKS,
]);
const showOptions = computed(() => !hideOptionsOn.has(route.name as PageName));
</script>
