<template>
  <div :class="['e-time-label', `e-time-label--${variant}`]">
    <component :is="icon" class="e-time-label__icon" />
    <span class="e-time-label__text">
      <span v-if="textBeforeDuration" class="e-time-label__label">{{ $t(textBeforeDuration) }}:</span>
      <span class="e-time-label__duration">{{ formattedDuration }}</span>
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import ClockDottedIcon from '@/assets/icons/ClockDottedIcon.vue';
import ClockIcon from '@/assets/icons/ClockIcon.vue';
import CalendarClockIcon from '@/assets/icons/CalendarClockIcon.vue';

export enum TimeLabelIconVersion {
  DOTTED = 'dotted',
  FULL = 'full',
  CALENDAR = 'calendar',
}

type IconVersion = (typeof TimeLabelIconVersion)[keyof typeof TimeLabelIconVersion];

const iconVersionsMap = {
  [TimeLabelIconVersion.DOTTED]: ClockDottedIcon,
  [TimeLabelIconVersion.FULL]: ClockIcon,
  [TimeLabelIconVersion.CALENDAR]: CalendarClockIcon,
};

export enum TimeLabelVariant {
  STARTED = 'started',
  NOT_STARTED = 'not-started',
  LAST_ACTIVITY = 'last-activity',
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';

import useTime from '@/services/useTime';
import { FormatDurationPart } from '@/utils/date';

interface TimeLabelProps {
  textBeforeDuration?: string;
  iconVersion?: IconVersion;
  duration?: string;
  format?: FormatDurationPart[];
  variant?: string;
  showIcon?: boolean;
}

const props = withDefaults(defineProps<TimeLabelProps>(), {
  showIcon: true,
  iconVersion: TimeLabelIconVersion.DOTTED,
  variant: TimeLabelVariant.NOT_STARTED,
});

const { formatContentTime, getDate } = useTime();

const formattedDuration = computed(() => {
  const initialTime = +props.duration;
  if (props.variant === TimeLabelVariant.LAST_ACTIVITY) {
    return getDate(new Date(props.duration).getTime());
  } else {
    return formatContentTime(initialTime, props.duration, props.format);
  }
});

const icon = computed(() => iconVersionsMap[props.iconVersion]);
</script>
