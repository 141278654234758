<template>
  <div id="main-content" class="v-layout" data-test="layout" tabindex="-1">
    <template v-if="isLoaded">
      <div :class="['v-layout__content', contentClass]">
        <div class="v-layout__navigation" data-test="layout-navigation">
          <slot name="navigation" />
        </div>
        <div class="v-layout__header-meta" data-test="layout-header-meta">
          <slot name="header-meta" />
        </div>
        <div class="v-layout__header" data-test="layout-header">
          <slot name="header" />
        </div>
        <div class="v-layout__main" data-test="layout-main">
          <slot />
        </div>
        <aside class="v-layout__aside" data-test="layout-aside">
          <slot name="aside" />
        </aside>
      </div>
    </template>
    <Loader v-else />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Loader from '@/elements/loader/BaseLoader.vue';
import useLoading from '@/services/useLoading';

defineProps({
  contentClass: {
    type: String,
    required: false,
  },
});

const { globalLoading } = useLoading();
const isLoaded = computed(() => !globalLoading.value);
</script>
