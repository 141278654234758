export enum PageName {
  ARTICLE = 'Article',
  CATEGORY = 'Category',
  CONCEPT = 'Concept',
  DOCUMENT = 'Document',
  ERROR = 'Error',
  HOME = 'Home',
  LOGIN = 'Login',
  MODULE = 'Module',
  PROJECT = 'Project',
  SEARCH = 'Search',
  TASK = 'Task',
  TEST = 'Test',
  MULTIMEDIA = 'Multimedia',
  LEARNING = 'Learning path',
  LEARNING_INDEX = 'Learning path main',
  LEARNING_STEP = 'Learning path step',
  LEARNING_LAST = 'Learning path last page',
  LEARNING_START = 'Learning path start',
  TEACHER_DASHBOARD = 'Teacher Dashboard',
  TEACHER_STUDENT = 'Teacher Student',
  TEACHER_CATEGORY = 'Teacher Category',
  REPLACE = 'Replace',
  CATEGORY_PAGE_WITH_BLOCKS = 'Category Page With Blocks',
}
