import { computed, ComputedRef, reactive } from 'vue';

interface UseMessageBanner {
  open: ComputedRef<boolean>;
  addMessage: (newMessage: string, key?: string) => void;
  clearMessages: () => void;
  removeMessage: (key: string) => void;
}

const messages = reactive(new Map());

export const useMessageBanner = (): UseMessageBanner => {
  const open = computed(() => Boolean(messages.size));

  const addMessage = (newMessage: string, key = Date.now().toString()) => {
    messages.set(key, { message: newMessage });
  };

  const clearMessages = () => messages.clear();

  const removeMessage = (key: string) => {
    if (!messages.has(key)) return;
    messages.delete(key);
  };

  return {
    open,
    addMessage,
    clearMessages,
    removeMessage,
  };
};

export default useMessageBanner;
