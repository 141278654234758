import { reactive } from 'vue';
import { namespaceQuery, namespaceQueryWithLocale } from '@/queries/gql';
import { NamespaceResponse } from '@/model/response';
import { mapNamespace, mapNamespaceWithConfig } from '@/mapper/Namespace';
import { Namespace } from '@/model/Namespace';
import { IOptions, useGraphQL, useNamespace as useCoreNamespace } from '@/core';
import { ColorTheme, Theme } from '@/model/Theme';
import { Language } from '@/i18n';
import { Content as SettingsResponse } from '@/model/response/Settings';

interface useNamespaceInterface {
  fetchNamespace: (locale?: string, options?: IOptions) => Promise<void>;
  initNamespace: (locale?: string, options?: IOptions) => Promise<void>;
  namespace: Namespace;
}

const emptyNamespace: Namespace = {
  id: '',
  name: '',
  languages: [],
  defaultLanguage: Language.NORWEGIAN_BOOKMAL,
  settings: {
    favicon: '',
    appLogo: null,
    levels: [],
    maxLevel: null,
    useLevels: false,
    headerNavigation: [],
    footerNavigation: [],
    cardStyle: null,
    logoutURL: '',
    levelType: '',
    filters: [],
    categories: [],
    skills: [],
    useTeacherDashboard: false,
  },
  themeMetadata: {
    theme: Theme.ADVANCED,
    template: 'default',
    color: ColorTheme.TEAL,
  },
  configId: '',
};

const { fetchObject, getObjectById } = useGraphQL();
const namespace = reactive<Namespace>(emptyNamespace);

const initNamespace = async (locale: string, options: IOptions) => {
  const { getNamespaceWithConfig } = useCoreNamespace();
  const response = await getNamespaceWithConfig<SettingsResponse>(options.namespace || options.domain, locale);

  if (response) {
    const mappedNamespace = mapNamespaceWithConfig(response);
    Object.assign(namespace, mappedNamespace);
  }
};

const fetchNamespace = async (locale: string, options: IOptions = {}) => {
  const query = locale ? namespaceQueryWithLocale : namespaceQuery;
  await fetchObject(query, { locale }, 'compositions', options);
  const response: NamespaceResponse = await getObjectById(query, '', { locale });

  if (response) {
    const mappedNamespace = mapNamespace(response);
    Object.assign(namespace, mappedNamespace);
  }
};

export default (): useNamespaceInterface => ({ namespace, fetchNamespace, initNamespace });
