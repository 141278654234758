import { makeTagPath } from '@/utils/search';

const filterToCollection = (tagLikeState: Record<string, boolean>): string[] =>
  Object.keys(tagLikeState).filter((tagKey) => tagLikeState[tagKey]);
export const filterToTagQuery = (filter: Record<string, boolean>, key: string): Record<string, string[]> => {
  const collection = filterToCollection(filter);
  return collection.length
    ? {
        [makeTagPath(key)]: collection,
      }
    : {};
};
