import { CardAdapter, CardImage, CardInterface, CardMultimedia } from '@/model/Card';
import { generateLinkHref } from '@/utils/generateLinkHref';
import { CategoryModel } from '@/model/page/Category';
import { NO_LEVELS_LEVEL } from '@/mapper/Namespace';
import useCachedAssets from '@/services/useCachedAssets';
import { getCategoryTagName } from '@/utils/getCategoryTagName';
import useNamespace from '@/services/useNamespace';
import useTags from '@/services/useTags';
import { ContentTypeEnum, ContentTypeTranslationMap } from '@/config/ContentType';
import i18n from '@/i18n';
import { Identifiable } from '@/model/Identifiable';
import useProgressTracking from '@/services/useProgressTracking';

export const resolveCardImage = async (
  imageId: string,
  namespaceId?: string,
  modifiers: [string, string] = ['upload/', 'upload/w_500/'],
): Promise<CardImage> => {
  if (!imageId) return;

  const asset = await useCachedAssets().getAsset(imageId, namespaceId);

  if (asset && modifiers && !asset?.url.includes(modifiers[1])) {
    asset.url = asset?.url.replace(modifiers[0], modifiers[1]);
  }

  return { src: asset?.url, alt: asset?.filename };
};

export const mapCard = async (cardData: CardAdapter, category?: CategoryModel): Promise<CardInterface> => {
  const { getFiltersObjectForContent } = useTags();
  const { namespace } = useNamespace();
  const { getAsset } = useCachedAssets();
  const { t } = i18n.global;
  const useLevels = namespace.settings.useLevels;

  const resolveLevel = (cardData: CardAdapter) => {
    const getLevel = (cardData: CardAdapter) => {
      if (!cardData?.tags) return;

      const level = namespace.settings.levels.find((level) => cardData?.tags.some((tag) => tag.keyword === level.id));

      return level ? level.value : NO_LEVELS_LEVEL.value;
    };

    return useLevels
      ? {
          useLevels,
          maxLevel: namespace.settings.maxLevel,
          level: getLevel(cardData),
        }
      : {};
  };

  const resolveTo = (cardData: CardAdapter, category: CategoryModel) => {
    const isConstructed = cardData.slug?.match(/\//g);

    if (cardData.to?.charAt(1) === '/') return cardData.to;

    return category && !isConstructed
      ? generateLinkHref(cardData.contentType, `${category.slug}/${cardData.slug}`)
      : generateLinkHref(cardData.contentType, cardData.slug);
  };

  const resolveBadge = (article: CardAdapter) => {
    if (article.contentType !== ContentTypeEnum.LEARNING_PATH) {
      return (
        article?.tags && getFiltersObjectForContent(namespace.settings.filters, article.tags)?.shift()?.value?.shift()
      );
    }
  };

  const resolveMultimedia = (cardData: CardAdapter): CardMultimedia => {
    if (cardData.contentType === ContentTypeEnum.MULTIMEDIA) {
      return {
        ...cardData.video,
        ...cardData.audio,
        duration: cardData.time,
      };
    }
  };

  const resolveCategoryTag = (categoryKeywordKey: string, categoriesTranslations: Identifiable[]) => {
    /*
      NOTE: By default we are assuming that if `categoryKeywordKey` is not present in
      `categoriesTranslations` we provide translated contentType as `categoryKeyword`
    */
    return (
      getCategoryTagName(categoryKeywordKey, categoriesTranslations) ??
      (ContentTypeTranslationMap[cardData.contentType] && t(ContentTypeTranslationMap[cardData.contentType]))
    );
  };

  const resolveSkillTags = (cardData: CardAdapter): string[] => {
    if (cardData.contentType === ContentTypeEnum.LEARNING_PATH) {
      const skills = namespace.settings.skills.filter(
        (skill) => cardData?.tags && cardData?.tags.some((tag) => tag.keyword === skill),
      );
      return skills;
    }
  };

  const resolveLearningPathData = async (
    cardData: CardAdapter,
  ): Promise<Pick<CardInterface, 'subject' | 'progress' | 'audio' | 'pages'> & { lpProxyId?: string }> => {
    const audio = true;
    const pages = cardData.pages;

    if (cardData.contentType === ContentTypeEnum.LEARNING_PATH) {
      const { getScore } = useProgressTracking();
      let progress;

      try {
        progress = await getScore(cardData.learningPath?.id);
      } catch {
        progress = { min: 0, max: cardData.learningPath.elementsCount, raw: 0, scaled: 0 };
      }

      // max=0 is not true, but not important for the card, as long as progress is 0
      // retrieving actual value can requires additional request

      /* NOTE:
          `cardData.learningPath.elementsCount` will return current count of elements, but in template we show only those that work,
          so there could be an issue with different counts for LRS and what we get from ES.
        */

      return {
        audio,
        progress: progress ?? { min: 0, max: cardData?.learningPath?.elementsCount, raw: 0, scaled: 0 },
        lpProxyId: cardData?.learningPath?.id,
        pages,
      };
    }

    return {};
  };

  return {
    id: cardData.id,
    ...resolveLevel(cardData),
    categoryKeyword: resolveCategoryTag(cardData?.categoryKeyword, namespace.settings.categories),
    image: await resolveCardImage(cardData.image?.assetId || cardData.image?.imageId, cardData?.namespaceId),
    type: cardData.contentType,
    title: cardData.title,
    subtitle: cardData.author,
    text: cardData.description,
    multimedia: resolveMultimedia(cardData),
    to: resolveTo(cardData, category),
    newTab: cardData.newTab,
    badge: resolveBadge(cardData),
    namespaceId: cardData?.namespaceId,
    duration: cardData.time,
    skillTags: resolveSkillTags(cardData),
    path: cardData.slug,
    ...(cardData.document
      ? {
          file: {
            ...(await getAsset(cardData.document?.file)),
            fileType: cardData.document?.fileType,
          },
        }
      : {}),
    ...(cardData.learningPath ? await resolveLearningPathData(cardData) : {}),
  };
};
