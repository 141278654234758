interface AudioOptions {
  speedRate: number;
}

interface useAudioOptionsInterface {
  audioOptionsDefault: AudioOptions;
  audioOptions: AudioOptions;
  setAudioOption: (key: keyof AudioOptions, value: string) => void;
}

const AUDIO_OPTIONS_KEY = 'audio_options';
const DEFAULT_SPEED_RATE = 1;

const audioOptionsDefault = {
  speedRate: DEFAULT_SPEED_RATE,
};

const getAudioOptions = () => JSON.parse(window.localStorage.getItem(AUDIO_OPTIONS_KEY));

const audioOptions: AudioOptions = {
  ...audioOptionsDefault,
  ...getAudioOptions(),
};

const setAudioOption = (key: keyof AudioOptions, value: string) => {
  const options = getAudioOptions();
  window.localStorage.setItem(AUDIO_OPTIONS_KEY, JSON.stringify({ ...options, [key]: value }));
};

export default (): useAudioOptionsInterface => ({
  audioOptionsDefault,
  audioOptions,
  setAudioOption,
});
