import { Router } from 'vue-router';
import { GoBackHandler, ResolvedRoute } from '@/services/useCustomRouter/model';
import {
  accessDeniedHandler,
  defaultHandler,
  fromErrorToLearningPathHandler,
  goToParentHandler,
  historyItemAvailableHandler,
  isLearningPathHandler,
  previousPageWasRedirectPageHandler,
  wasLearningPathHandler,
} from '@/services/useCustomRouter/handlers';
import { PublicWebHistory } from '@/router/publicWebHistory';
import { GenericContent } from '@/model/page/Content';
import { CategoryModel } from '@/model/page/Category';
import { Ref } from 'vue';

export interface UseCustomRouter {
  navigateBack: () => Promise<void>;
}

export const useCustomRouter = (
  router: Router,
  publicHistory: PublicWebHistory,
  currentContent: Ref<GenericContent>,
  currentParent: Ref<CategoryModel>,
): UseCustomRouter => {
  const handlers: GoBackHandler[] = [
    accessDeniedHandler,
    fromErrorToLearningPathHandler,
    isLearningPathHandler,
    wasLearningPathHandler,
    historyItemAvailableHandler,
    defaultHandler,
    previousPageWasRedirectPageHandler,
    goToParentHandler,
  ].sort((a, b) => a.order - b.order);

  const navigateBack = async () => {
    const routerState = publicHistory.history.map((route) => router.resolve(route) as ResolvedRoute);
    const context = { routerState, router, currentContent, currentParent };

    await handlers.find((handler) => handler.isSupported(context))?.handle(context);
  };

  return {
    navigateBack,
  };
};
