<template>
  <div ref="modalWrapper" class="c-modal__wrapper">
    <dialog open class="c-modal__dialog">
      <FocusLoop
        :class="['c-modal', modalTypeClass, { windowed }, { small }, { medium }, { auto }]"
        :style="style"
        tabindex="0"
        data-test="modal"
        @keyup.esc="close"
      >
        <header :class="['c-modal__header', { 'c-modal__header--black': blackHeader }]">
          <div class="c-modal__header--text">
            <slot name="header" />
          </div>
          <button
            class="c-modal__btn-close"
            :aria-label="$t('ARIA.CLOSE_MODAL')"
            tabindex="0"
            data-test="modal-btn-close"
            @click.stop="close"
            @keyup.stop.enter="close"
          >
            <CloseIcon />
          </button>
        </header>
        <div v-if="hasSlotContent(slots.controls)" class="c-modal__controls"><slot name="controls" /></div>

        <div ref="modalBodySection" :class="['c-modal__body', { 'c-modal__body--fixed-padding': fixedPadding }]">
          <slot name="body" />
        </div>
      </FocusLoop>
    </dialog>
    <div class="c-modal__backdrop" @click.stop="close"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, onMounted, ref, useSlots } from 'vue';
import CloseIcon from '@/assets/icons/CloseIcon.vue';
import useModal from '@/services/useModal';
import { ModalType } from '@/types/modal/ModalType.enum';
import { FocusLoop } from '@vue-a11y/focus-loop';
import { hasSlotContent } from '@/utils/hasSlotContent';
import { useRoute } from 'vue-router';

interface ModalLayoutProps {
  windowed?: boolean;
  small?: boolean;
  medium?: boolean;
  auto?: boolean;
  fixedPadding?: boolean;
}

defineProps<ModalLayoutProps>();
defineSlots<{
  header(): unknown;
  controls(): unknown;
  body(): unknown;
}>();

const slots = useSlots();

const { close, modal, modalHeight, initModalElement } = useModal();
const route = useRoute();
const modalBodySection = ref<HTMLDivElement>(null);
const modalWrapper = ref<HTMLDivElement>(null);

const blackHeader = computed(() => {
  return modal.value.modalType !== ModalType.DEFAULT;
});

const modalTypeClass = computed(() => {
  switch (modal.value?.modalType) {
    case ModalType.VIDEO:
      return 'video';
    default:
      return '';
  }
});

const style = computed(() => ({
  height: modalHeight.value,
}));

onMounted(() => {
  modalBodySection.value.focus();
  initModalElement(modalWrapper.value);
});

watch(() => route.path, close);
</script>
