import {reactive} from 'vue'
import {postGql} from './services/graphql'
import useTenant from './useTenant'

const { tenant } = useTenant()
const responses = reactive(new Map())

const fetchObjectById = async (query, contentId, variables = {}, endpoint, options) => {
  if (contentId) {
    const gqlRes = await postGql(
      options?.namespace || options?.domain || tenant.value.namespace,
      {
        query,
        variables: {
          id: encodeURIComponent(contentId),
          locale: 'nb',
          ...variables,
        },
      },
      endpoint,
    )

    const key = await generateKey(query, contentId, variables)

    responses.set(key, { ...gqlRes.data })
  }
}

const fetchObject = async (query, variables, endpoint, options) => {
  const gqlRes = await postGql(
      options?.namespace || options?.domain || tenant.value.namespace,
    {
      query,
      variables: {
        locale: 'nb',
        ...variables,
      },
    },
    endpoint,
  )

  const key = await generateKey(query, '', variables)

  responses.set(key, { ...gqlRes.data })
}

const getObjectById = async (query, id, variables) => {
  const key = await generateKey(query, id, variables)

  return responses.get(key)
}

const generateKey = async (query, id, variables) => {
  const key = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(`${query}-${id}-${Object.entries(variables)}`),
  )

  return Array.from(new Uint8Array(key))
    .map((bit) => bit.toString(16).padStart(2, '0'))
    .join('')
}

export default () => ({
  responses,
  fetchObjectById,
  fetchObject,
  getObjectById,
})
