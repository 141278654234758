<template>
  <Transition v-if="modal" name="fade">
    <component :is="modal.instance" v-if="modal" v-bind="modal.props" v-on="modal.events" />
  </Transition>
</template>

<script lang="ts" setup>
import useModal from '@/services/useModal';

const { modal } = useModal();
</script>
