import { useAuth, useStructures } from '@/core';
import useNamespace from '@/services/useNamespace';
import { ref, Ref } from 'vue';
import { trackableContentQuery } from '@/queries/search/Progression.query.elastic';
import useActivityDataSearch from '@/services/useActivityDataSearch';
import {
  ActivityDataAggregationNames,
  ActivityDataTypes,
  searchActivityData,
} from '@/queries/activity-data/ActivityData.query.ad';
import useElasticSearch from '@/services/useElasticSearch';
import useLoading from '@/services/useLoading';
import useLocalization from '@/services/useLocalization';

const { namespace } = useNamespace();

export enum ProgressStatus {
  COMPLETED = 'completed',
  STARTED = 'started',
  PENDING = 'pending',
}

interface ProgressionRecord {
  slugPath: string;
  status: ProgressStatus;
  order?: number;
  childProgression?: Array<ProgressionRecord>;
}

interface UseProgressionLP {
  init: () => void;
  enabled: Ref<boolean>;
  progression: Ref<Array<ProgressionRecord>>;
}

const enabled = ref(false);
const progression = ref([]);

const setup = () =>
  (async () => {
    // set depending on some settings (namespace, user)
    enabled.value = true;

    const { fetch } = useElasticSearch();
    const { onGlobalLoadStart, onGlobalLoadFinish } = useLoading();
    const { locale } = useLocalization();

    const search = (query) =>
      fetch(query, { locale: locale.value }).then((result) => result.hits.hits.map((hit) => hit._source));

    const init = () => {
      if (enabled.value) {
        onGlobalLoadStart('progression');
        const { structures, fetchStructuresParents } = useStructures();
        const { fetchActivityData } = useActivityDataSearch();
        const { user } = useAuth();
        const fetchTrackableContent = search(trackableContentQuery());
        const fetchStructuresForOrder = fetchStructuresParents();
        const fetchProgress = fetchActivityData(
          searchActivityData(
            {
              namespace: namespace.id,
              student: user.value.providerId,
              type: ActivityDataTypes.LEARNING_PATH_ELEMENT,
            },
            ActivityDataAggregationNames.SINGLE_STUDENT_VIEW_LP,
          ),
        );
        return Promise.all([fetchTrackableContent, fetchProgress, fetchStructuresForOrder])
          .then(([content, progress]) => {
            const lpProgress = progress.aggregations['by_lp_proxy_id'].buckets.reduce(
              (result: Record<string, string[]>, lp) => ({
                ...result,
                [lp.key]: lp.completed['lp_elements_count'].buckets.map((item) => item.key),
              }),
              {},
            );

            const state = content
              .reduce((result: ProgressionRecord[], current) => {
                const parentSlug = current.path.split('/')[0];
                const index = result.findIndex((record) => record.slugPath === parentSlug);

                let parent: ProgressionRecord;

                if (index !== -1) {
                  parent = result[index];
                } else {
                  parent = {
                    slugPath: parentSlug,
                    status: ProgressStatus.PENDING,
                    childProgression: [],
                    order: structures.value.find((structure) => structure.slugPath === parentSlug).order,
                  };
                  result.push(parent);
                }

                parent.childProgression.push({
                  slugPath: current.path,
                  status:
                    current.body.learningPath.elementsCount === lpProgress[current.id]?.length
                      ? ProgressStatus.COMPLETED
                      : ProgressStatus.PENDING,
                });
                return result;
              }, [])
              .sort((a, b) => b.order - a.order);

            progression.value = state.map((item) => ({
              ...item,
              status: item.childProgression?.every((child) => child.status === ProgressStatus.COMPLETED)
                ? ProgressStatus.COMPLETED
                : ProgressStatus.STARTED,
            }));

            onGlobalLoadFinish('progression');
          })
          .catch((_) => {
            onGlobalLoadFinish('progression');
          });
      }
      return Promise.resolve();
    };

    await init();
  })();

export default (): UseProgressionLP => ({ init: setup, progression, enabled });
