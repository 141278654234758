import useLocalization from '@/services/useLocalization';
import { Language } from '@/i18n';

interface DirectiveBinding<V = unknown> {
  arg?: string;
  modifiers: Record<string, boolean>;
  value: V;
  oldValue: V;
}

const changeElementDirection = (element: HTMLElement, binding: DirectiveBinding<Language>) => {
  const { direction } = useLocalization();
  element.dir = direction(binding?.value);
};

export default {
  mounted: changeElementDirection,
  updated: changeElementDirection,
};
