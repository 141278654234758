import { CONTENT_FRAGMENT, ERROR_FRAGMENT, gql } from './fragments.gql';

export const namespaceQueryWithLocale = gql`
  ${ERROR_FRAGMENT}
  ${CONTENT_FRAGMENT}
  query queryNamespaceWithLocale($locale: String!) {
    namespace {
      data {
        id
        name
        metadata {
          template
          theme
        }
        config
        configContent {
          data {
            ...ContentFragment
            localization(locale: $locale)
          }
        }
        status
        slug
        languages {
          fallback
          locale
        }
      }
      error {
        ...ErrorFragment
      }
    }
  }
`;

export const namespaceQuery = gql`
  ${ERROR_FRAGMENT}
  ${CONTENT_FRAGMENT}
  query queryNamespace {
    namespace {
      data {
        id
        name
        metadata {
          template
          theme
        }
        config
        configContent {
          data {
            ...ContentFragment
          }
        }
        status
        slug
        languages {
          fallback
          locale
        }
      }
      error {
        ...ErrorFragment
      }
    }
  }
`;
