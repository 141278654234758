// TODO: Move this service to core
import { useTenant } from '@/core';
import { ElasticSearchResponse } from '@/model/search/response';
import { fetchSH } from '@/core/api';

interface UseElasticSearchOptions {
  namespace?: string;
  locale: string;
}

interface UseElasticSearch {
  fetch: <Aggregation>(query: unknown, options: UseElasticSearchOptions) => Promise<ElasticSearchResponse<Aggregation>>;
}

const ENDPOINT = 'search';
const { tenant } = useTenant();

const fetch = async <Aggregation>(
  query: unknown,
  options?: UseElasticSearchOptions,
): Promise<ElasticSearchResponse<Aggregation>> => {
  const namespace = options.namespace || tenant.value.namespace;

  return fetchSH(`${ENDPOINT}/query/${namespace}/${options.locale}`, {
    method: 'POST',
    body: JSON.stringify(query),
  });
};

export default (): UseElasticSearch => ({
  fetch,
});
