import { GoBackHandler } from '@/services/useCustomRouter/model';
import { generateLinkHref } from '@/utils/generateLinkHref';

export const goToParentHandler: GoBackHandler = {
  isSupported: (context) => {
    return !!context.currentParent.value;
  },
  handle: async (context) => {
    if (context.currentParent.value.contentType === 'subject') {
      await context.router.push('/');
    } else {
      const link = generateLinkHref(context.currentParent.value.contentType, context.currentParent.value.slugPath);
      await context.router.push(`/${link}`);
    }
  },
  name: 'goToParent',
  order: 330,
};
