import { GoBackHandler } from '@/services/useCustomRouter/model';
import { getCurrentRoute, getPreviousRoute, matchRouteByName } from '@/services/useCustomRouter/handlers/historyTools';
import { PageName } from '@/constants/PageName';

export const historyItemAvailableHandler: GoBackHandler = {
  isSupported: (context) => {
    const currentItem = getCurrentRoute(context);
    const lastHistoryItem = getPreviousRoute(context)?.path;

    return (
      !!lastHistoryItem &&
      !matchRouteByName(currentItem, PageName.CATEGORY) &&
      !matchRouteByName(currentItem, PageName.MODULE) &&
      !matchRouteByName(currentItem, PageName.TEACHER_CATEGORY)
    );
  },
  handle: async (context) => {
    context.router.back();
  },
  order: 300,
  name: 'historyItemAvailableHandler',
};
