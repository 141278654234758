import { CategoryPageWithBlocksResponse } from '@/model/response/CategoryPageWithBlocks';
import { CategoryPageWithBlocksModel } from '@/model/page/CategoryPageWithBlocks';
import { ChildrenTypeEnum } from '@/model/response';
import { mapFilterType, mapContentBlocks } from '@/mapper/';

export const mapCategoryPageWithBlocks = (response: CategoryPageWithBlocksResponse): CategoryPageWithBlocksModel => ({
  id: response?.id,
  contentType: response?.contentType,
  slug: response?.slug,
  namespace: response?.content?.data?.namespace,
  title: response?.localization?.data?.content?.name || response?.name,
  accessLevel: response?.accessLevel,
  order:
    response?.content?.data?.localization?.orderOfContent?.value ||
    response?.content?.data?.content?.orderOfContent?.value,
  filterType: mapFilterType(
    response?.content?.data?.localization?.navTreeStructure?.value ||
      response?.content?.data?.content?.navTreeStructure?.value,
  ),
  filters: response?.content?.data?.content?.predefinedFilters?.filters?.list,
  categories: response?.content?.data?.content?.predefinedFilters?.categories?.list,
  mainCategoryTag:
    response?.content?.data?.localization?.mainCategory?.value?.tag ||
    response?.content?.data?.content?.mainCategory?.value?.tag,
  description: response?.description,
  cover: {
    altText: response?.content?.data?.localization?.altText || response?.content?.data?.content?.altText,
    assetId: response?.cover,
    copyright: response?.content?.data?.localization?.copyright || response?.content?.data?.content?.copyright,
  },
  children:
    response?.children?.data
      ?.filter((child) => child.type === ChildrenTypeEnum.OBJECT)
      ?.sort((a, b) => a.order - b.order)
      ?.map(mapContentBlocks) || [],
});
