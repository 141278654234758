import { ResultScore } from '@xapi/xapi';
import { AdjustedStatement, VerbDisplay } from '@/model/response/LRS';
import {
  getLearningPathId,
  getObjectExtensions,
  getStructureId,
  getVerbObject,
  LRSObjectType,
} from '@/queries/lrs/common';

interface QueryParams {
  verbId: VerbDisplay;
  contentId: string;
  contentSlugPath: string;
  contentNamespaceId: string;
  contentTitle: string;
  language?: string;
  contentType?: string; // Preferable an enum

  duration?: string; // ISO format
  score?: ResultScore;

  learningPath?: {
    id: string;
    title: string;

    structureId: string;
    structureSlugPath: string;
    structureNamespaceId: string;
  };
}

export const query = (options: QueryParams): AdjustedStatement => {
  return {
    verb: getVerbObject(options.verbId),
    object: {
      id: getStructureId(options.contentNamespaceId, options.contentId),
      objectType: 'Activity',
      definition: {
        name: {
          'en-US': options.contentTitle,
        },
        type: LRSObjectType.ACTIVITY,
        extensions: getObjectExtensions(
          options.contentType,
          'edtech.structure',
          options.contentId,
          options.contentSlugPath,
          options.contentNamespaceId,
        ),
      },
    },
    ...(options.learningPath
      ? {
          context: {
            contextActivities: {
              parent: [
                {
                  definition: {
                    extensions: getObjectExtensions('learning-path', 'edtech.learning-path', options.learningPath.id),
                    type: LRSObjectType.LEARNING_PATH,
                  },
                  id: getLearningPathId(options.learningPath.id),
                },
                {
                  definition: {
                    extensions: getObjectExtensions(
                      'learning-path',
                      'edtech.structure',
                      options.learningPath.structureId,
                      options.learningPath.structureSlugPath,
                      options.learningPath.structureNamespaceId,
                    ),
                    type: LRSObjectType.LEARNING_PATH,
                  },
                  id: getStructureId(options.learningPath.structureNamespaceId, options.learningPath.structureId),
                },
              ],
            },
          },
        }
      : {}),
  };
};
