import { GenericContent } from '@/model/page/Content';
import {
  mapArticle,
  mapCategory,
  mapCategoryPageWithBlocks,
  mapConcept,
  mapFrontpage,
  mapMultimedia,
  mapProject,
  mapTest,
  mapContentBlocks,
} from '@/mapper';
import {
  ArticleResponse,
  ArticleResponseChild,
  CategoryResponse,
  MultimediaResponse,
  PageResponse,
  TestResponse,
} from '@/model/response';
import { FrontpageResponse } from '@/model/response/Frontpage';
import { ProjectResponse } from '@/model/response/Project';
import { mapLearningPath } from '@/mapper/LearningPath';
import { LearningPathResponse, TempLearningPathDetailsResponse } from '@/model/response/LearningPath';
import { CategoryPageWithBlocksResponse } from '@/model/response/CategoryPageWithBlocks';
import { mapModule } from '@/mapper/Module';
import { mapSubject } from '@/mapper/Subject';
import { ConceptPageResponse } from '@/model/response/Concept';
import { ChildContentType } from '@/constants/ChildContentType';

export const mapContent = (response: PageResponse, extraInfo?: PageResponse): GenericContent | null => {
  switch (response.contentType) {
    case 'front-page':
      return mapFrontpage(response as FrontpageResponse);
    case 'category-page':
      return mapCategory(response as CategoryResponse);
    case 'module':
      return mapModule(response);
    case 'subject':
      return mapSubject(response);
    case 'article':
    case 'task':
    case 'document':
    case 'concept':
      return mapArticle(response as ArticleResponse);
    case 'concept-page':
      return mapConcept(response as ConceptPageResponse);
    case 'project':
      return mapProject(response as ProjectResponse);
    case 'test':
      return mapTest(response as TestResponse);
    case 'ct-vgs-multimedia':
      return mapMultimedia(response as MultimediaResponse);
    case 'learning-path':
      return mapLearningPath(response as LearningPathResponse, extraInfo as unknown as TempLearningPathDetailsResponse);
    case 'category-page-with-blocks':
      return mapCategoryPageWithBlocks(response as CategoryPageWithBlocksResponse);
    case ChildContentType.VIDEO:
    case ChildContentType.AUDIO:
    case ChildContentType.ARTICLE_LEFT_RIGHT:
    case ChildContentType.FRONT_PAGE_LEFT_RIGHT:
    case ChildContentType.ACCORDIONS:
      return mapContentBlocks(response as unknown as ArticleResponseChild);
    default:
      null;
  }
};
