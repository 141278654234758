<template>
  <Transition name="expand" v-on="events">
    <div :class="['transition-expand', { 'transition-expand--hidden': !calculationsFinished }]" v-show="showContents">
      <slot />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

interface TransitionExpandProps {
  show?: boolean;
}

const props = defineProps<TransitionExpandProps>();

const showForCalculations = ref(true);
const calculationsFinished = ref(false);

const showContents = computed(() => showForCalculations.value || props.show);

onMounted(() => {
  showForCalculations.value = false;

  if (props.show) {
    calculationsFinished.value = true;
  }
});

const emit = defineEmits(['onOpen', 'onAfterOpen', 'onClose', 'onAfterClose', 'onBeforeLeave']);

const events = {
  enter(element: HTMLElement) {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = '0';

    requestAnimationFrame(() => (element.style.height = height));
    emit('onOpen');
  },
  afterEnter(element: HTMLElement) {
    requestAnimationFrame(() => (element.style.height = 'auto'));
    emit('onAfterOpen');
  },
  leave(element: HTMLElement) {
    const height = getComputedStyle(element).height;
    element.style.height = height;
    requestAnimationFrame(() => {
      element.style.height = '0px';
      emit('onClose');
    });
  },
  afterLeave(element: HTMLElement) {
    emit('onAfterClose', element);

    calculationsFinished.value = true;
  },
};
</script>

<style scoped lang="scss">
.transition-expand--hidden {
  position: absolute;
  opacity: 0;
}
</style>
