import scss from '@/../styles/src/common/settings/_export.scss?inline';
import { ColorTheme, Theme } from '@/model/Theme';
import clearPx from '@/utils/clearPx';
import { useDebounceFn } from '@vueuse/core';
import { ComputedRef, Ref, computed, reactive, ref } from 'vue';

export type ScssVariablesInterface = Record<string, unknown>;

interface useScssInterface {
  variables: ScssVariablesInterface;
  windowWidth: Ref<number>;
  useMediaQuery: (breakpoint: Breakpoint) => ComputedRef<boolean>;
  setTheme: (theme: Theme) => void;
  setThemeColor: (themeColor: ColorTheme) => void;
  theme: {
    name: Theme;
    color: ColorTheme;
  };
  setCssVariable: (name: string, value: string) => void;
}
export enum Breakpoint {
  ALL = 'all',
  SMALL = 'small',
  MEDIUM = 'medium',
  XMEDIUM = 'xmedium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  DESKTOP = 'desktop',
}

const CommentRegexp = /(\/\*).*(\*\/)/g;
const NewLineRegexp = /[\n ]/g;

// TRANSFORMING TEXT TO OBJECT
const trimmedScss = scss.replace(CommentRegexp, '').replace(NewLineRegexp, '');

const variables: ScssVariablesInterface = Object.fromEntries(
  trimmedScss
    .substring(trimmedScss.indexOf(':export'))
    .replace(':export', '')
    .slice(1, import.meta.env.MODE === 'development' ? -2 : -1)
    .split(';')
    .map((string) => {
      const colonIndex = string.indexOf(':');
      return [string.slice(0, colonIndex).trim(), string.slice(colonIndex + 1).trim()];
    })
    .map((array) => [array[0], array[1] ? JSON.parse(array[1].replaceAll("'", '')) : array[1]]),
);

const windowWidth = ref(window.innerWidth);
const windowResizeObserver = new ResizeObserver(useDebounceFn(() => (windowWidth.value = window.innerWidth), 100));
windowResizeObserver.observe(document.body);

const useMediaQuery = (breakpoint: Breakpoint): ComputedRef<boolean> =>
  computed(() => windowWidth.value >= clearPx(variables.breakpoints[breakpoint]));

const theme = reactive({
  name: Theme.ADVANCED,
  color: ColorTheme.AZURE_BLUE,
});

const setTheme = (themeValue: Theme) => {
  theme.name = themeValue;
};

const setThemeColor = (themeColor: ColorTheme) => {
  theme.color = themeColor;
};

const setCssVariable = (name: string, value: string) => {
  document.documentElement.style.setProperty(name, value);
};

export default (): useScssInterface => ({
  variables,
  windowWidth,
  useMediaQuery,
  setTheme,
  setThemeColor,
  theme,
  setCssVariable,
});
