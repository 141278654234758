import { fetchSH } from '../api'

const endpoint = 'activity'

/* ACTIVITY DATA CRUD */
const queryActivityData = (query) =>
  fetchSH(`${endpoint}/query`, {
    method: 'POST',
    body: JSON.stringify(query),
  })

export { queryActivityData }
