import { ref } from 'vue';

export const enum ResponseErrorStatusCode {
  INTERNAL_SERVER_ERROR = '500',
  UNAUTHORIZED = '401',
  PAYMENT_REQUIRED = '402',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
}

class ErrorWithStatus extends Error {
  status: number;

  constructor(...args) {
    super(...args);
  }
}

const errorID = ref<ResponseErrorStatusCode>(null);

const showErrorWith = (id: ResponseErrorStatusCode) => {
  errorID.value = id;
};

const hideError = () => {
  errorID.value = null;
};

export default () => ({
  showErrorWith,
  hideError,
  errorID,
});

export const ResponseError = (status: number, message?: string) => {
  const error = new ErrorWithStatus(message || status.toString());
  error.status = status;

  return error;
};
