<template>
  <div
    :class="['c-audio-player__icon', { 'c-audio-player__icon--disabled': isChangingState }]"
    tabindex="0"
    :aria-label="$t('AUDIO_PLAYER.LISTEN')"
    @click.stop="playOrPause"
    @keydown.stop="playOrPause"
  >
    <component :is="icon" :class="className" />
  </div>
</template>

<script lang="ts" setup>
import { markRaw, ref, watch } from 'vue';
import { PlayerState } from '@/types/text-to-speech/PlayerState.enum';
import { KEYS_SPACEBAR_ENTER } from '@/utils/keyboard';
import Loader from '@/elements/loader/BaseLoader.vue';
import PlayIcon from '@/assets/icons/PlayIcon.vue';
import PauseIcon from '@/assets/icons/PauseIcon.vue';

interface AudioPlayerIconProps {
  playerState: PlayerState;
}

const props = defineProps<AudioPlayerIconProps>();
const emit = defineEmits(['playOrPause']);
const className = ref('');

const icon = ref(null);
const isChangingState = ref(false);

const checkState = (state: PlayerState) => {
  if (state === PlayerState.PAUSED || state === PlayerState.PLAYING) {
    isChangingState.value = false;
  }
};

const setIcon = () => {
  switch (props.playerState) {
    case PlayerState.LOADING:
      icon.value = markRaw(Loader);
      className.value = 'c-audio-player__icon--loading';
      break;
    case PlayerState.NOT_INITIALIZED:
    case PlayerState.PAUSED:
      icon.value = markRaw(PlayIcon);
      className.value = 'c-audio-player__icon--play';
      break;
    default:
      icon.value = markRaw(PauseIcon);
      className.value = 'c-audio-player__icon--pause';
      break;
  }
};

setIcon();
watch(
  () => props.playerState,
  (state: PlayerState) => {
    checkState(state);
    setIcon();
  },
);

const playOrPause = (event: KeyboardEvent | MouseEvent) => {
  if (event instanceof KeyboardEvent && !KEYS_SPACEBAR_ENTER.includes(event.key.toLowerCase())) {
    return;
  }
  isChangingState.value = true;
  emit('playOrPause', event);
};
</script>
