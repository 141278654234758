import { ResultScore } from '@xapi/xapi';
import { LearningPathProgress } from '@/model/Progression';

export const prepareLearningPathScore = (progress, learningPath): ResultScore => {
  const finishedTasks = learningPath.children.filter((child) =>
    progress.find((item) => item.id === child.id && item.state === LearningPathProgress.FINISHED),
  );

  const max = learningPath.children.length;
  const min = 0;
  const raw = finishedTasks.length;

  const scaled = raw / max;

  return { min, max, raw, scaled };
};
