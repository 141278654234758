<template>
  <div v-if="showTabsBlock" class="c-tabs-block">
    <BaseTab
      v-for="(tab, index) in tabs"
      :key="tab.title"
      v-bind="tab"
      :active="modelValue === index"
      class="c-tabs-block__tab"
      @click="onClick(index)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TabsBlockProps } from '@/model/content/Tab';
import BaseTab from '@/elements/tab/BaseTab.vue';

const props = defineProps<TabsBlockProps>();
const modelValue = defineModel<number>();

const onClick = (index: number) => (modelValue.value = index);

const showTabsBlock = computed(() => props.tabs.length > 1);
</script>
