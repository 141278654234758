import { createI18n } from 'vue-i18n';
import nb from '@/i18n/nb';
import nn from '@/i18n/nn';
import en from '@/i18n/en';

export enum Language {
  NORWEGIAN_BOOKMAL = 'nb',
  NORWEGIAN_NYNORSK = 'nn',
  ENGLISH = 'en',
}

// TODO: Make it dynamic - based on available translations
export const availableLanguagesVgs: Language[] = [
  Language.NORWEGIAN_BOOKMAL,
  Language.NORWEGIAN_NYNORSK,
  Language.ENGLISH,
];

export const i18n = createI18n({
  useScope: 'global',
  legacy: false,
  globalInjection: true,
  locale: Language.ENGLISH,
  messages: {
    nb,
    nn,
    en,
  },
});

export default i18n;
