import { SupportedContentTypes } from '@/config/ContentType';
import { SearchQuery, SearchQueryOptions, SearchStateInterface } from '@/types/search';
import { prepareTextQuery, setupHighlights } from '@/queries/search/Search.query.elastic';

export const searchHeaderQuery =
  (queryOptions?: SearchQueryOptions) =>
  (state: SearchStateInterface): SearchQuery => {
    const options: SearchQueryOptions = {
      pageSize: 5,
      minScore: 0.1,
      supportedContentTypes: state.supportedContentTypes || SupportedContentTypes,
      ...queryOptions,
    };

    return {
      _source_excludes: ['texts'],
      size: options.pageSize,
      min_score: options.minScore?.toString(),
      query: {
        bool: {
          ...prepareTextQuery(state.query, options),
        },
      },
      ...setupHighlights(),
      sort: [{ _score: 'desc' }, { id: 'asc' }],
    };
  };
