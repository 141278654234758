import { GoBackContext } from '@/services/useCustomRouter/model';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { PageName } from '@/constants/PageName';

export const getCurrentRoute = (context: GoBackContext) => context.routerState.at(-1);

export const getPreviousRoute = (context: GoBackContext) => context.routerState.at(-2);

export const matchRouteByName = (currentRoute: RouteLocationNormalizedLoaded, routeName: PageName) =>
  currentRoute.matched[0].name === routeName;
