import { stripLinkHref, normalizeTo } from '@/utils/routerUtil';
import { computed, ref } from 'vue';
import { UseLinkOptions, useLink, useRoute } from 'vue-router';

export default (props?: UseLinkOptions) => {
  const link = props ? useLink({ ...props, to: normalizeTo(props?.to as string) }) : undefined;
  const route = useRoute();

  const activeChildren = ref<Map<number, boolean>>(new Map());
  const setActiveChild = (id: number, value: boolean) => activeChildren.value.set(id, value);

  const paths = computed(() => (route.query.paths as string)?.split(','));
  const pathActiveLink = computed(
    () =>
      paths.value?.some((path) => {
        return path === stripLinkHref(props?.to as string);
      }),
  );

  const exactActiveLink = computed(() => link?.isExactActive?.value || pathActiveLink?.value);
  const activeLink = computed(
    () => exactActiveLink.value || link?.isActive?.value || [...activeChildren.value.values()].some((entry) => entry),
  );

  return { activeLink, exactActiveLink, pathActiveLink, setActiveChild };
};
