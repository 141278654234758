import useMessageBanner from '@/services/useMessageBanner';

export const requestWarning = (e: unknown) =>
  console.warn('WARNING! Something must have gone wrong with importing data from Erudio:', e);

const withErrorCatch = async (callback: () => unknown, identifier?: string): Promise<unknown> => {
  try {
    const cb = await callback();
    useMessageBanner().removeMessage(identifier);

    return cb;
  } catch (error) {
    requestWarning(error);
    useMessageBanner().addMessage(error.message || error.stack, identifier);
  }
};

export default withErrorCatch;
