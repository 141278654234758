import { Ref, ref } from 'vue';
import { CategoryModel } from '@/model/page/Category';
import { GenericContent } from '@/model/page/Content';
import useContent from '@/services/useContent';
import { LearningPathModel } from '@/model/page/LearningPath';
import useError, { ResponseErrorStatusCode } from '@/services/useError';

export interface PageProps {
  pathMatch?: string[];
  step?: string;
}

export interface UsePage<
  T extends GenericContent,
  P extends GenericContent = CategoryModel,
  L extends GenericContent = LearningPathModel,
> {
  currentContent: Ref<T>;
  currentParent: Ref<P>;
  currentLearningPath: Ref<L>;
  fetchContent: (slugOrId: string, locale: string, includeChildren?: boolean) => Promise<void>;
  fetchParent: (slugOrId: string, locale: string, includeChildren?: boolean) => Promise<void>;
  fetchLearningPath: (slug: string, locale: string, includeChildren?: boolean) => Promise<void>;
  fetchForPage: (
    props: { pathMatch?: string[]; structureId?: string; parentId?: string },
    locale: string,
  ) => Promise<void>;
  unsetParent: () => Promise<void>;
}

const { showErrorWith } = useError();

const currentContent = ref<GenericContent>();
const currentParent = ref<GenericContent>();
const currentLearningPath = ref<GenericContent>();

const setup = <
  T extends GenericContent,
  P extends GenericContent = CategoryModel,
  L extends GenericContent = LearningPathModel,
>(): UsePage<T, P, L> => {
  const { resolveContent } = useContent();
  const unsetParent = async (): Promise<void> => {
    currentParent.value = null;
    return Promise.resolve();
  };

  const genericFetch = async <X extends GenericContent>(
    target: Ref<X>,
    contentId: string,
    language: string,
    includeChildren: boolean,
    throwError: boolean,
  ): Promise<void> => {
    if (target.value?.id !== contentId) {
      target.value = null;
      target.value = (await resolveContent(contentId, language, includeChildren)) as X;
    }

    if (throwError && target.value.contentType === 'error') {
      showErrorWith(target.value.id.toString() as ResponseErrorStatusCode);
    }
  };

  const fetchContent = async (contentId: string, language: string, includeChildren: boolean): Promise<void> => {
    await genericFetch(currentContent, contentId, language, includeChildren, true);
  };

  const fetchParent = async (contentId: string, language: string, includeChildren = false): Promise<void> => {
    await genericFetch(currentParent, contentId, language, includeChildren, false);
  };

  const fetchLearningPath = async (contentId: string, language: string, includeChildren = false): Promise<void> => {
    await genericFetch(currentLearningPath, contentId, language, includeChildren, true);
  };

  const fetchForPage = async (props: PageProps, locale: string): Promise<void> => {
    if (props.pathMatch) {
      await Promise.all([
        fetchParent(props.pathMatch.slice(0, -1).join('/'), locale),
        fetchContent(props.pathMatch.join('/'), locale, true),
      ]);
    } else {
      throw "Can't find what you are looking for";
    }
  };

  return {
    currentContent: currentContent as Ref<T>,
    currentParent: currentParent as Ref<P>,
    currentLearningPath: currentLearningPath as Ref<L>,
    unsetParent,
    fetchContent,
    fetchParent,
    fetchLearningPath,
    fetchForPage,
  };
};

export default setup;
