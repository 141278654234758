<template>
  <div :class="['c-base-select-item', { hidden }]">
    <button :class="['c-base-select__item', { active }]" @click="emit('click', $event)">
      <span v-if="hasSlotContent(slots.iconPrefix)" class="c-base-select-item__icon c-base-select-item__icon--prefix">
        <slot name="iconPrefix" />
      </span>
      <span class="c-base-select-item__name">{{ name || value }}</span>
      <span v-if="hasSlotContent(slots.iconSuffix)" class="c-base-select-item__icon c-base-select-item__icon--suffix">
        <slot name="iconSuffix" />
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { hasSlotContent } from '@/utils/hasSlotContent';
import { useSlots } from 'vue';

export interface BaseSelectItemInterface {
  name?: string;
  value: string;
  active?: boolean;
  hidden?: boolean;
}

defineProps<BaseSelectItemInterface>();
const emit = defineEmits<{
  click: [event: MouseEvent];
}>();
defineSlots<{
  iconPrefix(): unknown;
  iconSuffix(): unknown;
}>();

const slots = useSlots();
</script>
