<template>
  <BaseHeader with-menu :show-actions="showActions">
    <BaseButton reset class="c-header-subpage__button" data-test="subpage-header-back-button" @click="goToParentRoute">
      <span class="c-header-subpage__icon-wrapper"><BackIcon class="c-header-subpage__icon" /></span>
      <span class="c-header-subpage__text">{{ $t('HEADER_BAR.BACK') }}</span>
    </BaseButton>
    <template #actions>
      <HeaderSearch />
      <HeaderUserProfile />
    </template>
  </BaseHeader>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseHeader from '@/modules/header/BaseHeader.vue';
import HeaderUserProfile from '@/modules/header/partials/HeaderUserProfile.vue';
import HeaderSearch from '@/modules/header/partials/HeaderSearch.vue';
import { goToParentRoute } from '@/router';
import BaseButton from '@/elements/button/BaseButton.vue';
import BackIcon from '@/assets/icons/BackIcon.vue';
import useNamespace from '@/services/useNamespace';
import { Theme } from '@/model/Theme';

const { namespace } = useNamespace();

const showActions = computed(() => namespace.themeMetadata.theme !== Theme.ADVANCED);
</script>
