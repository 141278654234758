<template>
  <GenericCard :class="['c-card', classObject]" :card="card" :variant="variant" role="button" v-bind="$attrs">
    <slot name="head">
      <div v-if="card.categoryKeyword || isExternalLink" class="c-card__head">
        <CardLabel
          v-if="card.categoryKeyword || isExternalLink"
          :content-type="(isSimple || isLearningPath || isExternalLink) && props.card?.type"
          :label="isExternalLink ? $t('CARD.EXTERNAL_LABEL') : card.categoryKeyword"
          show-icon
          show-label
          class="c-card__type"
        >
          {{ card.categoryKeyword }}
        </CardLabel>
        <CardLearningPathBadge v-if="card?.subject" class="c-card__subject">
          {{ card.subject }}
        </CardLearningPathBadge>
        <TimeLabel v-if="card?.duration && !card.multimedia" class="c-card__time" :duration="card.duration" />
      </div>
    </slot>
    <slot name="body">
      <div class="c-card__body">
        <CardImage v-if="card.image" :image="card.image" :multimedia="card.multimedia" />
        <div class="c-card__detail">
          <LevelRating
            v-if="card.level && card.useLevels"
            :level="card.level"
            :max-level="card.maxLevel"
            class="c-card__level"
          />
          <MetaLabel v-if="card?.pages && showExtendedView" :label="$t('LEARNING_PATH.META.PAGES')">
            {{ card.pages }}
          </MetaLabel>
          <TimeLabel
            v-if="card?.duration && showExtendedView"
            class="c-card__time"
            :duration="card.duration"
            text-before-duration="EXERCISE.META.TIME"
          />
        </div>
        <AudioPlayer
          v-if="showAudio"
          class="c-card__audio"
          :show-controls="false"
          :audio-factory="factory"
          :variant="AudioPlayerVariants.THEMED"
        />
        <div v-if="card.title" class="c-card__title">
          {{ card.title }}
        </div>
        <div v-if="card.subtitle" class="c-card__subtitle">
          {{ card.subtitle }}
        </div>
        <Badge v-if="card.badge" class="c-card__badge">
          {{ card.badge }}
        </Badge>
        <RichText v-if="card.text" class="c-card__text" :version="RichTextVersion.CARD" :content="card.text" />
        <CardSkillTags v-if="card.skillTags && showExtendedView" :skill-tags="card.skillTags" />
      </div>
      <CardFile v-if="card.file" class="c-card__file" :file="card.file" />
    </slot>
    <slot name="footer">
      <div class="c-card__footer">
        <CardProgression v-if="showProgression" :progress="card.progress" :disabled="progressionDisabled" />
        <CardHorizontalHoverBlock
          v-if="!isLearningPath && card.type !== ContentTypeEnum.DOCUMENT"
          :to="card.to"
          :type="card?.type"
        />
      </div>
    </slot>
  </GenericCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import GenericCard from '@/components/card/partials/GenericCard.vue';
import CardLabel from '@/components/card/partials/CardLabel.vue';
import RichText, { RichTextVersion } from '@/components/rich-text/RichText.vue';
import Badge from '@/elements/badge/BaseBadge.vue';
import { CardInterface } from '@/model/Card';
import LevelRating from '@/elements/level-rating/LevelRating.vue';
import CardImage from '@/components/card/partials/CardImage.vue';
import CardFile from '@/components/card/partials/CardFile.vue';
import CardProgression from '@/components/card/partials/CardProgression.vue';
import useNamespace from '@/services/useNamespace';
import TimeLabel from '@/elements/time-label/TimeLabel.vue';
import CardHorizontalHoverBlock from '@/components/card/partials/CardHorizontalHoverBlock.vue';
import { ContentCardVariants } from '@/model/response/Settings';
import AudioPlayer, { Variants as AudioPlayerVariants } from '@/components/multimedia/audio/AudioPlayer.vue';
import useSynthesis from '@/components/multimedia/audio/text-to-speech/useSynthesis';
import CardLearningPathBadge from '@/components/card/partials/CardLearningPathBadge.vue';
import { ContentTypeEnum } from '@/config/ContentType';
import useLocalization from '@/services/useLocalization';
import CardSkillTags from '@/components/card/partials/CardSkillTags.vue';
import MetaLabel from '@/elements/meta-label/MetaLabel.vue';
import useUserMode, { UserModeType } from '@/services/useUserMode';
import usePermissions from '@/services/usePermissions';

interface CardComponentProps {
  card: CardInterface;
  variant?: string;
}

const props = defineProps<CardComponentProps>();

const { namespace } = useNamespace();
const { locale } = useLocalization();
const { isAnonymous } = usePermissions();

const variant = computed(() => props.variant || namespace.settings.cardStyle);
const contentType = computed(() => props.card.type);
const showProgression = computed(
  () =>
    (useUserMode().mode.value === UserModeType.STUDENT ||
      contentType.value === ContentCardVariants.TEACHER_DASHBOARD) &&
    props.card.progress,
);
const isSimple = computed(() => variant.value === ContentCardVariants.SIMPLE);
const isLearningPath = computed(
  () =>
    contentType.value === ContentTypeEnum.LEARNING_PATH || contentType.value === ContentTypeEnum.LEARNING_PATH_ADVANCE,
);
const isExternalLink = computed(() => contentType.value === ContentTypeEnum.EXTERNAL);
const showExtendedView = computed(() => contentType.value === ContentCardVariants.LEARNING_PATH_ADVANCE);
const factory = computed(() => useSynthesis().setup(props.card.title, locale.value));
const showAudio = computed(() => props.card.audio && isSimple && !showExtendedView.value && !!factory.value);
const progressionDisabled = computed(() => isAnonymous());

const classObject = {
  ...(variant.value ? { [`c-card--${variant.value}`]: Boolean(variant.value) } : {}),
  ...(contentType.value ? { [`c-card--${contentType.value}`]: Boolean(contentType.value) } : {}),
};
</script>
