export const applyTranslations = (defaultContent, translatedContent?) => {
  if (!translatedContent) {
    return defaultContent;
  }

  if (typeof defaultContent === 'object' && defaultContent !== null) {
    if (Array.isArray(defaultContent) && Array.isArray(translatedContent)) {
      if (typeof translatedContent[0] === 'object') {
        if (translatedContent[0]._id) {
          // if there is _id, we match objects by this field
          return defaultContent.map((item) =>
            applyTranslations(
              item,
              translatedContent.find((translation) => item._id === translation._id),
            ),
          );
        } else {
          // else we match by index
          return defaultContent.map((item, index) => applyTranslations(item, translatedContent[index]));
        }
      } else {
        // we do not merge arrays of simple types
        return translatedContent.length ? translatedContent : defaultContent;
      }
    }

    return Object.keys(defaultContent).reduce(
      (result, currentKey) => ({
        ...result,
        [currentKey]: applyTranslations(defaultContent[currentKey], translatedContent[currentKey]),
      }),
      {},
    );
  }
  return translatedContent || defaultContent;
};
