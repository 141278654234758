export const KEYBOARD_KEYS = {
  space: ['spacebar', ' '],
  enter: ['enter'],
};

export const KEYS_SPACEBAR_ENTER = [...KEYBOARD_KEYS['space'], ...KEYBOARD_KEYS['enter']];

export const onKey =
  (keys: string[], fn: () => void) =>
  (event: KeyboardEvent): void => {
    if (keys.includes(event.key.toLocaleLowerCase())) {
      event.preventDefault();
      event.stopPropagation();
      fn();
    }
  };

export const onEnterSpace =
  (fn: () => void) =>
  (event: KeyboardEvent): void => {
    onKey(KEYS_SPACEBAR_ENTER, fn)(event);
  };

export const onEscape =
  (fn: () => void) =>
  (event: KeyboardEvent): void => {
    onKey(['escape'], fn)(event);
  };
