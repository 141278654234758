<template>
  <div v-if="downloadURL" class="c-card-hover-block__download">
    <DownloadIcon class="c-card-hover-block__download-icon" />
    <span class="c-card-hover-block__download-text">{{ $t('CARD.DOWNLOAD') }}</span>
  </div>
  <div v-else :class="['c-card-hover-block__go-to', { 'c-card-hover-block__go-to--external': isExternal }]">
    <component :is="icon" v-if="!isExternal" class="c-card-hover-block__go-to-icon" />
    <span :class="['c-card-hover-block__go-to-text', { 'c-card-hover-block__go-to-text--external': isExternal }]">
      {{ label }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import DownloadIcon from '@/assets/icons/DownloadIcon.vue';
import BoldArrowIcon from '@/assets/icons/BoldArrowIcon.vue';
import FullscreenIcon from '@/assets/icons/FullscreenIcon.vue';
import { useI18n } from 'vue-i18n';

interface CardHorizontalHoverBlockProps {
  downloadURL?: string;
  type?: string;
  to?: string;
}

const props = withDefaults(defineProps<CardHorizontalHoverBlockProps>(), {
  downloadURL: null,
  type: null,
  to: null,
});

const { t } = useI18n();
const isRoute = !!props.to;
const isExternal = props.type === 'externalLink';
const icon = isRoute ? BoldArrowIcon : FullscreenIcon;
const label = isExternal ? t('CARD.EXTERNAL_BUTTON') : isRoute ? t('CARD.GO_TO') : t('CARD.MODAL');
</script>
