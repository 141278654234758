const TEXT_DIRECTION = {
  RTL: 'rtl',
  LTR: 'ltr',
}

const rtlLanguageValues = ['ar', 'ps', 'ku']

const availableLanguages = [
  {
    text: 'Bokmål',
    value: 'nb',
  },
  {
    text: 'Nynorsk',
    value: 'nn',
  },
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Arabic',
    value: 'ar',
  },
  {
    text: 'Dari',
    value: 'fa-AF',
  },
  {
    text: 'Kurdish',
    value: 'ku',
  },
  {
    text: 'Pashto',
    value: 'ps',
  },
  {
    text: 'Polish',
    value: 'pl',
  },
  {
    text: 'Somali',
    value: 'so',
  },
  {
    text: 'Swahili',
    value: 'sw',
  },
  {
    text: 'Tigrinya',
    value: 'ti',
  },
  {
    text: 'Turkish',
    value: 'tr',
  },
]

export default availableLanguages.map((language) => {
  language.dir = rtlLanguageValues.includes(language.value)
    ? TEXT_DIRECTION.RTL
    : TEXT_DIRECTION.LTR
  return language
})
