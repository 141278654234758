import { AccordionModel } from '@/model/content';
import { TextToSpeechContext, TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';
import { ChildContentType } from '@/constants/ChildContentType';
import { ChildrenContent } from '@/model/response';
import { concatContent } from '@/services/useContent/helper/concatContent';

class AccordionMapper implements TextToSpeechMapper {
  mapContent(content: AccordionModel, context: TextToSpeechContext): string {
    return concatContent(
      content?.accordions
        ? content.accordions.flatMap((accordion) => [
            context.translate('CONTENT_TYPE.ACCORDION'),
            accordion.title,
            ...accordion.items.map((item) =>
              context.mapContentToText(item._type as ChildContentType, item as unknown as ChildrenContent),
            ),
          ])
        : [],
    );
  }
}

export const accordionMapper = new AccordionMapper();
