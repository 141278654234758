import { query } from '@/queries/gql/AllStructures.query.gql';
import { useGraphQL } from '@/core';
import { TreeNode as RawTreeNode } from '@/model/response/TreeNode';

const { fetchObject, getObjectById } = useGraphQL();

export const treeStructureQuery = async (locale: string, page: number, limit: number) => {
  return fetchObject(query(page, limit), { locale })
    .then(() => getObjectById<{ structures: { data: RawTreeNode[] } }>(query(page, limit), '', { locale }))
    .then((data) => data?.structures.data);
};
