import katex from 'katex';
import 'katex/dist/katex.min.css';

const KATEX_CLASS_NAME = 'katex';

export default {
  beforeMount(element: HTMLElement) {
    const katexNodes = Array.from(element.querySelectorAll(`.${KATEX_CLASS_NAME}`));

    if (!katexNodes.length) return;

    katexNodes.forEach((element: HTMLElement) => {
      const latex = element.textContent;

      katex.render(latex, element, {
        throwOnError: false,
      });
    });
  },
};
