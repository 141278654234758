<template>
  <div v-if="file" class="c-card-file">
    <div class="c-card-file__document-data">
      <DocumentIcon class="c-card-file__icon" />
      <span class="c-card-file__format">{{ file.fileType }}</span>
      <span class="c-card-file__size">{{ size }}</span>
    </div>
    <BaseButton class="c-card-file__download-button" outline @click="() => fileDownload(file.filename, file.url)">
      <DownloadIcon class="c-card-file__download-button-icon" />
      <span>{{ $t('DOCUMENT_CARD.DOWNLOAD') }}</span>
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DocumentIcon from '@/assets/icons/DocumentIcon.vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import DownloadIcon from '@/assets/icons/DownloadIcon.vue';
import { CardDocumentFile } from '@/model/Card';
import fileSize from '@/utils/fileSize';
import { fileDownload } from '@/utils/fileDownload';

interface CardFileProps {
  file: CardDocumentFile;
  directDownload?: boolean;
}

const props = defineProps<CardFileProps>();

const size = computed(() => fileSize(props.file?.size));
</script>
