export default {
  CARD_LIST: {
    NO_DATA: {
      DEFAULT: 'Det er ikke noe å vise',
      HEADING: 'Ingen data!',
      INFO: 'Mulig årsak:',
      CAUSE_1: 'Du har ingen elever tilknyttet din bruker.',
      CAUSE_2: 'Ingen elever har fullført noen læringsstier ennå. Trenger du hjelp, kontakt oss på:',
    },
  },

  HEADER_BAR: {
    TITLE: 'Språk og innlogging',
    BACK: 'Tilbake',
    OPEN_MODAL_BUTTON: 'Innhold',
    MAIN_LANGUAGE: 'Hovedspråk',
    MAIN_LANGUAGE_SUBTEXT: 'Endrer språk i navigasjon og innhold',
    MOTHER_TONGUE: 'Morsmål',
    MOTHER_TONGUE_SUBTEXT: 'Endrer språk kun for oversatt tekst',
    SELECT_MOTHER_TONGUE: 'Velg Morsmål',
    DESELECT: 'Fjern valg',
    HOME: 'Hjem',
    GO_TO_CONTENT: 'Hopp til hovedinnhold',
  },

  OPEN_MODAL: {
    CAPTION: 'Vis',
  },

  SEARCH_FIELD: {
    PLACEHOLDER: {
      SEARCH: 'Søk i alt',
      FILTER: 'Filtrer',
      FILTER_CONTENT: 'Filtrer innhold',
    },
    FILTER: {
      PLACEHOLDER: 'Søkefilter',
    },
  },

  ARIA: {
    CLOSE_MODAL: 'Lukk',
  },

  FOOTER: {
    PRIVACY: 'Personvern',
    CONTACT: 'Kontakt oss',
  },

  IMAGE_MODAL: {
    HEADER: 'Bilde',
    FIT_SCREEN: 'Tilpasset',
    FULL_WIDTH: 'Full bredde',
  },

  LEVEL_MODAL: {
    CLOSE: 'Lukk',
  },

  FACT_BOX: {
    HIDE: 'Skjul',
    READ_MORE: 'Les mer',
  },

  LANGUAGE: {
    en: 'English',
    nn: 'Nynorsk',
    nb: 'Bokmål',
    ar: 'Arabic',
    // Note: This is subject to change, `Dari` is just available in ISO standard in projects
    'fa-AF': 'Dari',
    ku: 'Kurdish',
    ps: 'Pashto',
    pl: 'Polish',
    so: 'Somali',
    sw: 'Swahili',
    ti: 'Tigrinya',
    tr: 'Turkish',
  },

  SEARCH: {
    DURATION: 'Tid',
    TOTAL: 'Treff',
    EXPAND: 'Vis alle treff',
  },

  VIDEO: {
    READ_TRANSCRIPTION: 'Vis teksten',
    CHAPTERS: 'Kapitler',
    TEXT_IN_VIDEO: 'Transkripsjon',
  },

  CHAPTERS: {
    HIDE: 'Skjul kapitler',
    SHOW: 'Vis kapitler',
  },

  LEVEL_RATING: {
    LABEL: 'Nivå:',
  },

  HOME: {
    LAST_USED: 'Sist brukt',
  },

  AUDIO_PLAYER: {
    LISTEN: 'Lytt til teksten',
    ERROR_NOT_ALLOWED: 'Lyden er klar, Lytt til teksten.',
    SPEED: {
      SLOWLY: 'Langsomt',
      QUICKLY: 'Hurtig',
    },
  },

  LOGOUT: {
    TEXT: 'Logg ut',
  },

  LOGIN: {
    TEXT: 'Logg in',
  },

  FILTERS: {
    LABEL: 'Filtrer',
    LEVELS_LABEL: 'Nivå',
    SKILLS_LABEL: 'Ferdigheter',
    ALL: 'Alle',
    NO_LEVELS: 'Ingen nivåer',
    ARTICLES: 'Tekster',
    TASKS: 'Oppgaver',
    CLOSE: 'Lukk',
    DOCUMENT: 'Dokument',
    CATEGORY: 'Kategori',
    STUDENTS: 'Elever',
    CLASSES: 'Klasser',
    GROUPS: 'Grupper',
    RESET: 'Nullstill filter',
    SUBJECT_CONTENT: 'Faginnhold',
    CHOOSE_SUBJECT: 'Velg fag',
    CHOOSE_CONTENT: 'Velg innhold',
    CHOOSE_SKILLS: 'Velg ferdigheter',
    MORE_FILTERS: 'Flere filtre',
  },

  VIDEO_BLOCK: {
    DEFAULT_TITLE: 'Video',
  },

  DURATION_TIME: {
    LABEL: 'Varighet:',
  },

  CARD: {
    EXTERNAL_LABEL: 'Ekstern lenke',
    EXTERNAL_BUTTON: 'Åpne i ny fane',
    GO_TO: 'Gå til',
    MODAL: 'Les mer',
    DOWNLOAD: 'Last ned',
    OPEN: 'Åpne',
    CHAPTER: {
      GO_TO_TOPIC: 'Gå til tema',
      START: 'Start',
      CONTINUE: 'Fortsett',
      RETAKE: 'Ta på nytt',
      TASKS: 'oppgaver',
      NOT_STARTED: 'Ikke startet',
    },
  },

  ARTICLE: {
    CHAPTER: 'Kategori',
    SUBJECT: 'Underkategori',
    AUTHOR: 'Forfatter',
    GOALS: 'Kompetansemål',
    TEST_YOUR_KNOWLEDGE: 'Test dine kunnskaper',
  },

  COMPETENCY_AIMS: {
    HEADER: 'Kompetansemål',
  },

  TIME_LABEL: {
    LABEL: 'Ca. lesetid',
    SHORT_LABEL: 'Tid',
    DAYS: '{ count } dag | { count } dager',
    HOURS: '{ count } time | { count } timer',
    MINUTES: '{ count } minutt | { count } minutter',
    SECONDS: '{ count } sekund | { count } sekunder',
  },

  SEARCH_MODAL: {
    SEARCH: 'Søk',
  },

  CONTENT_TYPE_SELECT: {
    ALL: 'Alle',
    LEARNING_PATHS: 'Læringsstier',
    ALL_CONTENT: 'Alt innhold',
  },

  CONTENT_TYPE: {
    FACTBOX: 'Faktaboks',
    IMAGE: 'Bilde',
    ACCORDION: 'trekkspillmeny',
    CHECKBOX: 'Avmerkningsboks',
  },

  TYPE: {
    ARTICLE: 'Artikkel',
    CONCEPT: 'Begreper',
    EXTERNALLINK: 'Ekstern link',
    VIDEO: 'Video',
    TASK: 'Oppgave',
    DOCUMENT: 'Dokument',
    PROJECT: 'Prosjekt',
    TEST: 'Test',
    MULTIMEDIA: 'Multimedia',
    CATEGORY: 'Kategori',
    LEARNING_PATH: 'Læringssti',
  },

  ERROR: {
    NOT_FOUND_TITLE: 'Noe gikk galt!',
    NOT_FOUND_MESSAGE:
      '<strong>Mulig årsak:</strong><br />' +
      '1. Siden finnes ikke lengre, eller har fått ny url-adresse.<br />' +
      '2. Du må logg inn for å se innholdet  (“Språkvalg og innlogging” øverst til høyre).<br />' +
      '3. Du mangler tilgang til denne siden eller produktet. Kontakt din lærer eller skoleadministrator for hjelp.',
    ACCESS_DENIED_TITLE: 'Du har dessverre ikke tilgang til denne siden',
    ACCESS_DENIED_MESSAGE: 'Enkelte sider er kun tilgjengelig for noen typer brukere.',
    ACCESS_FORBIDDEN_TITLE: 'Du har dessverre ikke tilgang til denne siden',
    ACCESS_FORBIDDEN_MESSAGE: 'Enkelte sider er kun tilgjengelig for noen typer brukere.',
    PAYMENT_REQUIRED_TITLE: 'Du har dessverre ikke tilgang på dette produktet',
    PAYMENT_REQUIRED_MESSAGE:
      'Hør med din lærer eller skoleadministrator hva du kan gjøre for å få tilgang, eller besøk denne siden for mer info',
    HOMEPAGE_LINK: 'Gå til forsiden',
    DEFAULT_TITLE: 'Noe gikk galt!',
    DEFAULT_MESSAGE: 'Finner ikke denne nettsiden.',
  },

  CARDS_CONTAINER: {
    RELATED_LINKS: 'Relatert innhold',
  },

  FILTERS_SELECTOR: {
    BUTTON_TEXT: 'Gå til faginnhold',
  },

  DOCUMENT_CARD: {
    LABEL: 'Dokument',
    DOWNLOAD: 'Last ned',
  },

  EXERCISE: {
    CONTROL_TEXT: 'Metadata',
    META: {
      TIME: 'Tid',
      CURRICULUM_OBJECTIVES: 'Lærerplanmål',
      ASSESSMENT_PROPOSAL: 'Forslag til vurdering',
      GO_TO_ARTICLE: 'Gå til artikkel',
    },
  },

  ARTICLE_NAVIGATION: {
    LABEL: 'Innhold',
  },

  CONCEPT: {
    LABEL: 'Begreper',
  },

  QUESTION_VALIDATION: {
    CORRECT: 'Riktig',
    INCORRECT: 'Feil',
  },

  QUESTION_GROUP: {
    SEE_RESULTS: 'Se ressultat',
  },

  QUESTION_GROUP_FEEDBACK: {
    CORRECT: 'Veldig bra! - Alt er fine!',
    WRONG: 'Unnskyld! Du hadde ikke nok riktige svar.',
    RETRY_GROUP: 'Forsøk på nytt',
    RETRY_TEST: 'Ta testen på nytt',
    HEADER_OUTCOME: 'Har du bestått testen?',
  },

  PROGRESSION: {
    EMPTY_MESSAGE: 'Progresjon på tester vil vises her',
    CONTENTS: {
      HEADER: 'Innhold',
    },
    TESTS: {
      HEADER: 'Tester',
      DESCRIPTION: `Du har utført: {done} av {total} tester`,
    },
  },

  PROGRESSION_INDICATOR: {
    FINISHED: 'Fullført',
    NOT_STARTED: 'Ikke startet',
    NO_DATA: 'Ingen dato er angitt',
    OF: 'av',
  },

  ANSWERS_COUNTER: {
    CORRECT: 'Riktig',
    WRONG: 'Feil',
  },

  MESSAGE_BANNER: {
    ERROR: 'Vi har litt tekniske problemer, prøv igjen senere!',
  },

  LEARNING_PATH: {
    CLOSE: 'Lukk',
    OVERVIEW: {
      SHOW: 'Vis oversikt',
      HIDE: 'Skjul oversikt',
    },
    PROGRESS: {
      STARTED: 'Startet',
      NOT_STARTED: 'Ikke startet',
      START: 'Start',
      FINISHED: 'Fullført',
      VIEW: 'Vis',
      CONTINUE: 'Fortsett',
    },
    LIST: {
      HEADER: 'Fortsett på nye læringsstier',
    },
    NUMBER_OF_TASKS: '{ count } side | { count } sider',
    CONFIRMATION: {
      HEADER: 'Fullført!',
      SUBHEADER: 'Endringene er lagret',
      EXIT: 'Avslutt',
    },
    PROGRESSION: {
      TITLE: 'Du er nesten i mål!',
      ANSWER_ALL_QUESTIONS: 'Ikke fullført',
      TRY_AGAIN: 'Fortsett',
    },
    SUMMARY: {
      HEADER: 'Oppsummering',
      INFO: 'Du må svare på alle spørsmålene',
      COMPLETED: 'Fullført',
    },
    META: {
      SKILLS: 'Ferdigheter',
      PAGES: 'Sider',
    },
    SHOW_CONTENT: 'Vis innhold',
  },

  NAVIGATION_BUTTON: {
    PROCEED: 'Fortsett',
    NEXT: 'Neste',
    PREVIOUS: 'Forrige',
  },

  CONTROLS: {
    CLOSE: 'Lukk',
    OPEN: 'Åpen',
    CANCEL: 'Avbryt',
    ACCEPT: 'Aksepterer',
  },

  TEACHER_DASHBOARD: {
    ADMIN: 'Admin',
    CLOSE: 'Lukk',
    HOME: 'Forside',
    STUDENT_DATA: 'Elevdata',
    VIEW_AS: 'Vis som',
    TEACHER: 'Lærer',
    STUDENT: 'Elev',
    LAST_VISIT: 'Siste',
    BUTTON_TEXT: 'Vis all data',
    TITLE: 'Elevdata',
    SEE_EXERCISES: 'Vis oppgaver',
    SORT: {
      LABEL: 'Sorter',
      ALL: 'Siste aktivitet',
      NOT_STARTED: 'Ikke startet',
      STARTED: 'Pågående',
      FINISHED: 'Fullført',
    },
  },

  TIMEGRAPH: {
    WEEK: 'Uke',
    MONTH: 'Måned',
    HALF_YEAR: '6 mnd',
    YEAR: '12 mnd',
    COMPLETED: 'Fullførte læringsstier',
    DATA_PERIOD: 'Data for siste',
    MONTHS_NAMES: {
      JAN: 'Januar',
      FEB: 'Februar',
      MAR: 'Mars',
      APR: 'April',
      MAY: 'Mai',
      JUN: 'Juni',
      JUL: 'Juli',
      AUG: 'August',
      SEP: 'September',
      OCT: 'Oktober',
      NOV: 'November',
      DEC: 'Desember',
    },
    WEEKDAYS_NAMES: {
      MON: 'Mandag',
      TUE: 'Tirsdag',
      WED: 'Onsdag',
      THU: 'Torsdag',
      FRI: 'Fredag',
      SAT: 'Lørdag',
      SUN: 'Søndag',
    },
    AMOUNT: 'Antall',
  },

  SKILL: {
    READ: 'Lese',
    WRITE: 'Skrive',
    LISTEN: 'Lytte',
    ORAL: 'Muntlig',
  },

  LAST_USED: {
    LEARNING_PATHS: {
      TITLE: 'Sist brukte læringsstier',
      EMPTY_CONTENT: 'Her vil du se dine sist brukte læringsstier',
    },
  },

  USER_INFO: {
    WELCOME: 'Velkommen',
    TIME_SPENT: 'Tid bruks',
    TIME_SPENT_VALUE: '{ hours } t { minutes } min',
    COMPLETED_CHAPTERS: 'Du har gjort',
    CHAPTERS: '{ completed } av { total } kapitler',
    WEEK: 'Uke',
  },
};
