<template>
  <div>
    <BaseChapters :timestamps="timestamps" class="c-chapters-list--expanded" @handle-click="handleClick" />
    <BaseCollapse v-model="isOpen" class="c-chapters-list--collapsible">
      <template #control>
        <BaseButton
          outline
          fluid
          class="c-chapters-list-button__button"
          :class="{ 'c-chapters-list-button__button--shown': isOpen }"
        >
          <span class="c-chapters-list-button__title" :class="{ 'c-chapters-list-button__title--shown': isOpen }">
            {{ $t(`CHAPTERS.${isOpen ? 'HIDE' : 'SHOW'}`) }}
          </span>
          <span class="c-chapters-list-button__icon" :class="{ 'c-chapters-list-button__icon--shown': isOpen }">
            <DropdownIcon />
          </span>
        </BaseButton>
      </template>
      <template #collapsible>
        <BaseChapters :timestamps="timestamps" @handle-click="handleClick"></BaseChapters>
      </template>
    </BaseCollapse>
  </div>
</template>
<script setup lang="ts">
import BaseChapters from '@/components/multimedia/chapters/BaseChapters.vue';
import { Timestamp } from '@/model/content';
import BaseCollapse from '@/elements/collapse/BaseCollapse.vue';
import { ref } from 'vue';
import DropdownIcon from '@/assets/icons/DropdownIcon.vue';
import BaseButton from '@/elements/button/BaseButton.vue';

defineProps<{ timestamps?: Timestamp[] }>();
const emit = defineEmits(['handleClick']);

const handleClick = (time: number) => {
  emit('handleClick', time);
};

const isOpen = ref(false);
</script>
