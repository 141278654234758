import { CONTENT_FRAGMENT, ERROR_FRAGMENT, gql } from './fragments.gql';

export const learningPathQuery = gql`
  ${ERROR_FRAGMENT}
  ${CONTENT_FRAGMENT}
  query queryLearningPathWithLocale($id: String!, $locale: String!) {
    learningPath(id: $id) {
      data {
        id
        name
        forkId
        status
        slug
        namespaceId
        elements {
          data {
            id
            namespaceId
            contentId
            contentType
            description
            content {
              id
              accessLevel
              name
              description
              slug
              slugPath
              contentType
              cover
              parent
              content {
                data {
                  ...ContentFragment
                  localization(locale: $locale)
                }
              }
              tag {
                data {
                  id
                  tags {
                    source
                    keyword
                  }
                }
              }
              localization(locale: $locale) {
                data {
                  locale
                  content
                  service
                  id
                }
                error {
                  ...ErrorFragment
                }
              }
            }
          }
          error {
            ...ErrorFragment
          }
        }
        metadata {
          description
          descriptionInstructor
          author
          coverAlt
          coverId
          coverCopyright
          coverCaption
          bannerId
          bannerAlt
          bannerCopyright
          bannerCaption
          duration
        }
        localization(locale: $locale) {
          data {
            locale
            content
            service
            id
          }
          error {
            ...ErrorFragment
          }
        }
      }
      error {
        ...ErrorFragment
      }
    }
  }
`;
