<template>
  <ModalLayout class="c-search-field-modal">
    <template #header>
      {{ $t('SEARCH_MODAL.SEARCH') }}
    </template>
    <template #body>
      <SearchFieldBar v-model="model" white-background />
      <SearchDropdown
        :is-open="isQuerySearchable"
        :loading="isLoading"
        :total="totalHits"
        :items="items"
        @on-all-results="navigateToSearchPage"
      />
    </template>
  </ModalLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import ModalLayout from '@/layout/modal/ModalLayout.vue';
import SearchFieldBar from '@/components/search/field/SearchFieldBar.vue';
import SearchDropdown from '@/components/search/dropdown/SearchDropdown.vue';
import { useRouter } from 'vue-router';
import { CardInterface } from '@/model/Card';
import { MinSearchInputLength } from '@/utils/search';
import { PageName } from '@/constants/PageName';

interface SearchFieldModalProps {
  totalResults: number;
  loading?: boolean;
  results?: CardInterface[];
}

const model = defineModel<string>();

const props = defineProps<SearchFieldModalProps>();

const router = useRouter();

const isLoading = computed(() => props.loading);
const items = computed(() => props.results);
const totalHits = computed(() => props.totalResults);

const isQuerySearchable = computed(() => model.value.length >= MinSearchInputLength);

const navigateToSearchPage = () => {
  router.push({ name: PageName.SEARCH, query: { query: model.value } });
};
</script>
