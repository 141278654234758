export const ContentTypeTranslationMap = {
  all: 'FILTERS.ALL',
  article: 'TYPE.ARTICLE',
  concept: 'TYPE.CONCEPT',
  externalLink: 'TYPE.EXTERNALLINK',
  task: 'TYPE.TASK',
  document: 'TYPE.DOCUMENT',
  project: 'TYPE.PROJECT',
  test: 'TYPE.TEST',
  'ct-vgs-multimedia': 'TYPE.MULTIMEDIA',
  'category-page': 'TYPE.CATEGORY',
  'learning-path': 'TYPE.LEARNING_PATH',
};

export enum ContentTypeEnum {
  CONCEPT = 'concept',
  CONCEPT_PAGE = 'concept-page',
  ARTICLE = 'article',
  TASK = 'task',
  DOCUMENT = 'document',
  PROJECT = 'project',
  TEST = 'test',
  MULTIMEDIA = 'ct-vgs-multimedia',
  LEARNING_PATH = 'learning-path',
  LEARNING_PATH_ADVANCE = 'learning-path-advance',
  CARD_TEACHER_DASHBOARD = 'teacher-dashboard',
  EXTERNAL = 'externalLink',
  FRONT_PAGE = 'front-page',
}

export enum ContentTypeRouteMap {
  ARTICLE = 'a',
  CATEGORY = 'k',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'CATEGORY-PAGE' = 'k',
  CONCEPT = 'c',
  DOCUMENT = 'd',
  ERROR = 'e',
  EXTERNALLINK = '',
  MODULE = 'o',
  PROJECT = 'p',
  TASK = 't',
  TEST = 'x',
  'LEARNING-PATH' = 'l',
  'CT-VGS-MULTIMEDIA' = 'm',
  'TEACHER-CATEGORY' = 'ct',
  'TEACHER-DASHBOARD' = 'td',
  'REPLACE' = 'replace',
  'CATEGORY-PAGE-WITH-BLOCKS' = 'kb',
}

export const SupportedContentTypes = Object.values(ContentTypeEnum);
