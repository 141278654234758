import useAuth from '../useAuth'

const generateBeforeEach = (isOpenAccess) => (to, from) => {
  const auth = useAuth()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.instance().isAuthenticated() && auth.token.value && typeof auth.token.value !== 'undefined') {
      auth.setToken()
      auth.setUser()
    } else {
      return auth.instance().checkToken()
        .then(() => {
          auth.setToken()
          auth.setUser()
        })
        .catch(() => {
          if (!isOpenAccess(to, from)) {
            return {path: '/login'}
          }
        })
    }
  }
}

const beforeEach = generateBeforeEach(() => false);

export {
  beforeEach,
  generateBeforeEach
}
