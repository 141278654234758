import { ALL_ID, FilterTags } from '@/config/Constants';
import { SupportedContentTypes } from '@/config/ContentType';
import { NO_LEVELS_LEVEL } from '@/mapper';
import {
  LevelsQueryPartial,
  NoLevelsQueryPartial,
  SearchQuery,
  SearchQueryOptions,
  SearchStateFilterEntry,
  SearchStateInterface,
  TagTerms,
} from '@/types/search';
import { makeTagPath } from '@/utils/search';
import { preparePaths, prepareSinglePath } from '@/queries/search/preparePaths';
import { prepareFilters } from '@/queries/search/prepareFilters';
import { filterToTagQuery } from '@/queries/search/filterToTagQuery';

const FULL_TEXT_SEARCH_FIELDS = ['title', 'texts'];

const boostField = (field: string, value: number) => `${field}^${value}`;

const requiredCategoryTagQueryPartial = {
  bool: {
    should: [
      {
        term: {
          kind: 'concept',
        },
      },
      {
        exists: {
          field: makeTagPath(FilterTags.Category),
        },
      },
    ],
  },
};

export const prepareTextQuery = (query: string, options: SearchQueryOptions) => {
  const textQueryPartialAll = {
    match_all: {},
  };

  const wildcardQueryString = `*${query}*`;

  const textQueryPartial = query.length
    ? {
        multi_match: {
          query: wildcardQueryString,
          fields: [boostField(FULL_TEXT_SEARCH_FIELDS[0], 3), FULL_TEXT_SEARCH_FIELDS[1]],
          type: 'phrase_prefix',
          operator: 'and',
        },
      }
    : textQueryPartialAll;

  return {
    must: [
      textQueryPartial,
      {
        terms: {
          kind: options.supportedContentTypes,
        },
      },
      requiredCategoryTagQueryPartial,
    ],
  };
};

const prepareLevels = (levels: Record<string, Record<string, boolean>>) => {
  const levelsKeys = Object.keys(levels);

  const tagLevels = levelsKeys.reduce<TagTerms>(
    (mappedFilterTag, key) => ({
      ...mappedFilterTag,
      ...filterToTagQuery(levels[key], key),
    }),
    {},
  );

  const levelTags = tagLevels[makeTagPath(levelsKeys[0])];
  const noLevelTagIndex = levelTags?.indexOf(NO_LEVELS_LEVEL.id);
  const includeNoLevels = levelTags?.length && noLevelTagIndex >= 0;

  if (includeNoLevels) {
    levelTags?.splice(noLevelTagIndex, 1);
  }

  const noLevelsQueryPartial: NoLevelsQueryPartial = {
    bool: {
      must_not: [
        {
          exists: {
            field: makeTagPath(levelsKeys[0]),
          },
        },
      ],
    },
  };

  const levelsQueryPartial: LevelsQueryPartial = {
    terms: tagLevels,
  };

  return {
    bool: {
      should: [...(levelTags?.length ? [levelsQueryPartial] : []), ...(includeNoLevels ? [noLevelsQueryPartial] : [])],
    },
  };
};

const prepareCategory = (contentType: string) => {
  return contentType === ALL_ID
    ? []
    : [
        {
          terms: {
            [makeTagPath(FilterTags.Category)]: [contentType],
          },
        },
      ];
};

const prepareSkills = (skillsState: SearchStateFilterEntry) => {
  const skills = Object.keys(skillsState).filter((key) => skillsState[key]);

  return skills.length
    ? [
        {
          terms: {
            [makeTagPath(FilterTags.Skills)]: skills,
          },
        },
      ]
    : [];
};

export const preparePath = (pathFragment: string): unknown => {
  return {
    regexp: {
      path: {
        value: `${pathFragment}[^\\/]*`,
        flags: 'ALL',
      },
    },
  };
};

export const prepareTags = (state: Partial<SearchStateInterface>, options?: SearchQueryOptions) => {
  const levelTagsPartial = state.levels ? prepareLevels(state.levels) : null;
  const filterTagsPartial = state.filters ? prepareFilters(state.filters) : [];
  const categoryTagsPartial = prepareCategory(state.contentType);
  const skillTagsPartial = state.skills ? prepareSkills(state.skills[FilterTags.Skills]) : [];
  const pathsPartial =
    options.pathFilterType === 'single' ? prepareSinglePath(state.paths, false) : preparePaths(state.paths);

  return {
    filter: {
      bool: {
        must: [
          levelTagsPartial,
          ...filterTagsPartial,
          ...categoryTagsPartial,
          ...skillTagsPartial,
          ...pathsPartial,
          ...(options?.filterByPath ? [preparePath(options.filterByPath)] : []),
        ].filter(Boolean),
      },
    },
  };
};

export const setupHighlights = () => {
  return {
    highlight: {
      pre_tags: ['<em>'],
      post_tags: ['</em>'],
      tags_schema: 'styled',
      fields: FULL_TEXT_SEARCH_FIELDS.reduce(
        (fieldObject, fieldName) => ({
          ...fieldObject,
          [fieldName]: {},
        }),
        {},
      ),
    },
  };
};

export const setSourceExcludes = (options?: SearchQueryOptions): string[] => [
  'texts',
  ...(options?.sourceExcludes ?? []),
];

export const searchQuery =
  (queryOptions?: SearchQueryOptions) =>
  (state: SearchStateInterface): SearchQuery => {
    const options: SearchQueryOptions = {
      pageSize: 15,
      minScore: 0.1,
      supportedContentTypes: state.teacherSelection
        ? [state.teacherSelection]
        : state.supportedContentTypes || SupportedContentTypes,
      sort: state?.sort ?? [{ _score: 'desc' }, { id: 'asc' }],
      ...queryOptions,
    };

    return {
      _source_excludes: setSourceExcludes(options),
      size: options.pageSize,
      min_score: options.minScore?.toString(),
      query: {
        bool: {
          ...prepareTextQuery(state.query, options),
          ...prepareTags(state, options),
        },
      },
      ...setupHighlights(),
      sort: options.sort,
    };
  };
