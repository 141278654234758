<template>
  <BaseHeader with-menu>
    <BaseLink class="c-header-standard__home" :to="'/'" data-test="standard-header-link-button">
      <span v-if="imageSrc" class="c-header-standard__logo" data-test="standard-header-app-logo">
        <img class="c-logo-icon" :src="imageSrc" :alt="namespace.settings.appLogo?.altText" />
      </span>
      <h1 v-if="showName || !imageSrc" class="c-header-standard__title" data-test="standard-header-app-title">
        {{ namespace.settings?.title || namespace.name }}
      </h1>
    </BaseLink>
    <template #actions>
      <HeaderSearch />
      <HeaderUserProfile />
    </template>
  </BaseHeader>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import useNamespace from '@/services/useNamespace';
import useMedia, { Breakpoint } from '@/services/useScss';
import BaseHeader from '@/modules/header/BaseHeader.vue';
import HeaderUserProfile from '@/modules/header/partials/HeaderUserProfile.vue';
import BaseLink from '@/elements/link/BaseLink.vue';
import { AppLogo } from '@/model/response/Settings';
import useCachedAssets from '@/services/useCachedAssets';
import HeaderSearch from '@/modules/header/partials/HeaderSearch.vue';

const { getAsset } = useCachedAssets();
const { namespace } = useNamespace();
const { useMediaQuery, windowWidth } = useMedia();
const imageSrc = ref<string>('');
const showName = ref<boolean>(namespace.settings.appLogo?.showName);
const appLogo = computed<AppLogo>(() => namespace.settings.appLogo);

const fetchLogo = async (imageId) => {
  const image = await getAsset(imageId);
  imageSrc.value = image?.url;
};

watch(
  [windowWidth, appLogo],
  () => {
    if (!appLogo.value) return;

    const { desktopLogo, tabletLogo, mobileLogo } = appLogo.value;

    if (useMediaQuery(Breakpoint.XLARGE).value && desktopLogo?.imageId) {
      fetchLogo(desktopLogo?.imageId);
    } else if (useMediaQuery(Breakpoint.MEDIUM).value && tabletLogo?.imageId) {
      fetchLogo(tabletLogo?.imageId);
    } else {
      fetchLogo(mobileLogo?.imageId);
    }
  },
  {
    immediate: true,
  },
);
</script>
