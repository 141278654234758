<template>
  <div ref="rootElement">
    <div v-show="showControl" @click="toggleCollapse">
      <slot name="control" />
    </div>
    <TransitionExpand :show="isBreakpointReached || modelValue">
      <slot name="collapsible" />
    </TransitionExpand>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import useScss, { Breakpoint } from '@/services/useScss';
import TransitionExpand from '@/elements/transition/TransitionExpand.vue';

interface BaseCollapseProps {
  hideAt?: Breakpoint;
}

const props = defineProps<BaseCollapseProps>();

const { useMediaQuery } = useScss();

const modelValue = defineModel<boolean>();

const rootElement = ref<HTMLDivElement>(null);

const isBreakpointReached = computed(() => props.hideAt && useMediaQuery(props.hideAt as Breakpoint).value);
const showControl = computed(() => !props.hideAt || !isBreakpointReached.value);

const toggleCollapse = () => {
  modelValue.value = !modelValue.value;
};

defineExpose({
  rootElement,
});
</script>
