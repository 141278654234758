<template>
  <label class="c-search-field-bar" :class="{ 'c-search-field-bar--white-background': whiteBackground }">
    <MagnifierIcon
      v-show="!modelValue?.length"
      class="c-search-field-bar__icon"
      :class="{ 'c-search-field-bar__icon--white-background': whiteBackground }"
    />
    <input
      class="c-search-field-bar__input"
      :class="{ 'c-search-field-bar__input--white-background': whiteBackground }"
      data-test="search-field-bar-input"
      type="text"
      minlength="3"
      :value="modelValue"
      :placeholder="$t(placeholder)"
      @input="onInput"
    />
    <CircleCloseIcon v-show="modelValue?.length" class="c-search-field-bar__clear" @click="clearSearch" />
  </label>
</template>

<script lang="ts" setup>
import CircleCloseIcon from '@/assets/icons/CircleCloseIcon.vue';
import MagnifierIcon from '@/assets/icons/MagnifierIcon.vue';
import { minSearchInputLength, SearchDebounceTimeMs } from '@/utils/search';
import { useDebounceFn } from '@vueuse/core';

interface SearchFieldBarProps {
  whiteBackground?: boolean;
  placeholder?: string;
}

withDefaults(defineProps<SearchFieldBarProps>(), { placeholder: 'SEARCH_FIELD.PLACEHOLDER.SEARCH' });

const modelValue = defineModel<string>();

const updateModelValue = (value) => (modelValue.value = value);
const clearSearch = () => updateModelValue('');
const onInput = useDebounceFn(
  (event: Event & { target: HTMLInputElement & { value: number } }) =>
    minSearchInputLength(event.target.value) && updateModelValue(event.target.value),
  SearchDebounceTimeMs,
);
</script>
