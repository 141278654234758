<template>
  <BaseButton class="c-go-to-content" @click="scrollToMain">{{ $t('HEADER_BAR.GO_TO_CONTENT') }}</BaseButton>
</template>

<script setup lang="ts">
import useScrollTo from '@/services/useScrollTo';
import BaseButton from '@/elements/button/BaseButton.vue';

const { scrollToMain } = useScrollTo();
</script>
