import useGraphQL from '@/core/useGraphQL';
import withErrorCatch from '@/utils/withErrorCatch';
import { QueryVariables } from '@/model/response';
import { useMemoize } from '@vueuse/core';

interface UseGraphQL {
  getObject: <T>(query: string, contentId: string, variables?: QueryVariables) => Promise<T>;
}

const { fetchObject, fetchObjectById, getObjectById } = useGraphQL();

const getObject = useMemoize(
  async (query: string, id: string, variables: QueryVariables) => {
    let response = await getObjectById(query, id, variables);

    if (!response) {
      await withErrorCatch(async () => {
        if (id.length > 0) {
          await fetchObjectById(query, id, variables);
        } else {
          await fetchObject(query, variables);
        }

        response = await getObjectById(query, id, variables);
      }, JSON.stringify({ query, id, variables }));
    }

    return response;
  },
  {
    getKey: (query, id, variables) => JSON.stringify({ query, id, variables, userId: localStorage.getItem('token') }),
  },
);

export default (): UseGraphQL => ({
  getObject,
});
