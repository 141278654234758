import { FilterTags } from '@/config/Constants';
import { makeTagPath } from '@/utils/search';

export const progressionQuery = () => {
  return {
    size: 500,
    min_score: 0.1,
    query: {
      bool: {
        must: [
          {
            exists: {
              field: 'body.linkToRelatedTest',
            },
          },
        ],
      },
    },
  };
};

const filtersByCategoryPartialQuery = (slugs: string[]) =>
  slugs.reduce(
    (partialQuery, slug) => ({
      ...partialQuery,
      [slug]: {
        prefix: {
          path: `${slug}/`,
        },
      },
    }),
    {},
  );

export interface Bucket {
  key: string;
  doc_count: number;
}

export interface ProgressionStepsAggregation {
  by_category: {
    buckets: Record<
      string,
      {
        doc_count?: number;
        by_category_tags: {
          doc_count_error_upper_bound?: number;
          sum_other_doc_count?: number;
          buckets: Bucket[];
        };
      }
    >;
  };
}

export const progressionStepsQuery = (categorySlugs: string[]): Record<string, unknown> => {
  return {
    size: 0,
    aggs: {
      // Progression only includes tests that are added to Article in `linkToRelatedTest` field
      by_category: {
        filters: {
          filters: filtersByCategoryPartialQuery(categorySlugs),
        },
        aggs: {
          by_category_tags: {
            terms: {
              field: makeTagPath(FilterTags.Category),
              order: {
                _key: 'asc',
              },
            },
          },
        },
      },
    },
  };
};

export const trackableContentQuery = () => ({
  size: 500,
  query: {
    bool: {
      must: [
        {
          term: {
            'body.trackable.isTrackable': true,
          },
        },
        {
          term: {
            kind: 'learning-path',
          },
        },
      ],
    },
  },
});
