import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export const removeTokenFromUrl = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void => {
  const { token, ...query } = to.query;
  if (token) {
    next({ ...to, query });
  }
  next();
};
