import { ArticleResponseChild, LocaleCollection } from '@/model/response';
import { Content } from '@/model/page/Content';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapContentBlocks = (originalChild: ArticleResponseChild): Content => {
  return {
    contentType: originalChild.contentType,
    content: applyTranslations(originalChild.content?.data?.content, originalChild.content?.data?.localization) || {},
    id: originalChild.content?.data?.id,
    contentId: originalChild.id,
    locales: originalChild.content?.data?.locales as LocaleCollection,
  };
};
