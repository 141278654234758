import { ConceptModel } from '@/model/page/Concept';
import { mapPage } from '@/mapper/Page';
import { ConceptPageResponse } from '@/model/response/Concept';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapConcept = (pageResponse: ConceptPageResponse): ConceptModel => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.content?.data?.content, response.content?.data?.localization);
  return {
    ...mapPage(pageResponse),
    filters: content?.predefinedFilters?.isEnabled ? content?.predefinedFilters?.filters?.list : undefined,
  };
};
