import XAPI from '@xapi/xapi'
// xAPI.js docs: https://www.xapijs.dev/

const auth = () => 'Bearer ' + localStorage.getItem('token')
const xapi = (options) => new XAPI({
  endpoint: `${process.env.VUE_APP_COSS_URL}${options?.lrsPath ?? '/lrs/xapi'}`,
  auth: auth()
})

export default xapi
