import { ContentTypeRouteMap } from '@/config/ContentType';
import { GoBackHandler, ResolvedRoute } from '@/services/useCustomRouter/model';

const wasRedirectUsed = (history: ResolvedRoute[]) =>
  history.at(-2)?.path?.includes(`/${ContentTypeRouteMap['REPLACE']}/`);

const goBack = (history: ResolvedRoute[]) =>
  wasRedirectUsed(history) ? goBack(history.slice(0, -2)) : history.at(-2) ?? '/';

export const previousPageWasRedirectPageHandler: GoBackHandler = {
  isSupported: (context) => wasRedirectUsed(context.routerState),
  handle: async (context) => {
    goBack(context.routerState);
  },
  order: 150,
  name: 'previousPageWasRedirectPageHandler',
};
