<template>
  <div class="e-card-time-badge">
    {{ formattedDuration }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { secondsToString } from '@/utils/date';

interface CardTimeBadgeProps {
  duration: string;
}

const props = defineProps<CardTimeBadgeProps>();

const formattedDuration = computed(() => {
  const initialTime = +props.duration;

  return isNaN(initialTime) ? props.duration : secondsToString(initialTime);
});
</script>
