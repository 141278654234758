export const gql = String.raw;

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on Error {
    message
    status
  }
`;

export const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on Content {
    id
    namespace
    schemaId
    content
    locales
  }
`;
