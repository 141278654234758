import { SearchStateFilter } from '@/types/search';
import { ALL_ID } from '@/config/Constants';
import { filterToTagQuery } from '@/queries/search/filterToTagQuery';

export const prepareFilters = (filters: SearchStateFilter) => {
  const tagFilters = Object.keys(filters).reduce(
    (mappedFilterTag, key) => ({
      ...mappedFilterTag,
      ...(filters[key][ALL_ID] ? {} : filterToTagQuery(filters[key], key)),
    }),
    {},
  );

  return Object.keys(tagFilters).map((key) => ({
    terms: {
      [key]: tagFilters[key],
    },
  }));
};
