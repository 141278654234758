import { ref, Ref } from 'vue';

export const redirectUrl = ref(window.location.origin);
const loginUrl = '/login';

interface UseLogin {
  redirectUrl: Ref<string>;
}

const useLogin = (initialHref?: string): UseLogin => {
  if (initialHref && !initialHref.includes(loginUrl)) {
    redirectUrl.value = initialHref;
  } else if (!window.location.href.includes(loginUrl)) {
    redirectUrl.value = window.location.href;
  }
  return {
    redirectUrl,
  };
};

export default useLogin;
