<template>
  <ul class="c-chapters-list">
    <li
      v-for="(timestamp, idx) in timestamps"
      :key="idx"
      class="c-chapters-timestamp"
      data-test="timestamp"
      :data-name="timestamp.name"
      @click="handleClick(timestamp.time)"
      @keypress="handleClick(timestamp.time)"
    >
      <a class="c-chapters-timestamp__link" target="_blank" rel="noopener noreferrer" tabindex="0">
        <div class="c-chapters-timestamp__wrapper">
          <span class="c-chapters-timestamp__time">{{ formatTime(timestamp.time) }}</span>
          <span class="c-chapters-timestamp__name">{{ timestamp.name }}</span>
        </div>
      </a>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { Timestamp } from '@/model/content';

const formatTime = (seconds: number) => new Date(Math.floor(seconds) * 1000).toISOString().substr(14, 5);

defineProps<{ timestamps?: Timestamp[] }>();
const emit = defineEmits(['handleClick']);

const handleClick = (time: number) => {
  emit('handleClick', time);
};
</script>
