export default {
  CARD_LIST: {
    NO_DATA: {
      DEFAULT: 'There is nothing to show',
      HEADING: 'No data!',
      INFO: 'Possible cause:',
      CAUSE_1: 'You have no students associated with your user.',
      CAUSE_2: 'No students have completed any learning paths yet. If you need help, contact us at:',
    },
  },

  HEADER_BAR: {
    TITLE: 'Language and login',
    BACK: 'Back',
    OPEN_MODAL_BUTTON: 'Contents',
    MAIN_LANGUAGE: 'Main language',
    MAIN_LANGUAGE_SUBTEXT: 'Changes language in navigation and content',
    MOTHER_TONGUE: 'Mother tongue',
    MOTHER_TONGUE_SUBTEXT: 'Changes language of translated text only',
    SELECT_MOTHER_TONGUE: 'Select mother tongue',
    DESELECT: 'Deselect',
    HOME: 'Home',
    GO_TO_CONTENT: 'Go to content',
  },

  OPEN_MODAL: {
    CAPTION: 'SHOW',
  },

  SEARCH_FIELD: {
    PLACEHOLDER: {
      SEARCH: 'Type to search',
      FILTER: 'Filter',
      FILTER_CONTENT: 'Filter content',
    },
    FILTER: {
      PLACEHOLDER: 'Search filters',
    },
  },

  ARIA: {
    CLOSE_MODAL: 'close modal',
  },

  FOOTER: {
    PRIVACY: 'Privacy Policy',
    CONTACT: 'Contact Us',
  },

  IMAGE_MODAL: {
    HEADER: 'Image',
    FIT_SCREEN: 'Fit screen',
    FULL_WIDTH: 'Full width',
  },

  LEVEL_MODAL: {
    CLOSE: 'Close',
  },

  FACT_BOX: {
    HIDE: 'Hide',
    READ_MORE: 'Read more',
  },

  LANGUAGE: {
    en: 'English',
    nn: 'Nynorsk',
    nb: 'Bokmål',
    ar: 'Arabic',
    // Note: This is subject to change, `Dari` is just available in ISO standard in projects
    'fa-AF': 'Dari',
    ku: 'Kurdish',
    ps: 'Pashto',
    pl: 'Polish',
    so: 'Somali',
    sw: 'Swahili',
    ti: 'Tigrinya',
    tr: 'Turkish',
  },

  SEARCH: {
    DURATION: 'time',
    TOTAL: 'hits',
    EXPAND: 'Show all hits',
  },

  VIDEO: {
    READ_TRANSCRIPTION: 'Read transcription',
    CHAPTERS: 'Chapters',
    TEXT_IN_VIDEO: 'Text in video',
  },

  CHAPTERS: {
    HIDE: 'Hide chapters',
    SHOW: 'Show chapters',
  },

  LEVEL_RATING: {
    LABEL: 'Level:',
  },

  HOME: {
    LAST_USED: 'Last used',
  },

  AUDIO_PLAYER: {
    LISTEN: 'Listen to your text',
    ERROR_NOT_ALLOWED: 'Audio is ready, click play to hear the recording.',
    SPEED: {
      SLOWLY: 'Slowly',
      QUICKLY: 'Quickly',
    },
  },

  LOGOUT: {
    TEXT: 'Logout',
  },

  LOGIN: {
    TEXT: 'Sign in',
  },

  FILTERS: {
    LABEL: 'Filter',
    LEVELS_LABEL: 'Level',
    SKILLS_LABEL: 'Skills',
    ALL: 'All',
    NO_LEVELS: 'No levels',
    ARTICLES: 'Articles',
    TASKS: 'Tasks',
    CLOSE: 'Close',
    DOCUMENT: 'Document',
    CATEGORY: 'Category',
    STUDENTS: 'Students',
    CLASSES: 'Classes',
    GROUPS: 'Groups',
    RESET: 'Reset filters',
    SUBJECT_CONTENT: 'Subject content',
    CHOOSE_SUBJECT: 'Choose subject',
    CHOOSE_CONTENT: 'Select content',
    CHOOSE_SKILLS: 'Choose skills',
    MORE_FILTERS: 'More filters',
  },

  VIDEO_BLOCK: {
    DEFAULT_TITLE: 'Video',
  },

  DURATION_TIME: {
    LABEL: 'Duration:',
  },

  CARD: {
    EXTERNAL_LABEL: 'External link',
    EXTERNAL_BUTTON: 'Open in a new tab',
    GO_TO: 'Go to',
    MODAL: 'Read more',
    DOWNLOAD: 'Download',
    OPEN: 'Open',
    CHAPTER: {
      GO_TO_TOPIC: 'Go to topic',
      START: 'Start',
      CONTINUE: 'Continue',
      RETAKE: 'Retake',
      TASKS: 'tasks',
      NOT_STARTED: 'Not started',
    },
  },

  ARTICLE: {
    CHAPTER: 'Chapter',
    SUBJECT: 'Subject',
    AUTHOR: 'Written by',
    GOALS: 'Competence aims',
    TEST_YOUR_KNOWLEDGE: 'Test your knowledge',
  },

  COMPETENCY_AIMS: {
    HEADER: 'Competence aims',
  },

  TIME_LABEL: {
    LABEL: 'Reading time',
    SHORT_LABEL: 'Time',
    DAYS: '{ count } day | { count } days',
    HOURS: '{ count } hour | { count } hours',
    MINUTES: '{ count } minute | { count } minutes',
    SECONDS: '{ count } second | { count } seconds',
  },

  SEARCH_MODAL: {
    SEARCH: 'Search',
  },

  CONTENT_TYPE_SELECT: {
    ALL: 'All',
    LEARNING_PATHS: 'Learning paths',
    ALL_CONTENT: 'All content',
  },

  CONTENT_TYPE: {
    FACTBOX: 'FactBox',
    IMAGE: 'Image',
    ACCORDION: 'Accordion',
    CHECKBOX: 'Checkbox',
  },

  TYPE: {
    ARTICLE: 'Article',
    CONCEPT: 'Concept',
    EXTERNALLINK: 'External link',
    VIDEO: 'Video',
    TASK: 'Task',
    DOCUMENT: 'Document',
    PROJECT: 'Project',
    TEST: 'Test',
    MULTIMEDIA: 'Multimedia',
    CATEGORY: 'Category',
    LEARNING_PATH: 'Learning path',
  },

  ERROR: {
    NOT_FOUND_TITLE: 'Something went wrong!',
    NOT_FOUND_MESSAGE:
      '<strong>Possible issue:</strong><br />' +
      '1. The page url has changed or does no longer exist anymore.<br />' +
      '2. You need to sign in to see the content. <br />' +
      '3. You don´t have access to this page or site - contact you teacher or administrator for more information and help.',
    ACCESS_DENIED_TITLE: 'Unfortunately, you do not have access to this page',
    ACCESS_DENIED_MESSAGE: 'Certain pages are only available to certain types of users.',
    ACCESS_FORBIDDEN_TITLE: 'Access forbidden',
    ACCESS_FORBIDDEN_MESSAGE: 'You have to buy subscription to visit this page.',
    PAYMENT_REQUIRED_TITLE: 'You don’t have access to this product',
    PAYMENT_REQUIRED_MESSAGE:
      'Ask your teacher or school administrator what you can do to gain access, or visit this page for more info',
    DEFAULT_TITLE: 'Something went wrong!',
    DEFAULT_MESSAGE: 'Cannot show this web page.',
    HOMEPAGE_LINK: 'Go to homepage',
  },

  CARDS_CONTAINER: {
    RELATED_LINKS: 'Related links',
  },

  FILTERS_SELECTOR: {
    BUTTON_TEXT: 'Go to course content',
  },

  DOCUMENT_CARD: {
    LABEL: 'Document',
    DOWNLOAD: 'Download',
  },

  EXERCISE: {
    CONTROL_TEXT: 'Metadata',
    META: {
      TIME: 'Time',
      CURRICULUM_OBJECTIVES: 'Curriculum objectives',
      ASSESSMENT_PROPOSAL: 'Assessment proposal',
      GO_TO_ARTICLE: 'Go to article',
    },
  },

  ARTICLE_NAVIGATION: {
    LABEL: 'Content',
  },

  CONCEPT: {
    LABEL: 'Concepts',
  },

  QUESTION_VALIDATION: {
    CORRECT: 'Correct',
    INCORRECT: 'Incorrect',
  },

  QUESTION_GROUP: {
    SEE_RESULTS: 'See results',
  },

  QUESTION_GROUP_FEEDBACK: {
    CORRECT: 'Very good! - Everything is fine!',
    WRONG: "Sorry! You didn't have enough right answers.",
    RETRY_GROUP: 'Retry',
    RETRY_TEST: 'Take test again',
    HEADER_OUTCOME: 'Have you passed the test?',
  },

  PROGRESSION: {
    EMPTY_MESSAGE: 'Your test progress will be displayed here',
    CONTENTS: {
      HEADER: 'Contents',
    },
    TESTS: {
      HEADER: 'Tests',
      DESCRIPTION: `You have done: {done} of {total} tests`,
    },
  },

  PROGRESSION_INDICATOR: {
    FINISHED: 'Finished',
    NOT_STARTED: 'Not started',
    NO_DATA: 'No data available',
    OF: 'of',
  },

  ANSWERS_COUNTER: {
    CORRECT: 'Correct',
    WRONG: 'Wrong',
  },

  MESSAGE_BANNER: {
    ERROR: 'We are having some technical problems, please try again later!',
  },

  LEARNING_PATH: {
    CLOSE: 'Close',
    OVERVIEW: {
      SHOW: 'Show overview',
      HIDE: 'Hide overview',
    },
    PROGRESS: {
      STARTED: 'Started',
      NOT_STARTED: 'Not started',
      START: 'Start',
      FINISHED: 'Finished',
      VIEW: 'View',
      CONTINUE: 'Continue',
    },
    LIST: {
      HEADER: 'Continue on new learning paths',
    },
    NUMBER_OF_TASKS: '{ count } tasks',
    CONFIRMATION: {
      HEADER: 'Finished!',
      SUBHEADER: 'Changes have been saved',
      EXIT: 'Exit',
    },
    PROGRESSION: {
      TITLE: 'You are almost there!',
      ANSWER_ALL_QUESTIONS: 'Not completed',
      TRY_AGAIN: 'Continue',
    },
    SUMMARY: {
      HEADER: 'Summary',
      INFO: 'You need to answer all of the questions',
      COMPLETED: 'Completed',
    },
    META: {
      SKILLS: 'Skills',
      PAGES: 'Pages',
    },
    SHOW_CONTENT: 'Show content',
  },

  NAVIGATION_BUTTON: {
    PROCEED: 'Proceed',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
  },

  CONTROLS: {
    CLOSE: 'Close',
    OPEN: 'Open',
    CANCEL: 'Cancel',
    ACCEPT: 'Accept',
  },

  TEACHER_DASHBOARD: {
    ADMIN: 'Admin',
    CLOSE: 'Close',
    HOME: 'Frontpage',
    STUDENT_DATA: 'Student data',
    VIEW_AS: 'View as',
    TEACHER: 'Teacher',
    STUDENT: 'Student',
    LAST_VISIT: 'Last visit',
    BUTTON_TEXT: 'Show all data',
    TITLE: 'Student data',
    SEE_EXERCISES: 'See exercises',
    SORT: {
      LABEL: 'Sort',
      ALL: 'All activities',
      NOT_STARTED: 'Not started',
      STARTED: 'Started',
      FINISHED: 'Finished',
    },
  },

  TIMEGRAPH: {
    WEEK: 'Week',
    MONTH: 'Month',
    HALF_YEAR: '6 months',
    YEAR: '12 months',
    COMPLETED: 'Completed learning paths',
    DATA_PERIOD: 'Data for last',
    MONTHS_NAMES: {
      JAN: 'January',
      FEB: 'February',
      MAR: 'March',
      APR: 'April',
      MAY: 'May',
      JUN: 'June',
      JUL: 'July',
      AUG: 'August',
      SEP: 'September',
      OCT: 'October',
      NOV: 'November',
      DEC: 'December',
    },
    WEEKDAYS_NAMES: {
      SUN: 'Sunday',
      MON: 'Monday',
      TUE: 'Tuesday',
      WED: 'Wednesday',
      THU: 'Thursday',
      FRI: 'Friday',
      SAT: 'Saturday',
    },
    AMOUNT: 'Amount',
  },

  SKILL: {
    READ: 'Read',
    WRITE: 'Write',
    LISTEN: 'Listen',
    ORAL: 'Oral',
  },

  LAST_USED: {
    LEARNING_PATHS: {
      TITLE: 'Last used learning paths',
      EMPTY_CONTENT: 'Your latest learning paths will be displayed here',
    },
  },

  USER_INFO: {
    WELCOME: 'Welcome',
    TIME_SPENT: 'Time spent',
    TIME_SPENT_VALUE: '{ hours } h { minutes } min',
    COMPLETED_CHAPTERS: 'You have done',
    CHAPTERS: '{ completed } of { total } chapters',
    WEEK: 'Week',
  },
};
