import { TextToSpeechContext, TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';
import { FactBoxModel } from '@/model/content';
import { concatContent } from '@/services/useContent/helper/concatContent';

class FactBoxMapper implements TextToSpeechMapper {
  mapContent(content: FactBoxModel, context: TextToSpeechContext): string {
    return concatContent([context.translate('CONTENT_TYPE.FACTBOX'), content?.title, content?.content]);
  }
}

export const factBoxMapper = new FactBoxMapper();
