<template>
  <div v-if="transcriptionProvider?.content" class="c-transcription">
    <div class="c-transcription__head">
      <div class="c-transcription-line" />
      <button
        :class="['c-transcription-button', { 'c-transcription-button--open': open }]"
        @click="handleToggleTranscriptionVisibility"
      >
        {{ $t('VIDEO.READ_TRANSCRIPTION') }}
        <ArrowIcon class="c-transcription-button__arrow" :class="{ 'c-transcription-button__arrow--open': open }" />
      </button>
    </div>
    <BaseCollapse v-model="open" class="c-transcription__body">
      <template #collapsible>
        <div class="c-transcription-window">
          <div class="c-transcription-window__header">
            <LocalLanguageSelect
              v-if="transcriptionProvider?.locales?.length > 1"
              :locales="transcriptionProvider?.locales"
              class="c-transcription-window__language-select"
              @change="transcriptionProvider?.changeTranscription"
            />
          </div>
          <div class="c-transcription-window__body">
            <RichText :content="transcriptionProvider?.content" class="c-transcription-window__content" :language="activeLocale" />
          </div>
        </div>
      </template>
    </BaseCollapse>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';
import RichText from '@/components/rich-text/RichText.vue';
import LocalLanguageSelect from '@/components/language-select/LocalLanguageSelect.vue';
import useLocalLanguage from '@/services/useLocalLanguage';
import BaseCollapse from '@/elements/collapse/BaseCollapse.vue';
import { inject } from 'vue';
import { type TranscriptionProvider } from './useTranscriptionProvider';

const transcriptionProvider = inject<TranscriptionProvider>('useTranscriptionProvider');

const localLanguage = computed(() => useLocalLanguage(transcriptionProvider.locales));
const activeLocale = computed(() => localLanguage.value?.activeLocale.value);

const open = ref(false);

const handleToggleTranscriptionVisibility = () => {
  open.value = !open.value;
};

</script>
