import {
  ArticleResponse,
  CategoryResponse,
  GlobalAudioPartial,
  LocaleCollection,
  MultimediaResponse,
  PageResponse,
  ProjectResponse,
  Tag,
  TestResponse,
} from '@/model/response/';
import { Content } from '@/model/page/Content';
import { getTranslatedTypeName } from '@/utils/getTranslatedTypeName';
import { mapContentBlocks } from '@/mapper/';
import { FilterTags } from '@/config/Constants';
import { LearningPathResponse } from '@/model/response/LearningPath';
import { sortItemsByOrder } from '@/utils/sortItemsByOrder';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

type PageResponses =
  | ArticleResponse
  | ProjectResponse
  | TestResponse
  | MultimediaResponse
  | LearningPathResponse
  | CategoryResponse
  | PageResponse;

export interface PageSharedFields extends GlobalAudioPartial {
  id: string;
  parentId: string;
  contentType: string;
  contentTypeName: string;
  categoryKeyword: string;
  title: string;
  author: string;
  children: Content[];
  description: string;
  slug: string;
  tags: Tag[];
  image: {
    altText: string;
    assetId: string;
    caption: string;
    copyright: string;
  };
  time: string;
  // NOTE: Response does not include `slugPath` right now
  slugPath: string;
  namespaceId: string;
  locales?: LocaleCollection;
}

export const mapPage = (pageResponse: PageResponses): PageSharedFields => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response?.content?.data?.content, response.content?.data?.localization);

  return {
    id: response.id,
    parentId: response.parent,
    contentType: response.contentType,
    contentTypeName: getTranslatedTypeName(response.contentType),
    categoryKeyword: getCategoryKeyword(response),
    title: content?.name || response.name,
    author: content?.author,
    children: response.children?.data?.sort(sortItemsByOrder).map(mapContentBlocks) || [],
    description: content?.description || response.description,
    slug: response.slug,
    tags: response.tag?.data?.tags || [],
    image: {
      altText: content?.altText,
      assetId: content?.cover || response.cover,
      caption: content?.altText,
      copyright: content?.copyright,
    },
    globalAudio: content?.globalAudio,
    globalAudioTranscription: content?.globalAudioTranscription,
    time: getTime(response),
    namespaceId: response.content?.data?.namespace,
    slugPath: response.slugPath,
    locales: response.locales?.length ? response.locales : response.content.data.locales,
  };
};

const getCategoryKeyword = (page: PageResponses): string =>
  page.tag?.data?.tags?.find((tag) => tag.source === FilterTags.Category)?.keyword;

const getTime = (response: PageResponses): string =>
  resolveTimeEstimate(response as ProjectResponse) || resolveReadingTime(response as ArticleResponse);

const resolveTimeEstimate = (response: ProjectResponse): string =>
  response.content?.data?.localization?.timeEstimate || response.content?.data?.content?.timeEstimate;

const resolveReadingTime = (response: ArticleResponse): string | null =>
  response.content?.data?.content?.readingTime ? parseReadingTime(response.content.data.content.readingTime) : null;

export const parseReadingTime = (originalValue: number): string => originalValue.toString(10);
