import PouchDB from 'pouchdb'
import { fetchWithAuth } from '../api'

class DB {
  constructor(dbName = 'core', options = {}) {
    this.localDB = new PouchDB(dbName, options)
    this.sync = null
    this.id = null
  }

  initSync = async (options = {}, optionsTo = {}) => {
    this.remoteDB = new PouchDB(`${process.env.VUE_APP_COSS_URL}/sync/proxy`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const response = await fetchWithAuth('sync/user', {
      method: 'POST',
      headers: { 'content-type': 'text/plain' },
    })

    if (200 <= response.status < 300) {
      this.id = (await response.json()).id
      await this.syncData(options, optionsTo)
      return this.id
    }
  }

  doSync = async (direction, options = {}) => {
    const emptyHandler = () => {};

    const {onChange, onPause, onActive, onDenied, onCompleted, onError, ...otherOptions} = options
    const sync = (direction === 'out' ?
      this.localDB.replicate.from(this.remoteDB, otherOptions) :
        this.localDB.replicate.to(this.remoteDB, otherOptions))
          .on('change', onChange ?? emptyHandler)
          .on('pause', onPause ?? emptyHandler)
          .on('active', onActive ?? emptyHandler)
          .on('denied', onDenied ?? emptyHandler)
          .on('complete', onCompleted ?? emptyHandler)
          .on('error', onError ?? emptyHandler)

    return await sync
  }

  syncData = async (options = {}, optionsTo = undefined) => {
    if (this.id) {
      this.sync = await this.doSync('in', optionsTo || options)
      await this.doSync('out', options)
    }
  }

  cancelSync = () => {
    this.sync?.cancel()
  }

  createOrUpdate = async (data) => {
    let doc

    if ('_id' in data) {
      try {
        const oldDoc = await this.localDB.get(data['_id'])

        data._rev = oldDoc._rev
        doc = await this.localDB.put(data)
      } catch (err) {
        doc = await this.localDB.put(data)
      }
    }

    return doc
  }

  getData = (id, options = {}) => {
    return this.localDB.get(id, options)
  }

  getAllData = (bulkIds) => {
    const allData = this.localDB.allDocs(bulkIds)
    return allData.then(records => {
      return { ...records, rows: records.rows.filter(record => record.doc && !record.doc.deleted || !record.doc) }
    })
  }

  removeData = async (id, rev = undefined) => {
    if (rev) {
      return await this.localDB.remove(id, rev)
    }

    const doc = await this.getData(id, {include_docs: true})
    doc._deleted = true
    doc.deleted = true
    return await this.localDB.put(doc)
  }

  onChanges = (options, callback) => {
    this.localDB.changes(options).on('change', (data) => callback(data))
  }
}

export default DB
