import { Ref, shallowRef } from 'vue';
import StandardHeader from '@/modules/header/StandardHeader.vue';
import SubpageHeader from '@/modules/header/SubpageHeader.vue';
import CategoryHeader from '@/modules/header/CategoryHeader.vue';
import LearningPathHeader from '@/modules/header/LearningPathHeader.vue';

type Header =
  | typeof StandardHeader
  | typeof SubpageHeader
  | typeof CategoryHeader
  | typeof LearningPathHeader
  | undefined;

interface UseHeader {
  hideHeader: VoidFunction;
  header: Ref<Header>;
  showCategoryHeader: VoidFunction;
  showLearningPathHeader: VoidFunction;
  showStandardHeader: VoidFunction;
  showSubpageHeader: VoidFunction;
}

const header = shallowRef<Header>(StandardHeader);

const switchHeader = (headerComponent: Header) => {
  if (header.value?.__name !== headerComponent?.__name) {
    header.value = headerComponent;
  }
};

const showStandardHeader = () => switchHeader(StandardHeader);
const showSubpageHeader = () => switchHeader(SubpageHeader);
const showCategoryHeader = () => switchHeader(CategoryHeader);
const showLearningPathHeader = () => switchHeader(LearningPathHeader);

const hideHeader = () => switchHeader(undefined);

export default (): UseHeader => ({
  header,
  hideHeader,
  showCategoryHeader,
  showStandardHeader,
  showSubpageHeader,
  showLearningPathHeader,
});
