import { markdownToHtml } from '../utils'

const mdHtml = {
  beforeMount: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
    el.addEventListener('click', (e) => {
      if (e.srcElement.attributes['data-concept-id']) {
        const conceptId = e.srcElement.attributes['data-concept-id'].nodeValue
        el.dispatchEvent(
          new CustomEvent('concept-click', { detail: { conceptId } }),
        )
      }
    })
  },

  beforeUpdate: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
  },
}

export default mdHtml
