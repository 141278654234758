import { PageResponse } from '@/model/response';
import { SubjectModel } from '@/model/page/Subject';
import { ModuleModel } from '@/model/page/Module';
import { mapPage } from '@/mapper/Page';
import { mapContent } from '@/mapper/Content';

export const mapSubject = (response: PageResponse): SubjectModel => {
  const { id, contentType, slug, title, description } = mapPage(response);

  return {
    id,
    contentType,
    slug,
    // TODO: should be changed to image and taken from pageMapper
    cover: response?.cover,
    title,
    description,
    children: response.children?.data.map((item) => mapContent(item as PageResponse)) as ModuleModel[],
  };
};
