import { TenantConfig } from '@/config/tenants/TenantConfig';
import { Namespace } from '@/model/Namespace';

export const getTenantConfig = (namespace: Namespace) =>
  ({
    namespace: namespace.id,
    name: namespace.name,
    assets: {
      favicon: '/favicon.ico',
    },
    styles: [],
  }) as TenantConfig;
