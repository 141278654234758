<template>
  <div ref="target" :class="['c-base-select', { open: shown }]">
    <button class="c-base-select__label" @click="toggleShown">
      <slot name="labelPrefix" />
      {{ labelText }}
      <slot name="labelSuffix">
        <ArrowIcon class="c-base-select__label-arrow" />
      </slot>
    </button>
    <div class="c-base-select__content">
      <FocusLoop class="c-base-select__collapse" :disabled="!shown">
        <div class="c-base-select__list-wrapper">
          <BaseSelectItem
            v-for="(item, index) in list"
            :key="index"
            v-bind="item"
            :active="isActive(item)"
            :hidden="item.hidden"
            @click="() => onItemChange(item)"
          >
            <template #iconSuffix><ThinTickIcon v-if="isActive(item)" class="c-base-select__item-tick" /></template>
          </BaseSelectItem>
        </div>
      </FocusLoop>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import BaseSelectItem from '@/elements/select/BaseSelectItem.vue';
import { onClickOutside } from '@vueuse/core';
import { FocusLoop } from '@vue-a11y/focus-loop';
import ThinTickIcon from '@/assets/icons/ThinTickIcon.vue';
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';

export interface SelectItem {
  name?: string;
  value: string;
  hidden?: boolean;
}

interface BaseSelect {
  list: SelectItem[];
  selected: SelectItem;
  open?: boolean;
  label?: string;
}

const props = defineProps<BaseSelect>();
const emit = defineEmits<{
  'update:selected': [value: SelectItem];
}>();

const target = ref<HTMLElement>(null);
const shown = ref(!!props.open);

const toggleShown = () => {
  shown.value = !shown.value;
};

const onItemChange = (item: SelectItem) => {
  emit('update:selected', item);
  toggleShown();
};

const isActive = (item: SelectItem) => item.value === props.selected?.value;

onClickOutside(target, () => shown.value && toggleShown());

const labelText = computed(() => (props.selected?.value ? props.selected?.name || props.selected?.value : props.label));
</script>
