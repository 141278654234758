<template>
  <ThemeProvider>
    <Loader v-if="globalLoading" />
    <template v-else>
      <ModalContainer />
      <component :is="header" />
      <main>
        <router-view v-slot="{ Component }">
          <Suspense v-if="!errorID" timeout="0">
            <template #default>
              <component :is="Component"></component>
            </template>
            <template #fallback>
              <Loader class="v-app__loader" />
            </template>
          </Suspense>
          <ErrorPage v-else :id="errorID || ResponseErrorStatusCode.FORBIDDEN" />
        </router-view>
      </main>
      <Footer v-show="showFooter" />
    </template>
  </ThemeProvider>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useFavicon } from '@vueuse/core';
import { TenantConfig, useAuth, useTenant } from '@/core';
import useCachedAssets from '@/services/useCachedAssets';
import ModalContainer from '@/layout/modal/ModalContainer.vue';
import useNamespace from '@/services/useNamespace';
import { useHead } from '@vueuse/head';
import useHeader from '@/services/useHeader';
import Footer from '@/modules/footer/BaseFooter.vue';
import useLocalization from '@/services/useLocalization';
import useDB from '@/services/useDB';
import { DB_CHECKLIST, DB_LAST_USED, DB_SUBJECT_LEVEL } from '@/constants/Databases';
import useLoading from '@/services/useLoading';
import Loader from '@/elements/loader/BaseLoader.vue';
import ThemeProvider from '@/components/theme-provider/ThemeProvider.vue';
import useUserMode from '@/services/useUserMode';
import { useRoute } from 'vue-router';
import usePermissions from '@/services/usePermissions';
import ErrorPage from '@/views/content/ErrorPage.vue';
import useError, { ResponseErrorStatusCode } from '@/services/useError';
import useProgressionLP from '@/services/useProgressionLP';
import useTreeStructure from '@/services/useTreeStructure';

interface AppProps {
  tenantConfig: TenantConfig;
}

const props = defineProps<AppProps>();

if (import.meta.env.DEV) {
  import('@/utils/devTools');
}
const { initTenant } = useTenant();
const { token } = useAuth();
const { fetchNamespace, namespace } = useNamespace();
const { locale, localeUi, setLocale, setLocaleUi, availableLocale, availableLocaleUi } = useLocalization();
const { header } = useHeader();
const { globalLoading, onGlobalLoadFinish, onGlobalLoadStart } = useLoading();
const { errorID } = useError();
const favicon = useFavicon();
const { getAsset } = useCachedAssets();
const { setupDBs } = useDB();
const { init: initUserMode } = useUserMode();
const route = useRoute();
const showFooter = computed(() => !route.meta?.hideFooter);

useHead({
  title: computed(() => namespace.settings?.title || namespace?.name || ''),
});

const initDBs = async () => {
  if (!namespace.id) return;
  onGlobalLoadStart('database');
  await Promise.all([setupDBs([DB_LAST_USED, DB_CHECKLIST, DB_SUBJECT_LEVEL])])
    .catch((error) => {
      console.error(error);
    })
    .finally(() => onGlobalLoadFinish('database'));
};

const initNamespace = async (newLocale: string, newLocaleUi: string) => {
  if (namespace.settings?.favicon) {
    onGlobalLoadStart('favicon');
    getAsset(namespace.settings.favicon).then((asset) => {
      favicon.value = asset?.url;
      onGlobalLoadFinish('favicon');
    });
  }

  if (namespace?.languages) {
    // Ensure, that you are not using unsupported UI language
    if (!availableLocaleUi.value.includes(newLocaleUi)) {
      setLocaleUi(availableLocaleUi.value.at(0));
    }
    // Ensure, that you are not using unsupported content language, default will be UI language
    if (![...availableLocaleUi.value, ...availableLocale.value].includes(newLocaleUi)) {
      setLocale(newLocaleUi);
    }
  }
};

const loadProgress = () => {
  const { isStudent } = usePermissions();
  // do it when loggedIn and Student (???)
  if (token.value && isStudent()) {
    const { init } = useProgressionLP();
    init();
  }
};

onGlobalLoadStart();
initTenant(props.tenantConfig as TenantConfig);
loadProgress();
useTreeStructure();

onMounted(async () => {
  initUserMode();
  await initNamespace(locale.value, localeUi.value);
  // NOTE: DBs are also created for anonymous user (should be no-blocking)
  initDBs();
  onGlobalLoadFinish();
});

// NOTE: Not `immediate` because onMounted and this calls were intertwined and not sequential
watch([locale, localeUi], async ([newLocale, newLocaleUI]) => {
  await fetchNamespace(newLocale);
  await initNamespace(newLocale, newLocaleUI);
});
</script>
