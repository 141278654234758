export enum ChildContentType {
  ACCORDIONS = 'accordions',
  ARTICLE_LEFT_RIGHT = 'article-left-right',
  AUDIO = 'audio',
  BIG_CARDS = 'big-cards',
  CHECKLIST = 'checklist',
  DOCUMENTS = 'documents',
  FACTBOX = 'factbox',
  FILTERS_SELECTOR = 'filters-selector',
  FRONT_PAGE_LEFT_RIGHT = 'front-page-left-right',
  H5P = 'h5p',
  IFRAME = 'iframe',
  IMAGE = 'image',
  LAST_VISITED = 'last-visited',
  LAST_VISITED_LP = 'last-visited-lp',
  LEFT_RIGHT = 'left-right',
  LINKS_BLOCK = 'links-block',
  MAUTHOR = 'mauthor',
  PROGRESSION_BLOCK = 'progression-block',
  QUESTION_GROUP = 'question-group',
  RELATED_CONTENT_ITEMS = 'related-content-items',
  SECTION_SEPARATOR = 'section-separator',
  SUBJECT_FOLDERS = 'subject-folders',
  TEXT = 'text',
  TOP_IMAGE = 'top-image',
  VIDEO = 'video',
  VIDEO_BLOCK = 'video-block',
  TABS_BLOCK = 'tabs-block',
}

export const SupportedChildrenContentTypes = Object.values(ChildContentType);
