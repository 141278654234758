import { availableLanguagesVgs, Language } from '@/i18n';

interface useLocalStorageInterface {
  getCurrentLocale: () => Language;
  setCurrentLocale: (lang: Language) => void;
  getSecondaryLocale: () => Language;
  setSecondaryLocale: (lang: Language) => void;
  getUiLocale: () => Language;
  setUiLocale: (lang: Language) => void;
}

const LangKeys = {
  LANG: 'lang',
  LANG_SECONDARY: 'lang-secondary',
  LANG_UI: 'lang-ui',
} as const;

const getItem = <T>(key: string) => localStorage.getItem(key) as T;
const setItem = (key: string, value: Language) => localStorage.setItem(key, value as unknown as string);

const getCurrentLocale = (): Language => getItem<Language>(LangKeys.LANG);
const setCurrentLocale = (locale: Language) => setItem(LangKeys.LANG, locale);

const getSecondaryLocale = (): Language => getItem<Language>(LangKeys.LANG_SECONDARY) || getCurrentLocale();
const setSecondaryLocale = (locale: Language) => setItem(LangKeys.LANG_SECONDARY, locale);

const getUiLocale = (): Language => {
  const language = getItem<Language>(LangKeys.LANG_UI) || undefined;
  return availableLanguagesVgs.includes(language) ? language : undefined;
};

const setUiLocale = (locale: Language) => setItem(LangKeys.LANG_UI, locale);

export default (): useLocalStorageInterface => ({
  getCurrentLocale,
  setCurrentLocale,
  getSecondaryLocale,
  setSecondaryLocale,
  getUiLocale,
  setUiLocale,
});
