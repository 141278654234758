export enum ArrowButtonVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  DROPDOWN = 'dropdown',
}

export enum ArrowButtonIconVersion {
  ARROW = 'arrow',
  CHEVRON = 'chevron',
}

export enum ArrowButtonDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ArrowButton {
  fluid?: boolean;
  arrowDirection?: ArrowButtonDirection;
  iconVersion?: ArrowButtonIconVersion;
  variant?: ArrowButtonVariant;
}
