<template>
  <header ref="headerElement" :class="['c-header', { 'c-header--modal-opened': isModalOpened }]" data-test="header">
    <GoToContent />
    <nav class="c-header__nav">
      <slot />
      <div v-if="slots.actions" class="c-header__actions">
        <slot name="actions" />
      </div>
      <TeacherDashboardPanel v-if="isTeacherDashboardAccessible" />
    </nav>
    <HeaderNavigation />
    <BaseCollapse
      v-if="showMenu"
      ref="navElement"
      v-model="isNavBarOpen"
      :hide-at="breakpoint"
      class="c-header__collapse"
      :class="[{ 'c-header__collapse--hidden': hideNavElement }]"
    >
      <template #control>
        <OpenModalButton :is-open="isNavBarOpen">
          {{ $t('HEADER_BAR.OPEN_MODAL_BUTTON') }}
        </OpenModalButton>
      </template>
      <template #collapsible>
        <div class="c-header__nav-buttons" data-test="header-links">
          <component
            :is="menuItemComponent(button)"
            v-for="button in navLinks"
            :key="button.name"
            :use-overlay="true"
            :external="button.external"
            :with-arrow="!isXLarge"
            v-bind="button"
            :class="['c-header__nav-button', { 'c-header__nav-button--dropdown': button.children }]"
            @click="!button.children && closeNavBarOpen()"
            @on-dropdown-click="closeNavBarOpen()"
            @on-dropdown-closed="closeNavBarOpen()"
          />
        </div>
      </template>
    </BaseCollapse>
    <LearningPathHeaderWrapper v-if="withLearningPath" />
    <div id="header-teleport-zone"></div>
    <MessageBanner />
  </header>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue';
import { useRoute } from 'vue-router';
import OpenModalButton from '@/components/open-modal-button/OpenModalButton.vue';
import BaseCollapse from '@/elements/collapse/BaseCollapse.vue';
import MenuButton from '@/components/menu-button/MenuButton.vue';
import MenuDropdownButton from '@/components/menu-button/MenuDropdownButton.vue';
import useScss, { Breakpoint } from '@/services/useScss';
import useNamespace from '@/services/useNamespace';
import useModal from '@/services/useModal';
import MessageBanner from '@/components/message-banner/MessageBanner.vue';
import GoToContent from '@/components/goToContent/GoToContent.vue';
import LearningPathHeaderWrapper from '@/components/learning-path-header/LearningPathHeaderWrapper.vue';
import TeacherDashboardPanel from '@/modules/teacher-dashboard-panel/TeacherDashboardPanel.vue';
import usePermissions from '@/services/usePermissions';
import { useElementSize, useScroll } from '@vueuse/core';
import useLearningPathMenu from '@/services/useLearningPathMenu';
import pxToRem from '@/utils/pxToRem';

interface BaseHeaderProps {
  withMenu?: boolean;
  withLearningPath?: boolean;
  showActions?: boolean;
}

const props = withDefaults(defineProps<BaseHeaderProps>(), {
  showActions: true,
});
defineSlots<{
  default();
  actions();
}>();

const { modal } = useModal();
const { namespace } = useNamespace();
const slots = useSlots();
const breakpoint = Breakpoint.XLARGE;
const { useMediaQuery, setCssVariable } = useScss();
const isXLarge = useMediaQuery(breakpoint);
const isNavBarOpen = ref(false);
const { isTeacher } = usePermissions();
const route = useRoute();

const navLinks = computed(() => (namespace ? namespace.settings.headerNavigation : []));
const headerElement = ref<HTMLElement>(null);
const { height } = useElementSize(headerElement);
const { setHeaderHeight } = useLearningPathMenu();

const menuItemComponent = (button): typeof MenuDropdownButton | typeof MenuButton =>
  button?.children ? MenuDropdownButton : MenuButton;

const closeNavBarOpen = () => (isNavBarOpen.value = false);

const isModalOpened = computed(() => modal.value?.instance);
const isTeacherDashboardAccessible = computed(() => namespace.settings.useTeacherDashboard && isTeacher());
const isTeacherDashboard = computed(() => route.name === 'Teacher Dashboard');
const showMenu = computed(() => navLinks.value?.length && props.withMenu && !isTeacherDashboard.value);

const navElement = ref<InstanceType<typeof BaseCollapse>>(null);
const { height: navHeight } = useElementSize(navElement);
const { y, arrivedState, directions } = useScroll(window, {
  offset: {
    top: 0,
    bottom: 200,
    left: 0,
    right: 0,
  },
});

const previousScrollDirection = computed((oldValue: string) => {
  const newScrollDirection = Object.keys(directions).filter((directionKey) => Boolean(directions[directionKey]))[0];

  return newScrollDirection || oldValue;
});

const hideNavElement = computed(() => {
  const isInScrollableArea =
    !arrivedState.top &&
    y.value > 0 &&
    y.value + window.innerHeight < document.documentElement.scrollHeight - height.value;

  return previousScrollDirection.value === 'bottom' && isInScrollableArea && (isXLarge.value || !isNavBarOpen.value);
});

watch([height, navHeight], () => {
  setHeaderHeight(height.value);

  setCssVariable('--header-size', pxToRem(height.value).toString());
  setCssVariable('--header-nav-size', pxToRem(navHeight.value).toString());
});
</script>
