<template>
  <FluidLayout class="v-error__layout">
    <div class="v-error" data-test="error-page">
      <div class="v-error__content">
        <div class="c-rich-text">
          <h1 class="v-error__title" data-test="error-title" v-html="title" />
          <p class="v-error__text" data-test="error-message" v-html="message" />
        </div>
        <BaseLink v-if="showButton && link" :to="link">
          <ArrowButton
            fluid
            :arrow-direction="ArrowButtonDirection.RIGHT"
            class="v-error__link"
            data-test="error-button"
          >
            {{ label }}
          </ArrowButton>
        </BaseLink>
      </div>
    </div>
  </FluidLayout>
</template>

<script lang="ts" setup>
import FluidLayout from '@/layout/FluidLayout.vue';
import ArrowButton from '@/components/arrow-button/ArrowButton.vue';
import { ArrowButtonDirection } from '@/model/ArrowButton';
import useNamespace from '@/services/useNamespace';
import useError, { ResponseErrorStatusCode } from '@/services/useError';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseLink from '@/elements/link/BaseLink.vue';

const props = defineProps<{
  id?: string;
}>();

const { t } = useI18n();
const { namespace } = useNamespace();
const { errorID } = useError();
const showButton = ref(true);

const title = ref('');
const message = ref('');
const label = ref('');
const link = ref('');

const id = computed(() => errorID.value || props.id);

switch (id.value) {
  case ResponseErrorStatusCode.UNAUTHORIZED:
    title.value = t('ERROR.ACCESS_DENIED_TITLE');
    message.value = t('ERROR.ACCESS_DENIED_MESSAGE');
    label.value = t('ERROR.HOMEPAGE_LINK');
    link.value = '/';
    break;
  case ResponseErrorStatusCode.PAYMENT_REQUIRED:
    title.value = namespace.settings?.userFallbackAfterLogin?.headingErrorMessage || t('ERROR.PAYMENT_REQUIRED_TITLE');
    message.value = namespace.settings?.userFallbackAfterLogin?.body || t('ERROR.PAYMENT_REQUIRED_MESSAGE');
    label.value = namespace.settings?.userFallbackAfterLogin?.buttonTitle;
    link.value = namespace.settings?.userFallbackAfterLogin?.urlLink;

    if (!label.value || !link.value) {
      showButton.value = false;
    }

    break;
  case ResponseErrorStatusCode.FORBIDDEN:
    title.value = t('ERROR.ACCESS_FORBIDDEN_TITLE');
    message.value = t('ERROR.ACCESS_FORBIDDEN_MESSAGE');
    showButton.value = false;
    break;
  case ResponseErrorStatusCode.NOT_FOUND:
    title.value = t('ERROR.NOT_FOUND_TITLE');
    message.value = t('ERROR.NOT_FOUND_MESSAGE');
    label.value = t('ERROR.HOMEPAGE_LINK');
    link.value = '/';
    break;
  default:
    title.value = t('ERROR.DEFAULT_TITLE');
    message.value = t('ERROR.DEFAULT_MESSAGE');
    label.value = t('ERROR.HOMEPAGE_LINK');
    link.value = '/';
}
</script>
