<template>
  <BaseLink
    :to="path"
    :class="[
      'c-dropdown-button',
      {
        'c-dropdown-button--active': activeLink,
      },
    ]"
  >
    {{ name }}
    <ArrowIcon v-if="withArrow" class="c-dropdown-button__arrow c-dropdown-button__arrow--page" />
  </BaseLink>
</template>

<script lang="ts" setup>
import ArrowIcon from '@/assets/icons/ArrowIcon.vue';
import BaseLink from '@/elements/link/BaseLink.vue';
import useActiveLink from '@/services/useActiveLink';

interface MenuButtonProps {
  name: string;
  path: string;
  withArrow?: boolean;
  external?: boolean;
}

const props = defineProps<MenuButtonProps>();

const { activeLink } = useActiveLink({ to: props.path });
</script>
