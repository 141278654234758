<template>
  <a v-if="isExternalLink" :href="href" target="_blank" class="e-base-link"><slot /></a>
  <router-link v-else :to="'/' + href" :replace="replace" class="e-base-link"><slot /></router-link>
</template>

<script lang="ts" setup>
import { RouterLink, RouterLinkProps } from 'vue-router';

interface BaseLinkProps extends RouterLinkProps {
  to: string;
}

const props = defineProps<BaseLinkProps>();

const isExternalLink = typeof props.to === 'string' && props.to.includes('http');

const href = props.to?.[0] === '/' ? props.to.substring(1) : props.to;
</script>
