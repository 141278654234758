<template>
  <TeacherDashboardAdminButton @click="toggleMenu" />
  <Transition name="translate-right">
    <TeacherDashboardPanelMenu v-show="isVisible" @close="closeMenu" />
  </Transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import TeacherDashboardAdminButton from '@/modules/teacher-dashboard-panel/TeacherDashboardAdminButton.vue';
import TeacherDashboardPanelMenu from '@/modules/teacher-dashboard-panel/menu/TeacherDashboardPanelMenu.vue';

const isVisible = ref(false);

const toggleMenu = () => (isVisible.value = !isVisible.value);
const closeMenu = () => (isVisible.value = false);
</script>
