import { Tag } from '@/model/response';
import { Filter } from '@/model/filter/Filter';
import { Identifiable } from '@/model/Identifiable';
import { CategoryTag } from '@/model/response/Settings';

export type FiltersObject = (Filter & { value: string[] })[];

interface UseTags {
  getFiltersObjectForContent: (
    contentTags: Filter[] | { parentSlug?: string },
    filterTags: Tag[],
    options?: { parentSlug: string },
  ) => FiltersObject;
  getFiltersForCategory: (contentTags: Filter[], usedTags?: CategoryTag[]) => Filter[];
  getCategoryTags: (contentTag: Identifiable[], usedCategoryTags?: CategoryTag[]) => Identifiable[];
}

const setup = (): UseTags => {
  const getFiltersObjectForContent = (contentTags: Filter[], filterTags: Tag[], options?) => {
    const result = contentTags?.map((filter) => ({
      ...filter,
      value: getStringForCurrentFilter(filterTags, filter.groupId, filter.list)?.filter(
        (val) => filter.list?.find((item) => item.name === val),
      ),
      parentSlug: options?.parentSlug,
    }));
    return result?.filter((filter) => filter.value?.length);
  };

  const getStringForCurrentFilter = (filterTags: Tag[], groupId: string, identifiable: Identifiable[]) => {
    const value = filterTags
      .filter((tag) => tag.source === groupId)
      .map((tag) => tag.keyword)
      .map((id) => identifiable.find((opt) => opt.id === id))
      .map((option) => option?.name || '');

    return value.length ? value : null;
  };

  const getCategoryTags = (contentTags: Identifiable[], usedCategoryTags?: CategoryTag[]): Identifiable[] =>
    usedCategoryTags && contentTags
      ? usedCategoryTags.reduce((filtered, current) => {
          const categoryTag = contentTags.find((tag) => tag.id === current.tag);
          return categoryTag ? [...filtered, categoryTag] : filtered;
        }, [])
      : contentTags || [];

  const getFiltersForCategory = (contentTags: Filter[], usedTags?: CategoryTag[]): Filter[] =>
    usedTags && contentTags
      ? usedTags.reduce((toReturn: Filter[], current: CategoryTag) => {
          const index = toReturn.findIndex((group) => group.groupId === current.groupId);
          const originalGroup = contentTags.find((group) => group.groupId === current.groupId);
          const processedTag = originalGroup?.list?.find((item) => item.id === current.tag);

          if (!originalGroup || !processedTag) {
            return toReturn;
          }

          if (index !== -1) {
            toReturn[index].list.push(processedTag);
          } else {
            toReturn.push({ ...originalGroup, list: [processedTag] });
          }

          return toReturn;
        }, [])
      : contentTags || [];

  return {
    getFiltersObjectForContent,
    getFiltersForCategory,
    getCategoryTags,
  };
};

export default setup;
