<template>
  <ContentBlocks :contents="contents" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { Content } from '@/model/page/Content';

interface AsyncContentBlocksProps {
  contents: Content[];
}

defineProps<AsyncContentBlocksProps>();

const ContentBlocks = defineAsyncComponent(() => import('@/components/content-blocks/ContentBlocks.vue'));
</script>
