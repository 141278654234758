import { computed, Ref, ref } from 'vue';

interface useLoading {
  loading: Ref<boolean>;
  globalLoading: Ref<boolean>;
  onLoadStart: VoidFunction;
  onLoadFinish: VoidFunction;
  onGlobalLoadStart: (context?: string) => void;
  onGlobalLoadFinish: (context?: string) => void;
}

const globalLoadingState = ref<string[]>([]);

const useLoading = (): useLoading => {
  const loading = ref(true);

  const onLoadStart = () => (loading.value = true);
  const onLoadFinish = () => (loading.value = false);

  const onGlobalLoadStart = (context: string = '') => globalLoadingState.value.push(context);
  const onGlobalLoadFinish = (context: string = '') =>
    (globalLoadingState.value = globalLoadingState.value.filter((item) => item !== context));

  const globalLoading = computed(() => globalLoadingState.value.length > 0);

  return {
    loading,
    globalLoading,
    onLoadStart,
    onLoadFinish,
    onGlobalLoadStart,
    onGlobalLoadFinish,
  };
};

export default useLoading;
