import { FrontpageResponse } from '@/model/response/Frontpage';
import { mapPage } from '@/mapper/Page';

export const mapFrontpage = (response: FrontpageResponse): { children: unknown[]; id: string; contentType: string } => {
  const { id, contentType, children } = mapPage(response);
  return {
    children,
    id,
    contentType,
  };
};
