import { ComputedRef, Ref, computed, onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue';
import { RichTextModel } from '@/model/content/RichText';
import { Content } from '@/model/page/Content';
import useOverlay from '@/services/useOverlay';
import { ModalType } from '@/types/modal/ModalType.enum';
import pxToRem from '@/utils/pxToRem';
import useScss from './useScss';

interface Modal {
  instance: unknown;
  id?: string;
  props: object;
  modalType: ModalType;
  events: unknown;
}

export type ModalSizeObject = { [K in SizesUnion]: boolean } | object;

interface useModal {
  modals: Ref<Modal[]>;
  modal: ComputedRef<Modal>;
  modalHeight: Ref<string>;
  initModalElement: (el: HTMLDivElement) => void;
  open: (
    instance: unknown,
    props?: Record<string, unknown>,
    modalType?: ModalType,
    events?: Record<string, unknown>,
  ) => Modal;
  close: () => void;
  getModalSizeFromContentLength: (content: string[] | Content[][]) => ModalSizeObject;
  registerModalHeightChange: (content: ComputedRef<string[] | Content[][]>) => void;
}

const resetState = () => [];
const state: { modals: Modal[] } = reactive({
  modals: resetState(),
});

const modal = computed(() => state.modals.at(-1));

const open = (instance: unknown, props = {}, modalType = ModalType.DEFAULT, events = {}): Modal => {
  const modalData = {
    instance,
    props,
    modalType,
    events,
  };

  state.modals.push(modalData);

  if (state.modals.length > 0) {
    useOverlay().add();
  }

  return modalData;
};

const close = () => {
  state.modals.pop();
  if (state.modals.length === 0) {
    useOverlay().remove();
  }
};

const modalElement = ref(null);

const initModalElement = (el: HTMLDivElement) => {
  modalElement.value = el;
};
const modalHeight = ref('min-content');

const MAX_CHARACTERS_FOR_SMALLER_MODAL = 420;
const MAX_CHARACTERS_FOR_MEDIUM_MODAL = 800;

export const Sizes = {
  AUTO: 'auto',
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

export type SizesUnion = (typeof Sizes)[keyof typeof Sizes];

const checkContentLength = (contents: string | Content[], maxLength: number) =>
  typeof contents === 'string'
    ? contents.length < maxLength
    : contents.length === 1 &&
      contents[0].contentType === 'text' &&
      (contents[0].content as RichTextModel).content.length < maxLength;

const getSizeNameFromContentLength = (contents: string | Content[]): SizesUnion => {
  let modalSize: SizesUnion = Sizes.AUTO;

  if (checkContentLength(contents, MAX_CHARACTERS_FOR_MEDIUM_MODAL)) {
    modalSize = Sizes.MEDIUM;
  }

  if (checkContentLength(contents, MAX_CHARACTERS_FOR_SMALLER_MODAL)) {
    modalSize = Sizes.SMALL;
  }

  return modalSize;
};

const getModalSizeFromContentLength = (content: string[] | Content[][]): ModalSizeObject =>
  content.reduce((acc, curr) => {
    const bodySize = getSizeNameFromContentLength(curr);

    if (!acc[bodySize]) {
      acc[bodySize] = true;
    }

    return acc;
  }, {});

const changeModalHeightBasedOnContentLength = (contents: string[] | Content[][]): void => {
  const isTextOnly = contents.every((content) => typeof content === 'string');
  const isRichTextOnly =
    !isTextOnly && contents.every((content: Content[]) => content.every((item) => item.contentType === 'text'));

  if (isTextOnly || isRichTextOnly) {
    const modalCloneWrapper = modalElement.value.cloneNode(true);
    document.body.appendChild(modalCloneWrapper);

    const modalCloneBody = modalCloneWrapper
      .querySelector('.c-modal__body')
      .querySelector('.c-rich-text') satisfies HTMLDivElement;
    const modalClone = modalCloneWrapper.querySelector('.c-modal') satisfies HTMLDivElement;

    modalClone.style.height = 'min-content';

    if (modalCloneBody) {
      const ULTRA_SHORT_CONTENT_OVERFLOW = 100;
      const MODAL_CONTROLS_HEIGHT = modalClone.querySelector('.c-modal__controls')?.offsetHeight ?? 0;
      const MODAL_HEADER_HEIGHT = modalClone.querySelector('.c-modal__header').offsetHeight;
      let maxSize = 0;

      contents.forEach((content) => {
        modalCloneBody.innerHTML = (content[0].content?.content || content[0].content) ?? content;
        if (modalClone.offsetHeight > maxSize) {
          maxSize = modalClone.offsetHeight;
        }
      });

      if (maxSize > window.innerHeight) {
        modalHeight.value = '95vh';
      } else {
        modalHeight.value = pxToRem(
          maxSize + ULTRA_SHORT_CONTENT_OVERFLOW + MODAL_CONTROLS_HEIGHT + MODAL_HEADER_HEIGHT,
        );
      }
    }
    document.body.removeChild(modalCloneWrapper);
  } else {
    const modalSize = getModalSizeFromContentLength(contents);

    if (modalSize[Sizes.AUTO]) {
      modalHeight.value = 'auto';
    } else if (modalSize[Sizes.SMALL]) {
      modalHeight.value = '30vh';
    } else if (modalSize[Sizes.MEDIUM]) {
      modalHeight.value = '50vh';
    }
  }
};

const { windowWidth } = useScss();

const registerModalHeightChange = (content: ComputedRef<string[] | Content[][]>) => {
  const observer = new MutationObserver(() => changeModalHeightBasedOnContentLength(content.value));

  onMounted(() => {
    changeModalHeightBasedOnContentLength(content.value);

    observer.observe(modalElement.value.querySelector('.c-modal__body'), { childList: true, subtree: true });
  });

  watch([windowWidth, content], () => {
    changeModalHeightBasedOnContentLength(content.value);
  });

  onUnmounted(() => {
    observer.disconnect();
    modalHeight.value = 'min-content';
  });
};

export default (): useModal => ({
  ...toRefs(state),
  modal,
  open,
  close,
  getModalSizeFromContentLength,
  initModalElement,
  modalHeight,
  registerModalHeightChange,
});
