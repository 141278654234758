const BYTES_IN_KILOBYTE = 1000;
const BYTES_IN_MEGABYTE = 1000000;

export default (bytes: number): string => {
  if (bytes < BYTES_IN_KILOBYTE) {
    return `${bytes} B`;
  }
  if (bytes < BYTES_IN_MEGABYTE) {
    return `${(bytes / BYTES_IN_KILOBYTE).toFixed(1)} KB`;
  }
  return `${(bytes / BYTES_IN_MEGABYTE).toFixed(1)} MB`;
};
