export class HttpError extends Error {
  constructor(message, status) {
    super(message)
    this.status = status
  }
}

const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json'
  }
}

const fetchWithAuth = (path, options) => {
  const mergedOptions = Object.assign({}, DEFAULT_HEADERS, options)

  if(localStorage.getItem('token')) {
    mergedOptions.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
  }

  return fetch(`${process.env.VUE_APP_COSS_URL}/${path}`, mergedOptions)
}

const fetchSH = async (param, options) => {
  const res = await fetchWithAuth(param, options)

  if (res.ok) {
    if (res.status === 204) {
      return res.text()
    }

    return res.json()
  }

  const err = await res.json()
  throw new HttpError(err.message, res.status || res.code);
}

export {
  fetchWithAuth,
  fetchSH
}
