import { CMSContent } from '@/core';
import { HasPermissions } from '@/model/response/Shared';

export interface FilterGroup {
  name: string;
  groupId: string;
}

export interface CategoryTag {
  name: string;
  tag: string;
  groupId: string;
}

export interface CategoryFilters {
  filterGroups: FilterGroup[];
  list: CategoryTag[];
}

export interface CategoryCategories {
  list: CategoryTag[];
}

export interface List {
  name: string;
  type: string;
  value: string;
}

export interface Levels {
  useType: string;
  list: List[];
}

export interface LevelsSection {
  levels: Levels;
  useLevels: boolean;
}

export interface AppLogo {
  altText: string;
  showName: boolean;
  desktopLogo: { imageId: string };
  mobileLogo: { imageId: string };
  tabletLogo: { imageId: string };
}

export enum ContentCardVariants {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  FRONTPAGE_BIG = 'frontpage_big',
  FRONTPAGE_SMALL = 'frontpage_small',
  SIMPLE = 'simple',
  HOME = 'home',
  SEARCH = 'search',
  BLOCK_ITEM = 'block-item',
  SHORTCUT = 'shortcut',
  SHORTCUT_THIN = 'shortcut-thin',
  LEARNING_PATH_ITEM = 'learning-path-item',
  LEARNING_PATH = 'learning-path',
  LEARNING_PATH_ADVANCE = 'learning-path-advance',
  SEARCH_DROPDOWN_ITEM = 'search-dropdown-item',
  SMALL = 'small',
  TEACHER_DASHBOARD = 'teacher-dashboard',
  ALL_CONTENT = 'all-content',
}

export interface UserFallback {
  addMessageToUser: boolean;
  body: string;
  buttonTitle: string;
  headingErrorMessage: string;
  urlLink: string;
}

export type ContentCardVariant = `${ContentCardVariants}`;

export interface Content {
  favicon: {
    imageId: string;
  };
  appLogo: AppLogo;
  levelsSection: LevelsSection;
  colorTheme: {
    value: string;
  };
  menus: {
    menuItems: Menu[];
  };
  customUrls?: {
    logoutRedirectUrl: string;
  };
  cardStyle: {
    value: ContentCardVariant;
  };
  teacherDashboard: {
    enableTeacherDashboard: boolean;
  };
  filters: CategoryFilters;
  categories: CategoryCategories;
  skills: CategoryCategories;
  productAccess: {
    productId?: string;
  };
  site: {
    title: string;
  };
  userFallbackAfterLogin: UserFallback;
}

export type MenuType = 'main' | 'footer';

export interface Menu {
  items: MenuItem[];
  type: MenuType;
}

export interface MenuItem extends HasPermissions {
  _id: string;
  name: string;
  contentType?: string;
  order: number;
  parent: string;
  type: 'internal' | 'external';
  url: string;
}

export type SettingsResponse = CMSContent<Content> & { localization: Content };

export type LanguagesResponse = { fallback: boolean; locale: string }[];
