import pxToRem from '@/utils/pxToRem';

interface UseOverlay {
  add: () => void;
  remove: () => void;
}

const OVERLAY_CLASS = 'body-overlay';
const OVERLAY_CLASS_WITH_SCROLL = 'body-overlay--scroll';
const OVERLAY_ACTIVE_CLASS = 'body-overlay--active';
const bodyClasslist = document.body.classList;

const add = () => {
  const scrollable = document.body.scrollHeight > window.innerHeight;
  document.body.style.setProperty('--body-scroll-distance', `${pxToRem(window.scrollY).toString()}`);

  bodyClasslist?.add(...[OVERLAY_ACTIVE_CLASS, ...(scrollable ? [OVERLAY_CLASS_WITH_SCROLL] : [])]);
};

const remove = () => {
  bodyClasslist.remove(OVERLAY_ACTIVE_CLASS);
  bodyClasslist.remove(OVERLAY_CLASS_WITH_SCROLL);
};

const setup = () => {
  remove();

  const initialized = bodyClasslist.contains(OVERLAY_CLASS);
  if (!initialized) {
    bodyClasslist.add(OVERLAY_CLASS);
  }
};

setup();

export default (): UseOverlay => ({
  add,
  remove,
});
