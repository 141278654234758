import i18n from '@/i18n';

export enum FormatTypes {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export type FormatTypesUnion = `${FormatTypes}`;
export type FormatDurationPart = { part: FormatTypesUnion; leadingZero?: true; label?: string };

const now = (): number => Date.now();
const { t } = i18n.global;

const timeInTimeValues = {
  hours: 24,
  minutes: 60,
  seconds: 60,
};

export const dateFormatters = {
  days: (seconds: number): number => seconds / (60 * 60 * 24),
  hours: (seconds: number): number => seconds / (60 * 60),
  minutes: (seconds: number): number => seconds / 60,
  seconds: (seconds: number): number => seconds,
};

const roundRawTime = (int: number): number => (int % 1 >= 0.5 ? Math.ceil(int) : Math.floor(int));

export const formatDuration = (
  seconds: number,
  parts: FormatDurationPart[] = [
    { part: FormatTypes.DAYS },
    { part: FormatTypes.HOURS },
    { part: FormatTypes.MINUTES, leadingZero: true },
    { part: FormatTypes.SECONDS, leadingZero: true },
  ],
  separator = ' ',
): string => {
  const date = parts
    .map(({ part, leadingZero, label }) => {
      const rawInt =
        parts.length > 1 && timeInTimeValues[part]
          ? Math.floor(dateFormatters[part](seconds) % timeInTimeValues[part])
          : roundRawTime(dateFormatters[part](seconds));

      const dateFormatted = leadingZero ? withTrailingZero(rawInt) : rawInt;

      return label === undefined
        ? t('TIME_LABEL.' + part.toUpperCase(), {
            count: dateFormatted,
          })
        : `${dateFormatted}${label}`;
    })
    .join(parts.length > 1 ? separator : '');

  return date;
};

export const withTrailingZero = (str: number): string => `0${str}`.substr(-2, 2);

export const secondsToString = (time: number): string => {
  return `${Math.floor(dateFormatters[FormatTypes.MINUTES](time))}:${withTrailingZero(roundRawTime(time % 60))}`;
};

export const isToday = (timestamp: number): boolean => {
  const today = new Date().setHours(0, 0, 0, 0);
  const date = new Date(timestamp).setHours(0, 0, 0, 0);

  return today === date;
};

export const padTo2Digits = (value: number): string => value?.toString().padStart(2, '0');

export default now;
