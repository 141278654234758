<template>
  <div ref="target" class="e-tooltip" @keyup.esc="hideTooltip">
    <div ref="button" class="e-tooltip__button" tabindex="0" v-on="events">
      <slot name="button" />
    </div>
    <div
      ref="tooltip"
      :class="['e-tooltip__content', { 'e-tooltip__content--shown': modelValue }]"
      data-test="tooltip-content"
    >
      <FocusLoop :disabled="!modelValue">
        <slot v-if="modelValue" name="content" />
      </FocusLoop>
      <div ref="arrowElement" class="e-tooltip__arrow" :class="{ 'e-tooltip__arrow--shown': modelValue }" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onUpdated, ref } from 'vue';
import { arrow, autoUpdate, computePosition, flip, offset, Placement, shift } from '@floating-ui/dom';
import pxToRem from '@/utils/pxToRem';
import clearPx from '@/utils/clearPx';
import { onEnterSpace } from '@/utils/keyboard';
import { onClickOutside } from '@vueuse/core';
import { FocusLoop } from '@vue-a11y/focus-loop';

interface BaseTooltipProps {
  placement?: Placement;
  click?: boolean;
  keydown?: boolean;
  hover?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<BaseTooltipProps>(), {
  placement: 'bottom-start',
  click: false,
  keydown: false,
  hover: false,
});

const button = ref<HTMLButtonElement>(null);
const tooltip = ref<HTMLElement>(null);
const arrowElement = ref<HTMLDivElement>(null);
const modelValue = defineModel<boolean>();
const OFFSET = 15;
const arrowWidth = ref(0);
const autoUpdateCleanup = ref(null);
const target = ref<HTMLElement>(null);

const hideTooltip = () => (modelValue.value = false);
const showTooltip = () => (modelValue.value = true);
const toggleTooltip = () => (modelValue.value = !modelValue.value);

onClickOutside(target, hideTooltip);

const events = {
  click: props.click ? toggleTooltip : null,
  keydown: props.keydown ? onEnterSpace(toggleTooltip) : null,
  mouseenter: props.hover ? showTooltip : null,
  mouseleave: props.hover ? hideTooltip : null,
};

const updateTooltipPosition = async () => {
  if (!(button.value && tooltip.value) || props.disabled) {
    return null;
  }
  const { x, y, placement, middlewareData } = await computePosition(button.value, tooltip.value, {
    placement: props.placement,
    middleware: [
      offset(arrowWidth.value / 2),
      shift(),
      flip(),
      arrow({ element: arrowElement.value, padding: arrowWidth.value }),
    ],
  });

  Object.assign(tooltip.value.style, {
    left: pxToRem(x - OFFSET),
    top: pxToRem(y),
  });

  const { x: arrowX, centerOffset: arrowY } = middlewareData.arrow;

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]];

  Object.assign(arrowElement.value.style, {
    left: arrowX != null ? pxToRem(arrowX) : '',
    top: arrowY != null ? pxToRem(arrowY) : '',
    right: '',
    bottom: '',
    [staticSide]: pxToRem(-arrowWidth.value / 2),
  });
};

onUpdated(() => {
  arrowWidth.value = clearPx(getComputedStyle(arrowElement.value).width);
  autoUpdateCleanup.value = autoUpdate(button.value, tooltip.value, updateTooltipPosition);
});

onBeforeUnmount(() => {
  if (autoUpdateCleanup.value) {
    autoUpdateCleanup.value();
  }
});
</script>
