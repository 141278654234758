export const fileDownload = async (filename: string, url: string): Promise<void> => {
  const link = document.createElement('a');

  link.download = filename;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
