import { createWebHistory, HistoryState, RouterHistory } from 'vue-router';

type HistoryLocation = string;

export class PublicWebHistory implements RouterHistory {
  private _history: RouterHistory;
  private _historyState: HistoryLocation[];
  private _pointer: number;

  constructor(originalHistory: RouterHistory) {
    this._history = originalHistory;
    this._historyState = [];
    this._pointer = 0;
  }

  get history() {
    return this._pointer === 0 ? this._historyState : this._historyState.slice(0, this._pointer);
  }

  get state() {
    return this._history.state;
  }

  get base() {
    return this._history.base;
  }

  get location() {
    return this._history.location;
  }

  push(to: HistoryLocation, data?: HistoryState): void {
    if (this._pointer) {
      this._historyState = this._historyState.slice(0, this._pointer);
      this._pointer = 0;
    }
    this._historyState.push(to);
    this._history.push(to, data);
  }

  replace(to: HistoryLocation, data?: HistoryState): void {
    if (this._pointer) {
      this._historyState = this._historyState.slice(0, this._pointer);
      this._pointer = 0;
    }

    this._historyState.pop();
    this._historyState.push(to);
    this._history.replace(to, data);
  }

  go(delta: number): void {
    if (delta > 0) {
      if (this._pointer < 0) {
        this._pointer = Math.min(delta + this._pointer, 0);
      }
    } else {
      this._pointer = Math.max(delta + this._pointer, -this._historyState.length);
    }
    this._history.go(delta);
  }

  listen(callback): () => void {
    return this._history.listen(callback);
  }

  createHref(location: HistoryLocation): string {
    return this._history.createHref(location);
  }

  destroy(): void {
    this._history.destroy();
  }
}

export const publicWebHistory = new PublicWebHistory(createWebHistory());
