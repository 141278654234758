import { applyTranslations } from '@/mapper/utils/applyTranslations';
import { TreeNode as RawTreeData } from '@/model/response/TreeNode';
import { FlatTree, TreeNode } from '@/model/TreeStructure';

const comparator = (a: RawTreeData, b: RawTreeData): number => a.order - b.order;
const partitionFn = (parent: TreeNode | null) => (a: RawTreeData) => Number(a.parent === (parent?.id || null));

const partition = <T>(data: T[], fn: (a: T) => number): T[][] => {
  const result: T[][] = [[], []];
  data.forEach((item) => {
    const index = fn(item);
    result[index].push(item);
  });
  return result;
};

export const buildTree = (
  data: RawTreeData[],
  parentNode: TreeNode | null = null,
  byId: FlatTree,
  bySlug: FlatTree,
): TreeNode[] => {
  const [other, currentLevel] = partition(data || [], partitionFn(parentNode));

  return currentLevel.sort(comparator).map((item): TreeNode => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, localization, ...otherProps } = item;
    const translatedContent = applyTranslations(otherProps, localization.data?.content);
    const el: TreeNode = {
      ...translatedContent,
      name: translatedContent.name || '',
      parent: parentNode,
      children: [],
      slugPath: parentNode ? `${parentNode.slugPath}/${item.slug}` : item.slug,
    };

    el.children = buildTree(other, el, byId, bySlug);

    byId[el.id] = el;
    bySlug[el.slugPath] = el;

    return el;
  });
};
