import { ref, Ref } from 'vue';
import { LocaleCollection } from '@/model/response';
import useLocalization from '@/services/useLocalization';
import { Language } from '@/i18n';
import useLocalStorage from '@/services/useLocalStorage';

interface useLocalLanguage {
  locales: string[];
  activeLocale: Ref<string>;
  setActiveLocale: (newLocale: string) => void;
}

const activeLocale = ref<Language>(null);

export default (rawLocales: LocaleCollection = []): useLocalLanguage => {
  const { locale } = useLocalization();
  const { getCurrentLocale, getSecondaryLocale, setSecondaryLocale } = useLocalStorage();
  const secondaryLocale = getSecondaryLocale();

  activeLocale.value = rawLocales.includes(secondaryLocale) ? secondaryLocale : getCurrentLocale() || locale.value;

  const locales = [...new Set([...rawLocales, locale.value])];

  const setActiveLocale = (newLocale: Language) => {
    activeLocale.value = newLocale;
    setSecondaryLocale(newLocale);
  };

  return {
    locales,
    activeLocale,
    setActiveLocale,
  };
};
