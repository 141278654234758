export interface HasName {
  displayName?: string;
  raw?: {
    name?: {
      givenName?: string;
      middleName?: string;
      familyName?: string;
    };
  };
}

export const prepareDisplayName = <T extends HasName>(user: T): string => {
  try {
    const names = [user.raw?.name?.givenName, user.raw?.name?.middleName, user.raw?.name?.familyName]
      .map((a) => a?.trim())
      .filter(Boolean);

    return names.join(' ') || user.displayName?.trim() || '';
  } catch (e) {
    return '';
  }
};

export const getInitials = (username: string): string => {
  if (!username || !username.trim()) {
    return '';
  }

  const nameArray = username.trim().split(' ');
  return nameArray[0][0] + nameArray[nameArray.length - 1][0];
};
