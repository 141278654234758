import { MultimediaModel } from '@/model/page/Multimedia';
import { MultimediaResponse } from '@/model/response';
import { mapPage } from '@/mapper/Page';
import parseReadingTime from '@/mapper/utils/parseReadingTime';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapMultimedia = (pageResponse: MultimediaResponse): MultimediaModel => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.content?.data?.content, response.content?.data?.localization);

  return {
    ...mapPage(pageResponse),
    competencyAims: content?.competencyAims,
    audio: content?.multimedia?.audio,
    video: content?.multimedia?.video,
    relatedLinks: content?.relatedLinks?.items?.map((link) => ({
      to: link.contentUrl,
      title: link.title,
      contentId: link.contentId,
    })),
    time: content?.readingTime ? parseReadingTime(content.readingTime) : null,
  };
};
