import { ChildContentType } from '@/constants/ChildContentType';
import { ChildrenContent } from '@/model/response';
import {
  accordionMapper,
  checklistMapper,
  factBoxMapper,
  imageMapper,
  sideBySideMapper,
  textMapper,
} from '@/services/useContent/mapper';
import {
  AccordionModel,
  ChecklistModel,
  FactBoxModel,
  ImageModel,
  RichTextModel,
  SideBySideModel,
} from '@/model/content';
import i18n from '@/i18n';

export const mapContentToText = (contentType: ChildContentType | string, content: ChildrenContent): string => {
  const { t } = i18n.global;
  {
    const context = {
      translate: t,
      mapContentToText,
    };

    switch (contentType) {
      case ChildContentType.FACTBOX:
        return factBoxMapper.mapContent(content as FactBoxModel, context);
      case ChildContentType.IMAGE:
        return imageMapper.mapContent(content as ImageModel, context);
      case ChildContentType.TEXT:
        return textMapper.mapContent(content as RichTextModel);
      case ChildContentType.ACCORDIONS:
        return accordionMapper.mapContent(content as AccordionModel, context);
      case ChildContentType.ARTICLE_LEFT_RIGHT:
      case ChildContentType.LEFT_RIGHT:
        return sideBySideMapper.mapContent(content as SideBySideModel, context);
      case ChildContentType.CHECKLIST:
        return checklistMapper.mapContent(content as ChecklistModel, context);
      default:
        return '';
    }
  }
};
