import { useAuth } from '@/core';
import DB, { DBInterface } from '@/core/services/db';
import { reactive, Ref, toRefs, UnwrapNestedRefs } from 'vue';
import useNamespace from './useNamespace';
import * as PouchDB from 'pouchdb';
import { AccessLevel } from '@/model/response';
import useMessageBanner from '@/services/useMessageBanner';

export const SyncStatus = {
  SYNCED: 'synced',
  SYNCING: 'syncing',
  FAILED: 'failed',
} as const;

interface DatabaseEntry {
  loading: boolean;
  database: DBInterface;
  sync?: string;
}

interface DatabaseEntryAsRefs {
  loading: Ref<boolean>;
  database: Ref<DBInterface>;
  sync?: Ref<string>;
}

interface Databases {
  [dbName: string]: UnwrapNestedRefs<DatabaseEntry>;
}

interface UseDB extends DatabaseEntryAsRefs {
  setupDBs: (dbNames: string[]) => Promise<void>;
  replicationOptions: (dbName: string) => PouchDB.Replication.ReplicateOptions;
}

export interface DatabaseDocument {
  _id: string;
  entryType: string;
  productId: string;
  user: string;
  _deleted?: boolean;
}

const databases: Databases = reactive({});

const useDB = (dbName?: string): UseDB => {
  const { namespace } = useNamespace();
  const { user } = useAuth();
  const ViteE2E = import.meta.env.VITE_E2E;

  const replicationOptions = (dbName: string) => ({
    filter: (doc: DatabaseDocument) => {
      const isCurrentNamespace = doc.productId === namespace.id;
      const isCurrentUser = user.value._id === doc.user;
      const isDeleted = doc._deleted;

      const isCurrentType = doc.entryType === dbName;

      return !isDeleted && isCurrentNamespace && isCurrentUser && isCurrentType && doc;
    },
  });

  const createDB = (dbName: string) => ({
    [dbName]: {
      loading: Boolean(user.value?._id),
      database: new DB(`${dbName}:${namespace?.id}:${user.value?._id ?? AccessLevel.ANONYMOUS}`, {
        revs_limit: 1,
        auto_compaction: true,
      }),
      sync: null,
    },
  });

  const syncDB = async (dbName: string) => {
    if (!dbName || ViteE2E) return;
    try {
      if (databases[dbName]?.sync === SyncStatus.SYNCED || databases[dbName]?.sync === SyncStatus.SYNCING) return;
      databases[dbName].sync = SyncStatus.SYNCING;
      const syncId = await databases[dbName].database.initSync(replicationOptions(dbName));
      if (syncId) {
        databases[dbName].sync = SyncStatus.SYNCED;
      }
      return syncId;
    } catch (error) {
      databases[dbName].sync = SyncStatus.FAILED;
      console.error(error);
      useMessageBanner().addMessage(error.message);
    } finally {
      databases[dbName].loading = false;
    }
  };

  const syncDbs = (dbNames: string[]) => {
    return Promise.all(dbNames.map(syncDB));
  };

  const setupDBs = async (dbNames: string[]): Promise<void> => {
    const tempDbs = dbNames.reduce((databasesEntry, dbName) => {
      return {
        ...databasesEntry,
        ...createDB(dbName),
      };
    }, {});

    Object.assign(databases, tempDbs);

    if (user.value?._id) {
      syncDbs(dbNames);
    }
  };

  // NOTE: For some reason returning it without function resulted in undefined
  const getDB = () => databases[dbName];

  return {
    ...toRefs(getDB()),
    setupDBs,
    replicationOptions,
  };
};

export default useDB;
