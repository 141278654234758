import { Asset, useAssets } from '@/core';
import withErrorCatch from '@/utils/withErrorCatch';

export interface UseAssets {
  getAsset: (assetId: string, namespace?: string) => Promise<Asset>;
}

const { fetchAsset, getAssetById } = useAssets();

const getAsset = async (assetId: string, namespace?: string) => {
  if (assetId) {
    let asset = getAssetById(assetId);

    if (!asset) {
      await withErrorCatch(async () => {
        await fetchAsset(assetId, {
          namespace,
        });
        asset = getAssetById(assetId);
      }, assetId);
    }

    return asset;
  }
};

export default (): UseAssets => ({
  getAsset,
});
