import { CardAdapter, CardInterface } from '@/model/Card';
import { Ref, ref } from 'vue';
import useElasticSearch from '@/services/useElasticSearch';
import useLoading from '@/services/useLoading';
import { Aggregation, Aggregations } from '@/model/search/response/Aggregations';
import useLocalization from '@/services/useLocalization';
import { Hit } from '@/model/search/response/Hits';
import { parseReadingTime } from '@/mapper';
import { FilterTags } from '@/config/Constants';
import { Tags as HitTags } from '@/model/search/response/HitSource';
import { Tag as ResponseTag } from '@/model/response';
import { Identifiable } from '@/model/Identifiable';
import { useCounter } from '@vueuse/core';
import { SearchQuery } from '@/types/search';
import useMessageBanner from '@/services/useMessageBanner';
import { mapCard } from '@/mapper/Card';
import { removeLastSlashFromPath } from '@/utils/search';
import { Countable } from '@/model/Countable';
import { SkillType } from '@/config/Skills';

interface UseSearch {
  search: (query: SearchQuery) => Promise<void>;
  results: Ref<CardInterface[]>;
  nextPage: (query: SearchQuery) => void;
  totalResults: Ref<number>;
  loading: Ref<boolean>;
  setupAggregations: (query: SearchQuery) => Promise<void>;
  aggregations: Ref<Aggregations>;
  mapCategoryTypeToCountedContentType: (categoryType: Identifiable) => Identifiable & Countable;
  getSkillsAggregation: () => Countable[];
}

const setup = (): UseSearch => {
  const { fetch } = useElasticSearch();
  const { locale } = useLocalization();
  const { loading, onLoadStart, onLoadFinish } = useLoading();

  const lastHit = ref<Hit>(null);
  const aggregations = ref({});
  const results = ref([]);
  const totalResults = ref<number>(null);
  const { count: counter, inc: counterIncrement, dec: counterDecrement, reset: counterReset } = useCounter();

  // This is to setup initial aggregations, used in categoryPage prevents filters from changing between queries
  const setupAggregations = async (query: SearchQuery) => {
    try {
      const response = await fetch(query, {
        locale: locale.value,
      });
      aggregations.value = response.aggregations;
    } catch (error) {
      console.error('While setting up search filters:' + error);
    }
  };

  const search = async (query: SearchQuery) => {
    counterIncrement();
    onLoadStart();

    try {
      results.value = [];
      const response = await fetch(query, {
        locale: locale.value,
      });

      const hits = response.hits.hits;
      totalResults.value = response.hits.total.value;
      lastHit.value = hits[hits.length - 1];

      if (response.aggregations) {
        aggregations.value = response.aggregations;
      }

      const resolvedCards = await resolveCards(hits);

      if (counter.value > 1) {
        counterDecrement();
        return;
      }

      counterReset();
      results.value = resolvedCards;

      onLoadFinish();
    } catch (error) {
      console.error(error);
      useMessageBanner().addMessage(error.message);
      onLoadFinish();
    }
  };

  const nextPage = async (query: Record<string, string>) => {
    if (loading.value || !lastHit.value) return;
    onLoadStart();

    try {
      const paginatedQuery = {
        ...query,
        search_after: lastHit.value.sort,
      };

      const response = await fetch(paginatedQuery, {
        locale: locale.value,
      });

      const hits = response.hits.hits;
      lastHit.value = hits[hits.length - 1];

      const resolvedCards = await resolveCards(hits);

      results.value.push(...resolvedCards);
    } catch (error) {
      console.error(error);
      useMessageBanner().addMessage(error.message);
    } finally {
      onLoadFinish();
    }
  };

  const resolveCards = async (searchHitsCollection: Hit[]) => {
    const articles = await Promise.all(
      searchHitsCollection.map(async (hit): Promise<CardAdapter> => {
        const hitSource = hit?._source;
        const hitBody = hitSource?.body;

        const articleQueryablePath = removeLastSlashFromPath(hitSource?.path);

        const mapHitTagToArticleTag = (hitTags: HitTags) =>
          Object.keys(hitTags).flatMap((hitTag) =>
            hitTags[hitTag].map((keyword): ResponseTag => ({ source: hitTag, keyword })),
          );

        const mapHitHighlightToString = (highlight: string[]) =>
          highlight.reduce((highlights, highlight) => highlights.concat(highlight, ' '));

        return {
          id: hitSource.id,
          contentType: hitSource.kind,
          categoryKeyword: hitSource.tags?.[FilterTags.Category]?.[0],
          title: hitSource?.title || hitBody?.learningPath?.name,
          description: hit.highlight?.texts ? mapHitHighlightToString(hit.highlight.texts) : hitBody?.description ?? '',
          time:
            hitBody.learningPath?.metadata?.duration?.toString() ??
            (hitBody?.timeEstimate || (hitBody?.readingTime && parseReadingTime(hitBody.readingTime))),
          slug: articleQueryablePath,
          image: {
            assetId: hitBody?.cover || hitBody?.cardImage?.imageId || hitBody?.learningPath?.metadata?.bannerId,
            altText: hitBody?.altText || hitBody?.cardImage?.altText || hitBody?.learningPath?.metadata?.bannerAlt,
            copyright:
              hitBody?.copyright || hitBody?.cardImage?.copyright || hitBody?.learningPath?.metadata?.bannerCopyright,
          },
          pages: hitBody?.learningPath?.elementsCount,
          tags: hitSource.tags && mapHitTagToArticleTag(hitSource.tags),
          document: hitBody.cardDocuments?.documents?.[0],
          namespaceId: hitBody?.learningPath?.namespaceId ?? hitSource.namespace,
          author: hitBody?.author || hitBody?.learningPath?.metadata?.author,

          parentId: articleQueryablePath.split('/')[0],
          ...(hitBody.learningPath ? { learningPath: hitBody.learningPath } : {}),
        };
      }),
    );

    return Promise.all(articles.map((article) => mapCard(article))) || [];
  };

  const mapCategoryTypeToCountedContentType = (categoryType: Identifiable) => ({
    id: categoryType?.id,
    name: categoryType?.name,
    amount:
      (aggregations.value?.['by_category_tag'] as Aggregation)?.buckets?.find(
        (bucket) => bucket?.key === categoryType?.id,
      )?.doc_count ?? null,
  });

  const getSkillsAggregation = (): Countable[] => {
    const buckets = (aggregations.value?.['by_skills_tag'] as Aggregation)?.buckets;

    return buckets?.length
      ? buckets.map((bucket) => ({
          id: bucket.key,
          amount: bucket.doc_count,
        }))
      : Object.values(SkillType).map((skill) => ({
          id: skill,
          amount: 0,
        }));
  };

  return {
    results,
    search,
    nextPage,
    loading,
    setupAggregations,
    aggregations,
    totalResults,
    mapCategoryTypeToCountedContentType,
    getSkillsAggregation,
  };
};

export default setup;
