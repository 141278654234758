import { computed, watch } from 'vue';
import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query';
import useLocalization from '@/services/useLocalization';
import { treeStructureQuery } from '@/queries/treeStructureQuery';
import useLoading from '@/services/useLoading';
import { buildTree } from '@/utils/buildTree';
import { ContentTypeEnum } from '@/config/ContentType';

// This setting forces data fetch until depletion on backend or error
const ENTRIES_PER_PAGE_LIMIT = 100;
const LOADER_KEY = 'treeStructure';
const DAY_IN_MS = 1000 * 60 * 60 * 24;

const setup = () => {
  const { locale } = useLocalization();
  const { onGlobalLoadStart, onGlobalLoadFinish } = useLoading();
  const queryClient = useQueryClient();

  onGlobalLoadStart(LOADER_KEY);

  const queryOptions = {
    queryKey: ['structures', locale],
    queryFn: ({ pageParam }) => treeStructureQuery(locale.value, pageParam, ENTRIES_PER_PAGE_LIMIT),
    getNextPageParam: (lastPage, _, lastPageParam) => {
      const isLastPage = lastPage.length < ENTRIES_PER_PAGE_LIMIT;
      // null || undefined for nextPage means thats we've reached end of collection
      const nextPage: number = isLastPage ? null : lastPageParam + 1;

      return nextPage;
    },
    initialPageParam: 0,
    /*
      NOTE: Product `4ea9110d-1b68-497c-a4f1-6e10b5aeba72` (puls)
      has so many entries we had to add that much pages to prefetch
      fortunately this works until the getNextPageParam returns
      null / undefined before reaching this limit in most products
    */
    pages: 50,
    staleTime: DAY_IN_MS,
  };

  watch(
    locale,
    () => {
      queryClient.prefetchInfiniteQuery(queryOptions).finally(() => {
        onGlobalLoadFinish(LOADER_KEY);
      });
    },
    {
      immediate: true,
    },
  );

  const query = useInfiniteQuery(queryOptions);

  const treeStructure = computed(() => {
    const notTreeStructureDirectory = [ContentTypeEnum.CONCEPT_PAGE, ContentTypeEnum.FRONT_PAGE];

    const filterOutNotTreeStructureDirectory = (structureData) =>
      !notTreeStructureDirectory.includes(structureData.contentType);

    const entries = [...query.data.value.pages.flat().filter(filterOutNotTreeStructureDirectory)];

    const byId = {};
    const bySlug = {};
    const tree = entries.length ? buildTree(entries, null, byId, bySlug) : [];

    return {
      tree,
      byId,
      bySlug,
    };
  });

  return {
    ...query,
    treeStructure,
  };
};

let instance = undefined;

export default () => {
  if (instance === undefined) {
    instance = setup();
  }

  return instance;
};
