import { RichTextModel } from '@/model/content';
import { TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';

class TextMapper implements TextToSpeechMapper {
  mapContent(content: RichTextModel): string {
    return content?.content || '';
  }
}

export const textMapper = new TextMapper();
