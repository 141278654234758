import { computed, Ref } from 'vue';
import { useAuth } from '@/core';
import XAPI, { Activity, Agent, Statement } from '@xapi/xapi';
import xapi from '@/core/services/lrs';
import useLoading from '@/services/useLoading';
import useMessageBanner from '@/services/useMessageBanner';
import { useMemoize } from '@vueuse/core';

interface UseLRS {
  loading: Ref<boolean>;
  fetchStatements: (id: string, verbId?: string) => Promise<Statement[]>;
  saveStatement: (statement: unknown) => Promise<string[]>;
}

let XapiRead: XAPI;
let XapiWrite: XAPI;
const EPORTAL_URL = 'https://id.eportal.fagbokforlaget.no';
const ViteE2E = import.meta.env.VITE_E2E;

export default (): UseLRS => {
  if (!XapiRead) {
    XapiRead = xapi({});
  }

  if (!XapiWrite) {
    XapiWrite = xapi({ lrsPath: process.env.VUE_APP_LRS_ENDPOINT });
  }

  const { user } = useAuth();
  const { loading, onLoadStart, onLoadFinish } = useLoading();
  const actor = computed<Agent>(() => ({
    objectType: 'Agent',
    account: {
      homePage: EPORTAL_URL,
      name: user.value?.providerId,
    },
  }));

  const fetchStatements = useMemoize(async (activityId: string, verbId?: string): Promise<Statement[]> => {
    if (!actor.value?.account?.name || ViteE2E) {
      return [];
    }

    onLoadStart();
    try {
      const response = await XapiRead.getStatements({
        agent: { account: actor.value.account } as Agent,
        activity: activityId,
        verb: verbId,
        limit: 1,
      });

      return response.data.statements;
    } catch (error) {
      console.error(`While fetching statement: ${activityId}`, error);
      useMessageBanner().addMessage(error.message);
    } finally {
      onLoadFinish();
    }
  });

  const saveStatement = async (query: Omit<Statement, 'actor'>): Promise<string[]> => {
    if (!actor.value?.account?.name || ViteE2E) {
      return [];
    }

    const statement = { ...query, actor: actor.value };

    try {
      const request = await XapiWrite.sendStatement({
        statement,
      });

      return request.data;
    } catch (error) {
      console.error(`While saving statement: ${(statement.object as Activity).id}`, error);
      useMessageBanner().addMessage(error.message);
    }
  };

  return {
    fetchStatements,
    saveStatement,
    loading,
  };
};
