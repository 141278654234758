<template>
  <render />
</template>

<script lang="ts" setup>
import { h, useSlots, withModifiers } from 'vue';
import { CardInterface } from '@/model/Card';
import useConcept from '@/services/useConcept';
import BaseLink from '@/elements/link/BaseLink.vue';
import { ContentTypeEnum } from '@/config/ContentType';
import { ContentCardVariants } from '@/model/response/Settings';

const slots = useSlots();

interface GenericCard {
  card: CardInterface;
  variant?: string;
  downloadOnClick?: boolean;
  onClickFunc?: () => Promise<unknown>;
}

const props = defineProps<GenericCard>();

const concept = useConcept();
const onClick = () => {
  concept.open(props.card);
};

const isDocumentType = props.card.type === ContentTypeEnum.DOCUMENT;
const hasFile = Boolean(props.card.file);
const isConcept = props.card.type === ContentTypeEnum.CONCEPT;

const isNotLink =
  isConcept || props.onClickFunc || (!props.downloadOnClick && isDocumentType && hasFile) || !props.card.to;

const render = () => {
  return h(
    isNotLink ? 'div' : BaseLink,
    {
      'data-test': 'card',
      onClick: (props.onClickFunc && props.onClickFunc) || (isConcept && withModifiers(onClick, ['prevent'])),
      onKeypress:
        (props.onClickFunc && props.onClickFunc) || (isConcept && withModifiers(onClick, ['prevent', 'enter'])),
      to: !isNotLink && props.card.to,
      replace: props.variant === ContentCardVariants.LEARNING_PATH_ITEM,
      ...(hasFile && props.downloadOnClick
        ? {
            download: props.card.file.filename,
            href: props.card.file.url,
            target: '_blank',
          }
        : props.card?.newTab
          ? { target: '_blank' }
          : { tabindex: 0 }),
    },
    isNotLink ? slots.default() : () => slots.default(),
  );
};
</script>
