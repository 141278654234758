import { aggregationObjects } from '@/queries/activity-data/aggregationObjects';

interface SearchActivityDataParams {
  namespace?: string;
  student?: string;
  students?: Array<string>;
  learningPathProxyId?: string;
  learningPathId?: string;
  type: ActivityDataTypes;
  timegraph?: {
    from: string;
    to: string;
    interval: ActivityDataTimegraphIntervals;
  };
}

interface SearchActivityDataFilters {
  terms?: {
    [key: string]: Array<string>;
  };
  term: {
    [key: string]: string;
  };
}

export enum ActivityDataTimegraphIntervals {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum ActivityDataTypes {
  LEARNING_PATH = 'learning-path',
  LEARNING_PATH_ELEMENT = 'learning-path-element',
}

export enum ActivityDataAggregationNames {
  ALL_STUDENTS_VIEW = 'allStudentsView',
  SINGLE_STUDENT_VIEW_LP = 'singleStudentViewLP',
  SINGLE_STUDENT_VIEW_LP_DETAILS = 'singleStudentViewLPDetails',
  SINGLE_STUDENT_TIMEGRAPH = 'singleStudentTimegraph',
}

enum FilterOptions {
  student = 'statement.actor.account.name',
  // eslint-disable-next-line  @typescript-eslint/no-duplicate-enum-values
  students = 'statement.actor.account.name',
  learningPathProxyId = 'custom.learningPathProxy.id',
  learningPath = 'custom.learningPath.id',
  namespace = 'custom.learningPathProxy.namespaceId',
}

export const searchActivityData = (
  options: SearchActivityDataParams,
  aggregationName: ActivityDataAggregationNames,
) => ({
  ...prepareQuery(options),
  aggs: options.type === 'learning-path' ? prepareAggs(options) : aggregationObjects[aggregationName],
});

const prepareQuery = (options: SearchActivityDataParams) => ({
  size: 0,
  query: {
    bool: {
      filter: prepareFilters(options),
      ...(options.type && prepareTypeLimiter(options.type)),
    },
  },
});

const prepareFilters = (options: SearchActivityDataParams): SearchActivityDataFilters[] => {
  const filters = [];

  for (const option in FilterOptions) {
    const filterObj = {};

    if (Object.keys(options).indexOf(option) !== -1) {
      filterObj[Array.isArray(options[option]) ? 'terms' : 'term'] = {
        [FilterOptions[option]]: options[option],
      };

      filters.push(filterObj);
    }
  }

  return filters;
};

const prepareAggs = (options: SearchActivityDataParams) => {
  const aggs = aggregationObjects.singleStudentTimegraph;

  aggs.timegraph_range.date_range.ranges[0] = {
    from: options.timegraph.from,
    to: options.timegraph.to,
  };
  aggs.timegraph_range.aggs.activity_over_time.date_histogram.calendar_interval = options.timegraph.interval;

  return aggs;
};

const prepareTypeLimiter = (type: string) => ({
  must: {
    exists: {
      field: type === ActivityDataTypes.LEARNING_PATH ? 'custom.learningPath' : 'custom.learningPathElementStructure',
    },
  },
  ...(type === ActivityDataTypes.LEARNING_PATH && {
    must_not: {
      exists: {
        field: 'custom.learningPathElementStructure',
      },
    },
  }),
});
