<template>
  <div v-if="open" class="c-message-banner">
    <div class="c-message">
      <InfoIcon class="c-message__icon" />
      <div class="c-message__text">
        {{ $t('MESSAGE_BANNER.ERROR') }}
      </div>
      <BaseButton
        reset
        class="c-message__btn-close"
        :title="$t('ARIA.CLOSE_MODAL')"
        :aria-label="$t('ARIA.CLOSE_MODAL')"
        tabindex="0"
        data-test="message-banner-btn-close"
        @click="clearMessages"
        @keyup.enter="clearMessages"
      >
        <CloseIcon />
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useMessages from '@/services/useMessageBanner';
import BaseButton from '@/elements/button/BaseButton.vue';
import CloseIcon from '@/assets/icons/CloseIcon.vue';
import InfoIcon from '@/assets/icons/InfoIcon.vue';

const { open, clearMessages } = useMessages();
</script>
