import { Verbs } from '@/model/response/LRS';

export const aggregationObjects = {
  allStudentsView: {
    by_user: {
      terms: {
        field: 'statement.actor.account.name',
      },
      aggs: {
        by_lp_proxy_id: {
          terms: {
            field: 'custom.learningPathProxy.id',
            size: 1,
            order: {
              max_statement_timestamp: 'desc',
            },
          },
          aggs: {
            lp_doc: {
              top_hits: {
                size: 1,
                _source: ['statement', 'custom'],
                sort: [
                  {
                    'statement.timestamp': {
                      order: 'desc',
                    },
                  },
                ],
              },
            },
            max_statement_timestamp: {
              max: {
                field: 'statement.timestamp',
              },
            },
            completed: {
              filter: {
                term: {
                  'statement.verb.id': Verbs.COMPLETED,
                },
              },
              aggs: {
                lp_elements_count: {
                  terms: {
                    field: 'custom.learningPathElementStructure.id',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  singleStudentViewLP: {
    by_lp_proxy_id: {
      terms: {
        field: 'custom.learningPathProxy.id',
        order: {
          max_statement_timestamp: 'desc',
        },
      },
      aggs: {
        lp_doc: {
          top_hits: {
            size: 1,
            _source: ['statement', 'custom'],
            sort: [
              {
                'statement.timestamp': {
                  order: 'desc',
                },
              },
            ],
          },
        },
        max_statement_timestamp: {
          max: {
            field: 'statement.timestamp',
          },
        },
        completed: {
          filter: {
            term: {
              'statement.verb.id': Verbs.COMPLETED,
            },
          },
          aggs: {
            lp_elements_count: {
              terms: {
                field: 'custom.learningPathElementStructure.id',
              },
            },
          },
        },
      },
    },
  },
  singleStudentViewLPDetails: {
    by_lp_e_id: {
      terms: {
        field: 'custom.learningPathElementStructure.id',
      },
      aggs: {
        lp_e_doc: {
          top_hits: {
            size: 1,
            _source: ['statement', 'custom'],
            sort: [
              {
                'statement.timestamp': {
                  order: 'desc',
                },
              },
            ],
          },
        },
      },
    },
  },
  singleStudentTimegraph: {
    timegraph_range: {
      date_range: {
        field: 'statement.timestamp',
        ranges: [],
      },
      aggs: {
        activity_over_time: {
          date_histogram: {
            field: 'statement.timestamp',
            calendar_interval: 'month',
          },
          aggs: {
            by_lp_proxy_id: {
              terms: {
                field: 'custom.learningPathProxy.id',
              },
            },
          },
        },
      },
    },
  },
};
