import { queryActivityData } from '@/core/services/activity-data';
import { ActivityDataResponse } from '@/model/response/ActivityData';

interface UseActivitySearch {
  fetchActivityData: <Aggregation>(query: unknown) => Promise<ActivityDataResponse<Aggregation>>;
}

const fetchActivityData = async <Aggregation>(query: unknown): Promise<ActivityDataResponse<Aggregation>> => {
  return queryActivityData(query);
};

export default (): UseActivitySearch => ({
  fetchActivityData,
});
