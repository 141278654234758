import { StructureResponse } from '@/model/response';
import useGraphQl from '@/services/useGraphQl';
import { learningPathQuery, query } from '@/queries/gql';

const { getObject } = useGraphQl();

export async function commonFetch<T>(
  contentId: string,
  locale: string,
  includeChildren = true,
): Promise<StructureResponse<T>> {
  return await getObject<StructureResponse<T>>(query, contentId, {
    page: 0,
    limit: 200,
    locale,
    includeChildren,
  });
}

export async function learningPathDetailsFetch<T>(contentId: string, locale: string): Promise<StructureResponse<T>> {
  return await getObject<StructureResponse<T>>(learningPathQuery, contentId, { locale });
}
