import { Statement } from '@xapi/xapi';

export const Verbs = {
  COMPLETED: `https://adlnet.gov/expapi/verbs/completed`,
  PROGRESSED: `https://adlnet.gov/expapi/verbs/progressed`,
} as const;

export const VerbsDisplay = {
  COMPLETED: 'completed',
  PROGRESSED: 'progressed',
} as const;

export type VerbDisplay = (typeof VerbsDisplay)[keyof typeof VerbsDisplay];

// general statement does not allow to have extensions in definition of ContextActivity
// we also needs to omit the actor in Statement, as it is added by some middleware
export type AdjustedStatement = Omit<Statement, 'actor'> & {
  context?: { contextActivities: { parent: object[] } };
};
