import { SearchStateFilterEntry } from '@/types/search';

const mapToQuery = (action) => (value) => ({ [action]: { path: { value } } });
const createRegexp = (value) => `${value}/.+/.+`;
const findParentPath = (current: string) => (item: string) =>
  item.startsWith(current) && item.substring(current.length).startsWith('/');
// checking for the slash avoids situation of filtering out pairs like "cat" and "category"
// only when "cat" and "cat/european" will be together, "cat" is going to be removed

const prepareMeaningfulPaths = (paths: SearchStateFilterEntry = {}) => {
  const selectedPaths = Object.keys(paths)
    .filter((path) => paths[path])
    .sort()
    .reverse();

  return selectedPaths.reduce(
    (aggregator: string[], current: string) =>
      aggregator.find(findParentPath(current)) ? aggregator : [...aggregator, current],
    [],
  );
};

export const preparePaths = (paths: SearchStateFilterEntry = {}) => {
  const filters = prepareMeaningfulPaths(paths).map(mapToQuery('prefix'));

  return filters.length > 1 ? [{ bool: { should: filters, minimum_should_match: 1 } }] : filters;
};

export const prepareSinglePath = (paths: SearchStateFilterEntry = {}, hideChildren = false) => {
  const meaningfulPaths = prepareMeaningfulPaths(paths);

  return meaningfulPaths.length
    ? [
        {
          bool: {
            must: meaningfulPaths.slice(0, 1).map(mapToQuery('term')),
            ...(hideChildren
              ? { must_not: meaningfulPaths.slice(0, 1).map(createRegexp).map(mapToQuery('regexp')) }
              : {}),
          },
        },
      ]
    : [];
};
