<template>
  <LearningPathHeader
    v-if="currentLearningPath"
    :title="currentLearningPath.title"
    :image="image"
    :elements="elements"
    :show-elements="showElements"
  />
</template>

<script setup lang="ts">
import LearningPathHeader from '@/components/learning-path-header/LearningPathHeader.vue';
import { computed, ref, watch } from 'vue';
import useCachedAssets from '@/services/useCachedAssets';
import { ElementStatus } from '@/components/learning-path-header/LearningPathHeaderElements.vue';
import { LearningPathElementStatus } from '@/model/component/LearningPathElementStatus';
import { useRoute } from 'vue-router';
import usePage from '@/services/usePage';
import useProgressTracking from '@/services/useProgressTracking';
import { LearningPathProgress } from '@/model/Progression';
import { PageName } from '@/constants/PageName';

const currentIndex = ref();

const route = useRoute();

const { currentLearningPath } = usePage();
const showElements = computed(() => !!currentIndex.value || route.name === PageName.LEARNING_LAST);

const { getAsset } = useCachedAssets();

const resolveImage = async (assetId) => {
  const asset = await getAsset(assetId);
  return asset?.url;
};

const image = ref();

const { subscribe } = useProgressTracking();
const progression = subscribe();

const isElementDone = (progress): boolean => {
  return progress?.state === LearningPathProgress.FINISHED;
};

const getStatus = (progress, index): LearningPathElementStatus => {
  if (currentIndex.value - 1 === index) {
    return isElementDone(progress) ? LearningPathElementStatus.CURRENT_DONE : LearningPathElementStatus.CURRENT;
  } else if (isElementDone(progress)) {
    return LearningPathElementStatus.DONE;
  } else {
    return LearningPathElementStatus.NONE;
  }
};

const elements = ref([]);

watch(
  [() => currentLearningPath.value?.children, progression, currentIndex],
  () => {
    elements.value =
      currentLearningPath.value?.children?.map(
        (child, index): ElementStatus => ({
          status: getStatus(
            progression.value.find((item) => item.id === child.id),
            index,
          ),
          index: index + 1,
          to: `${index + 1}`,
        }),
      ) || [];
  },
  { immediate: true },
);

watch(
  [currentLearningPath],
  async () => {
    if (currentLearningPath.value?.image?.assetId) {
      const newAsset = await resolveImage(currentLearningPath.value.image.assetId);
      if (newAsset !== image.value?.src) {
        image.value = {
          src: newAsset,
          alt: currentLearningPath.value.image.altText || '',
        };
      }
    } else {
      image.value = undefined;
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => route.params.step,
  () => {
    if (route.params.step && typeof route.params.step === 'string') {
      currentIndex.value = parseInt(route.params.step, 10);
    } else {
      currentIndex.value = undefined;
    }
  },
  { immediate: true },
);
</script>
