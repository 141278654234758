import { ImageModel } from '@/model/content/Image';
import { FactBoxModel } from '@/model/content/FactBox';
import { AccordionModel } from '@/model/content/Accordion';
import { RichTextModel } from '@/model/content/RichText';
import { VideoModel } from '@/model/content/multimedia/Video';
import { BannerModel } from '@/model/content/Banner';
import { RelatedLinksModel } from '@/model/content/RelatedLinks';
import { TabsBlockModel } from '@/model/content/Tab';
import { QuestionGroupModel } from '@/model/content/Questions';
import { CompetencyAims } from '@/model/CompetencyAim';
import { GlobalAudio } from '@/model/response/Article';
import { SideBySideModel } from '@/model/content/SideBySide';
import { Language } from '@/i18n';

export interface ResponseError {
  message: string;
  status: number;
}

export interface Pagination {
  page: number;
  total: number;
  limit: number;
}

export interface ResponseContent<ContentType> {
  id: string;
  namespace: string;
  schemaId: string;
  content: ContentType;
  localization: ContentType;
  locales: string[];
}

export interface LocalizedContent<ContentType> {
  id: string;
  locale: string;
  service: string;
  content: ContentType;
}

export interface StructureResponse<DataType> {
  structure: StructureData<DataType>;
}

export interface StructureData<DataType> {
  data: DataType;
  error?: ResponseError;
}

export interface PageResponse<ChildType = unknown, LocalizationType = Record<string, string>> extends HasPermissions {
  id: string;
  tag: { data: { tags: { source: string; keyword: string }[] } };
  name: string;
  description: string;
  slug: string;
  // NOTE: Response does not include `slugPath` right now
  slugPath?: string;
  contentType: string;
  cover: string;
  namespace: string;
  contentId: string;
  order: number;
  locales?: LocaleCollection;
  localization: PageLocalization<LocalizationType>;
  countChildren: {
    count: number;
  };
  children: PageResponseChildren<ChildType>;
  parent: string;
  type?: string;
}

export interface PageResponseChildren<T> {
  data: T[];
  pagination: Pagination;
}

export type LocalizedCategory = {
  name: string;
  tag: string;
  groupId: string;
};

export interface PageLocalization<R = Record<string, string>> {
  categories: { list: LocalizedCategory[] };
  data: LocalizedContent<R>;
  error?: ResponseError;
}

export type ChildrenContent =
  | ImageModel
  | TabsBlockModel
  | FactBoxModel
  | AccordionModel
  | RichTextModel
  | VideoModel
  | BannerModel
  | RelatedLinksModel
  | QuestionGroupModel
  | SideBySideModel;

export enum AccessLevel {
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  ANONYMOUS = 'ANONYMOUS',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
}

export interface HasPermissions {
  accessLevel: AccessLevel;
}

export interface ChildContent {
  _id: string;
  _type: string;
}

export interface ContentBase {
  altText: string;
  author: string;
  copyright: string;
}

export interface CardDocument {
  file: string;
  fileType?: string;
  name?: string;
}

export interface CardDocuments {
  documents: CardDocument[];
}

export interface RelatedLink {
  contentId: string;
  contentUrl: string;
  description: string;
  namespaceId: string;
  imageId: string;
  title: string;
  type: string;
}

export interface RelatedLinks {
  heading: string;
  items: RelatedLink[];
}

export interface GlobalAudioPartial {
  globalAudio: GlobalAudio;
  globalAudioTranscription?: string;
}

export interface SharedContent extends ContentBase, GlobalAudioPartial {
  relatedLinks?: RelatedLinks;
  readingTime?: number;
  competencyAims?: CompetencyAims;
}

export interface CategoryTag {
  name: string;
  tag: string;
  groupId: string;
}

export type LocaleCollection = Language[];
