import { ref } from 'vue';
import { cleanText } from '@/utils/cleanText';
import { useAuth, useCognitive } from '@/core';
import useAudioOptions from '@/components/multimedia/audio/audio-player/useAudioOptions';
import { AudioFactory } from '@/types/text-to-speech/Audio.interface';
import { Language } from '@/i18n';

const { fetchAuthorizationToken, fetchVoiceList } = useCognitive;
window.SpeechSDK?.Recognizer?.enableTelemetry(false);

const { audioOptionsDefault } = useAudioOptions();
const { user } = useAuth();

type Synthesis = (textToPlay: string, locale: string) => AudioFactory;

export const languageToLocale = (language: string): string => {
  switch (language) {
    case Language.NORWEGIAN_BOOKMAL:
    case Language.NORWEGIAN_NYNORSK:
      return 'nb-NO';
    case Language.ENGLISH:
      return 'en-GB';
    default:
      return `${language}-${language.toUpperCase()}`;
  }
};

const setup = (textToPlay: string, locale: string): AudioFactory => {
  const state = {
    token: null,
    voice: null,
    player: null,
    region: null,
    speedRate: ref<number>(audioOptionsDefault.speedRate),
  };

  // Azure API requires token to perform action, anonymous users can not use it
  if (!user.value?._id) {
    return null;
  }

  return async () => {
    if (!window.SpeechSDK) {
      throw new Error('SpeechSDK not available');
    }

    await fetchAuthorizationToken((token, region) => {
      state.token = token;
      state.region = region;
    });

    await fetchVoiceList(languageToLocale(locale), (voice) => (state.voice = voice));

    const speech = window.SpeechSDK.SpeechConfig.fromAuthorizationToken(state.token, state.region);
    speech.speechSynthesisVoiceName = state.voice;
    speech.speechSynthesisOutputFormat = window.SpeechSDK.SpeechSynthesisOutputFormat.Riff16Khz16BitMonoPcm;

    const synthesizer = new window.SpeechSDK.SpeechSynthesizer(speech, null);
    const strippedText = cleanText(textToPlay).slice(0, 7500); // TODO: Remove this after synthesizing large text chunks are resolved

    return new Promise((resolve, reject) => {
      synthesizer.speakTextAsync(
        strippedText,
        (result) => {
          if (result.errorDetails) {
            synthesizer.close();

            return reject(result.errorDetails);
          }

          const blob = new Blob([result.audioData], { type: 'audio/wav' });
          const audio = new Audio();
          audio.src = URL.createObjectURL(blob);

          synthesizer.close();

          resolve(audio);
        },
        (error) => {
          synthesizer.close();

          reject(error);
        },
      );
    });
  };
};

export default (): { setup: Synthesis } => ({
  setup,
});
