import { PageName } from '@/constants/PageName';
import { GoBackHandler } from '@/services/useCustomRouter/model';
import { generateLinkHref } from '@/utils/generateLinkHref';
import { ContentTypeRouteMap } from '@/config/ContentType';
import { getCurrentRoute, matchRouteByName } from '@/services/useCustomRouter/handlers/historyTools';

export const isLearningPathHandler: GoBackHandler = {
  isSupported: (context) => {
    const to = getCurrentRoute(context);
    return matchRouteByName(to, PageName.LEARNING);
  },
  handle: async (context) => {
    let index = -1;

    while (context.routerState.at(index)?.path.includes(`/${ContentTypeRouteMap['LEARNING-PATH']}`)) {
      index -= 1;
    }

    const lastHistoryItem = context.routerState.at(index);

    if (
      lastHistoryItem?.path?.includes(`/${ContentTypeRouteMap['CATEGORY-PAGE']}`) ||
      lastHistoryItem?.path?.includes(`/${ContentTypeRouteMap['TEACHER-CATEGORY']}`)
    ) {
      context.router.go(index + 1); // last position in routeState is current page, we are not counting it
    } else {
      const parentPath = ([...getCurrentRoute(context).params.pathMatch] as string[]).slice(0, -1);
      await context.router.push(`/${generateLinkHref(PageName.CATEGORY, parentPath.join('/'))}`);
    }
  },
  order: 300,
  name: 'isLearningPathHandler',
};
