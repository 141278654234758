import { PlayerState } from '@/types/text-to-speech/PlayerState.enum';
import { Ref, ref, shallowRef, ShallowRef, watch } from 'vue';
import useAudioOptions from '@/components/multimedia/audio/audio-player/useAudioOptions';
import { AudioControl, AudioFactory, updateSpeedRateCallback } from '@/types/text-to-speech/Audio.interface';

interface useAudioControlInterface {
  setup: (audioFactory: AudioFactory) => AudioControl;
  updateSpeedRate: updateSpeedRateCallback;
}

const { audioOptionsDefault, audioOptions, setAudioOption } = useAudioOptions();

const speedRate = ref<number>(audioOptions.speedRate);

const setup = (audioFactory: AudioFactory): AudioControl => {
  const duration = ref<number>(0);
  const currentTime = ref<number>(0);
  const playerState = ref<PlayerState>(PlayerState.NOT_INITIALIZED);
  let audio = new Audio();
  const audioLoaded = ref<boolean>(false);
  const currentAudioFactory: ShallowRef<AudioFactory> = shallowRef(audioFactory);

  const loadAudio = async (): Promise<void> => {
    playerState.value = PlayerState.LOADING;
    const audioResponse = await currentAudioFactory.value();
    audio.src = audioResponse.src;

    audio.playbackRate = speedRate.value;

    audio.addEventListener('loadedmetadata', onCanPlay);
    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('ended', updateState);

    audioLoaded.value = true;
  };

  const playAudio = async () => {
    try {
      await audio.play();
      playerState.value = PlayerState.PLAYING;
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.error(error);
        updateState();
      }
    }
  };

  const onCanPlay = () => {
    updateState();
    updateDuration();
  };

  const updateDuration = () => {
    duration.value = audio.duration;
  };

  const updateCurrentTime = () => {
    currentTime.value = audio.currentTime;
  };

  const updateState = () => {
    playerState.value = PlayerState.PAUSED;
  };

  const userPermissionBypass = async () => {
    audio.src = `data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV`;
    await playAudio();
  };

  const play = async () => {
    if (!audioLoaded.value) {
      await userPermissionBypass();
      await loadAudio();
    }

    await playAudio();
  };

  const pause = () => {
    if (playerState.value === PlayerState.LOADING) {
      watch(playerState, () => {
        pauseAudio();
      });
    } else {
      pauseAudio();
    }
  };

  const pauseAudio = () => {
    updateState();

    if (audio) {
      audio.pause();
    }
  };

  const resume = async () => {
    playerState.value = PlayerState.PLAYING;

    if (audio) {
      await playAudio();
    }
  };

  const reloadAudio = (audioFactory: AudioFactory) => {
    reset();
    currentAudioFactory.value = audioFactory;
  };

  const reset = () => {
    if (audio) {
      audio.removeEventListener('canplay', updateDuration);
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('ended', updateState);

      audio = null;
    }

    duration.value = 0;
    currentTime.value = 0;
    updateState();
    speedRate.value = audioOptionsDefault.speedRate;
  };

  const changeTime = async (newTime: number): Promise<void> => {
    if (playerState.value === PlayerState.NOT_INITIALIZED || playerState.value === PlayerState.PAUSED) {
      await play();
    }
    audio.currentTime = newTime;
  };

  watch(speedRate, () => (audio.playbackRate = speedRate.value), {
    immediate: true,
  });

  return {
    play,
    pause,
    resume,
    duration,
    currentTime,
    playerState,
    speedRate,
    reloadAudio,
    changeTime,
  };
};

const updateSpeedRate: updateSpeedRateCallback = (speedRate: Ref<number>) => (event) => {
  speedRate.value = event.target.value;
  setAudioOption('speedRate', speedRate.value.toString());
};

export default (): useAudioControlInterface => ({
  setup,
  updateSpeedRate,
});
