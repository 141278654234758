import { CONTENT_FRAGMENT, ERROR_FRAGMENT, gql } from './fragments.gql';

export const query = gql`
  ${ERROR_FRAGMENT}
  ${CONTENT_FRAGMENT}
  query queryStructureWithLocale(
    $id: String!
    $locale: String!
    $limit: Int!
    $page: Int!
    $includeChildren: Boolean!
  ) {
    structure(id: $id) {
      data {
        id
        accessLevel
        name
        description
        slug
        slugPath
        contentType
        cover
        parent
        type
        content {
          data {
            ...ContentFragment
            localization(locale: $locale)
          }
        }
        locales
        localization(locale: $locale) {
          data {
            locale
            content
            service
            id
          }
          error {
            ...ErrorFragment
          }
        }
        countChildren {
          count
        }
        tag {
          data {
            id
            tags {
              source
              keyword
            }
          }
          error {
            ...ErrorFragment
          }
        }
        children(limit: $limit, page: $page) @include(if: $includeChildren) {
          data {
            id
            accessLevel
            namespace
            name
            order
            description
            cover
            parent
            type
            slug
            contentType
            contentId
            content {
              data {
                ...ContentFragment
                localization(locale: $locale)
              }
              error {
                ...ErrorFragment
              }
            }
            localization(locale: $locale) {
              data {
                locale
                content
                service
                id
              }
            }
            tag {
              data {
                id
                tags {
                  source
                  keyword
                }
              }
            }
          }
          pagination {
            page
            total
            limit
          }
        }
      }
      error {
        ...ErrorFragment
      }
    }
  }
`;
