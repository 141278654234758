import { markRaw, reactive, ref } from 'vue';
import ConceptModal from '@/components/modal/ConceptModal.vue';
import useModal from '@/services/useModal';
import { ModalType } from '@/types/modal/ModalType.enum';
import { CardInterface } from '@/model/Card';
import useLocalization from '@/services/useLocalization';
import { Content, GenericContent } from '@/model/page/Content';
import { ArticleModel } from '@/model/page/Article';
import { TabInterface, TabsBlockModel } from '@/model/content/Tab';
import useContent from '@/services/useContent';
import { LocaleCollection } from '@/model/response';
import { Language } from '@/i18n';
import useLocalLanguage from '@/services/useLocalLanguage';
import useNamespace from './useNamespace';

interface ConceptState {
  id: string;
  title: string;
  contents: Content[];
  tabs: TabInterface[];
  activeTab: number;
  loading: boolean;
  locales?: LocaleCollection;
}

interface UseConcept {
  open: (card: CardInterface, events?: Record<string, () => void>) => void;
  state: ConceptState;
  switchTab: (index: number) => void;
  changeLanguage: (locale: string) => void;
}

const filteredOverlappedEntries = (array1: unknown[], array2: unknown[]) =>
  array1.filter((value) => array2.includes(value));
const isObjectEmpty = (object: object) => Object.keys(object).length === 0 && object.constructor === Object;

const modal = useModal();

const initialState = (): ConceptState => ({
  id: '',
  title: '',
  contents: [],
  tabs: [],
  activeTab: 0,
  loading: true,
  locales: [],
});

const state = reactive<ConceptState>(initialState());
const resetState = () => Object.assign(state, initialState());

export default (): UseConcept => {
  const { locale } = useLocalization();
  const { activeLocale } = useLocalLanguage();
  const { resolveContent } = useContent();
  const cardState = ref<CardInterface>(null);

  const fetchConcept = (id: string, locale: string) => {
    state.loading = true;

    resolveContent(id, locale).then((response) => {
      mapContentToCardState(response);
      state.loading = false;
    });
  };

  const open = async (card: CardInterface, events = {}) => {
    resetState();

    cardState.value = card;

    modal.open(markRaw(ConceptModal), { card }, ModalType.DEFAULT, events);

    fetchConcept(cardState.value.id, activeLocale.value || locale.value);
  };

  const changeLanguage = (locale: string) => {
    fetchConcept(state.id, locale);
  };

  const assignNamespaceId = (contents: Content[]) => {
    const namespaceId = cardState.value?.namespaceId;
    if (namespaceId) {
      contents.map((item) => Object.assign(item.content, { namespaceId }));
    }
  };

  const mapContentToCardState = (content: GenericContent) => {
    const currentContent = content as ArticleModel;
    const contents = currentContent?.children;

    const tabsBlock = contents?.filter((content) => content.contentType === 'tabs-block')?.[0];
    if (tabsBlock) {
      contents.splice(contents.indexOf(tabsBlock), 1);
    }

    state.id = currentContent?.id;
    state.title = currentContent?.title;

    const contentLocales = [Language.NORWEGIAN_BOOKMAL, ...(currentContent?.locales ?? [])];

    const allLocales = [
      filteredOverlappedEntries(useNamespace().namespace.languages, contentLocales),
      contentLocales,
    ].flat() as Language[];

    state.locales = Array.from(new Set(allLocales));

    if (tabsBlock) {
      if (isObjectEmpty(tabsBlock.content)) {
        console.warn(`No content in block ${tabsBlock}`);
        return;
      }

      state.tabs = (tabsBlock.content as TabsBlockModel).tabsBlock.map((tab) => {
        const title = tab.title;
        const contents = tab.items.map((item) => {
          const { _type: contentType, _id: id, ...content } = item;
          return { content, contentType, id } as Content;
        });
        return {
          title,
          contents,
        };
      });
      state.tabs.map((tab) => assignNamespaceId(tab.contents));
      switchTab(state.activeTab);
    } else {
      assignNamespaceId(contents);
      state.contents = contents;
    }
  };

  const switchTab = (index: number) => {
    state.activeTab = index;
    switchContent();
  };

  const switchContent = () => {
    state.contents = state?.tabs[state.activeTab].contents;
  };

  return {
    open,
    state,
    switchTab,
    changeLanguage,
  };
};
