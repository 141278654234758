import { TextToSpeechContext, TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';
import { SideBySideItemModel, SideBySideModel } from '@/model/content';
import { concatContent } from '@/services/useContent/helper/concatContent';

class SideBySideMapper implements TextToSpeechMapper {
  mapContent(content: SideBySideModel, context: TextToSpeechContext): string {
    return content?.columns
      ? concatContent(content.columns?.flatMap((column) => mapSideBySideColumn(column, context)))
      : '';
  }
}

const mapSideBySideColumn = (column: SideBySideItemModel, context: TextToSpeechContext) => [
  column?.title,
  ...(column?.items ? column.items.map((item) => context.mapContentToText(item._type, item)) : []),
];

export const sideBySideMapper = new SideBySideMapper();
