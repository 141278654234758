<template>
  <form class="c-speed-control" :class="{ 'c-speed-control--dark': dark }" @click.stop>
    <div class="c-speed-control__labels">
      <span class="c-speed-control__label">{{ $t('AUDIO_PLAYER.SPEED.SLOWLY') }}</span>
      <span class="c-speed-control__label">{{ $t('AUDIO_PLAYER.SPEED.QUICKLY') }}</span>
    </div>
    <label for="range">
      <input
        id="range"
        ref="range"
        type="range"
        min="0.5"
        max="1.5"
        step="0.01"
        :value="speedRate"
        @input="updateSpeedRate"
      />
    </label>
  </form>
</template>

<script lang="ts" setup>
import { inject, ref, Ref, watch } from 'vue';
import useAudioControl from '@/components/multimedia/audio/audio-player/useAudioControl';

interface SpeedControlProps {
  dark?: boolean;
}

defineProps<SpeedControlProps>();

const range = ref<HTMLInputElement>(null);
const speedRate = inject<Ref<number>>('speedRate');
const audioControl = useAudioControl();
const updateSpeedRate = audioControl.updateSpeedRate(speedRate);
const isPopoverVisible = inject<Ref<boolean>>('isPopoverVisible');

watch(isPopoverVisible, () => {
  if (isPopoverVisible.value) {
    setTimeout(() => range.value.focus(), 50);
  }
});
</script>
