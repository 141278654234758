<template>
  <div>
    <label
      v-for="languageOption in languages"
      :key="languageOption.code"
      data-test="language-switch-button"
      :class="['c-language', { 'c-language--label-on-right': labelOnRight }]"
      tabindex="0"
      @keydown.enter.space="onSelect(languageOption.code)"
      @click="onSelect(languageOption.code)"
    >
      <input
        v-model="selectedLanguage"
        data-test="language-switch-input"
        class="c-language__radio"
        type="radio"
        :value="languageOption.code"
      />
      <span class="c-language__title">{{ $t(languageOption.title) }}</span>
      <span class="c-language__circle"></span>
    </label>
  </div>
</template>
<script lang="ts" setup>
import { Language } from '@/types/shared/Language.interface';
import { computed } from 'vue';

interface LanguageSwitchProps {
  labelOnRight?: boolean;
  languages: Language[];
  language: string;
}

const props = defineProps<LanguageSwitchProps>();

const emit = defineEmits(['setLanguage']);

const onSelect = (language) => {
  emit('setLanguage', language);
};

const selectedLanguage = computed(() => props.language);
</script>
