import { useAuth } from '@/core';
import { AccessLevel, HasPermissions } from '@/model/response';
import { computed, ref, Ref, watch } from 'vue';

const { token, instance, userRole, highestUserRole, rolesHierarchy } = useAuth();
export type UsePermissions = {
  isAuthenticated: Ref<boolean>;
  checkPermissions: (object: HasPermissions) => boolean;
  isStudent: () => boolean;
  isTeacher: () => boolean;
  isAnonymous: () => boolean;
};

const managerRoles = rolesHierarchy.slice(0, 7);
const hasContentManagerRole = ref(false);

const isStudent = () => userRole.value === 'student';
const isTeacher = () => userRole.value === 'teacher';
const isContentManager = () => hasContentManagerRole.value;

const checkPermissions = (object: HasPermissions): boolean => {
  switch (object?.accessLevel?.toUpperCase()) {
    case AccessLevel.ANONYMOUS:
      return true;
    case AccessLevel.STUDENT:
      return isTeacher() || isStudent();
    case AccessLevel.TEACHER:
      return isTeacher();
    case AccessLevel.CONTENT_MANAGER:
      return isContentManager();
    default:
      return false;
  }
};

const setup = (): UsePermissions => {
  const isAuthenticated = computed<boolean>(() => instance().isAuthenticated() || !!token.value);
  const isAnonymous = () => !isAuthenticated.value;

  watch(token, () => {
    hasContentManagerRole.value = managerRoles.some((role) => role === highestUserRole.value);
  });

  return {
    checkPermissions,
    isAuthenticated,
    isAnonymous,
    isStudent,
    isTeacher,
  };
};

export default setup;
