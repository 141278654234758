import {
  CardDocuments,
  ChildrenContent,
  HasPermissions,
  PageLocalization,
  PageResponse,
  RelatedLinks,
  ResponseContent,
  ResponseError,
  SharedContent,
} from '@/model/response/Shared';
import { ImageModel } from '@/model/content/Image';

export interface Tag {
  source: string;
  keyword: string;
}

export enum GlobalAudioType {
  NONE = 'none',
  FILE = 'file',
  TEXT_TO_SPEECH = 'text-to-speech',
}

export interface GlobalAudio {
  type: GlobalAudioType;
  value: string;
}

export interface ArticleContent extends SharedContent {
  relatedLinks?: RelatedLinks;
  bannerImage: ImageModel;
  useAnchorScrollNav: boolean;
  linkToContentBlock?: {
    items: LinkToContentBlock[];
  };
  cardDocuments?: CardDocuments;
  linkToRelatedTest?: {
    items: LinkToRelatedTest[];
  };
}

export interface LinkToContentBlock {
  contentId: string;
  contentUrl: string;
  namespaceId: string;
  title: string;
  type: string;
}

export interface LinkToRelatedTest {
  contentId: string;
  contentUrl: string;
  namespaceId: string;
  title: string;
  type: string;
}

export interface ArticleResponseTag {
  data: {
    id: string;
    tags: Tag[];
  };
  error?: ResponseError;
}

export interface ArticleResponseChild extends HasPermissions {
  id: string;
  namespace: string;
  name: string;
  order: number;
  description: string;
  cover: string;
  parent: string;
  type: string;
  slug: string;
  contentType: string;
  contentId: string;
  content: {
    data: ResponseContent<ChildrenContent>;
    error: ResponseError;
  };
  localization: PageLocalization<ChildrenContent>;
}

export interface ArticleResponse extends PageResponse<ArticleResponseChild>, HasPermissions {
  content: {
    data: ResponseContent<ArticleContent>;
  };
  tag: ArticleResponseTag;
}

export enum ChildrenTypeEnum {
  COURSE = 'COURSE',
  UNIT = 'UNIT',
  LESSON = 'LESSON',
  DIRECTORY = 'DIRECTORY',
  OBJECT = 'OBJECT',
  COMPOUND = 'COMPOUND',
}

export interface QueryVariables {
  locale?: string;
  limit?: number;
  page?: number;
  includeChildren?: boolean;
}
