import { ref, watch } from 'vue';

const MOVE_OFFSET_VAR = '--currentOffset';

export default (element: HTMLElement) => {
  if (!element) return;

  const targetVisibleWidth = ref(0);
  const targetTotalWidth = ref(0);
  const notVisibleNodes = ref(0);
  let childNodeWidth;
  const maxState = ref(0);
  const minState = ref(0);
  const currentState = ref(0);
  const currentActive = ref(0);

  const changeState = () => {
    let moveOffset = currentState.value * childNodeWidth - targetVisibleWidth.value / 2;
    const hiddenPart = targetTotalWidth.value - targetVisibleWidth.value;

    if (currentState.value >= maxState.value) {
      moveOffset = hiddenPart;
    }

    if (currentState.value <= minState.value) {
      moveOffset = 0;
    }

    element.style.setProperty(MOVE_OFFSET_VAR, `${-moveOffset}px`);
  };

  const setActive = (newValue: number) => (currentActive.value = newValue);

  const initVars = () => {
    targetVisibleWidth.value = element.offsetWidth;
    targetTotalWidth.value = element.scrollWidth;

    childNodeWidth = (element.children[0] as HTMLElement)?.offsetWidth;

    notVisibleNodes.value = Math.ceil((element.scrollWidth - element.offsetWidth) / childNodeWidth);
    const visibleNodes = Math.floor(element.children.length - notVisibleNodes.value);

    maxState.value = element.children.length - visibleNodes / 2;
    minState.value = visibleNodes / 2;
  };

  const init = () => {
    element.style.setProperty('transform', `translateX(var(${MOVE_OFFSET_VAR}))`);
    initVars();
    toRight(currentActive.value || minState.value);
  };

  watch([currentState, currentActive], changeState);

  window.addEventListener('resize', () => {
    initVars();
    toRight(currentActive.value);
    changeState();
  });

  const toRight = (index?: number) => {
    const nextValue = index ? (index < minState.value ? minState.value : index) : currentState.value + 1;

    currentState.value = nextValue > maxState.value ? maxState.value : nextValue;
  };

  const toLeft = () => {
    const nextValue = currentState.value - 1;
    currentState.value = nextValue < minState.value ? minState.value : nextValue;
  };

  return {
    init,
    currentState,
    notVisibleNodes,
    maxState,
    minState,
    toRight,
    toLeft,
    setActive,
  };
};
