<template>
  <div class="c-progress-bar">
    <div class="c-progress-bar__amount">
      <div class="c-progress-bar__amount--current" :style="{ width: percentageFormatted }" />
      <label for="progress" class="c-progress-bar__input-wrapper">
        <input
          class="c-progress-bar__input"
          type="range"
          :value="percentage"
          :disabled="disabled"
          @input="updateProgress"
        />
      </label>
    </div>

    <div class="c-progress-bar__label">{{ duration }}/{{ totalFormatted }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { secondsToString } from '@/utils/date';

interface ProgressBarProps {
  total: number;
  current: number;
  disabled?: boolean;
}

const props = defineProps<ProgressBarProps>();
const emit = defineEmits(['updateProgress']);

const duration = ref<string>();
const percentage = ref<number>();
const update = () => {
  const percentValue = (props.current / props.total) * 100;

  percentage.value = isNaN(percentValue) ? 0 : percentValue;
  duration.value = secondsToString(props.current);
};

update();
watch([() => props.current, () => props.total], update);

const updateProgress = (progress) => {
  emit('updateProgress', (progress.target.value / 100) * props.total);
};

const totalFormatted = computed(() => secondsToString(props.total));
const percentageFormatted = computed(() =>
  percentage.value < 50 ? `${percentage.value + 2}%` : `${percentage.value}%`,
);
</script>
