<template>
  <ModalLayout class="c-concept-modal" v-bind="modalSize">
    <template #header>{{ state.title }}</template>
    <template #controls>
      <TabsBlock v-if="state.tabs.length" v-model="activeIndex" :tabs="state.tabs" />
      <LocalLanguageSelect
        v-if="concept.state.locales?.length > 1"
        :locales="concept.state.locales"
        class="c-concept-modal__language-select"
        @change="handleLanguageChange"
      />
    </template>
    <template #body>
      <div v-if="state.loading" class="c-concept-modal__loader">
        <Loader />
      </div>
      <template v-else>
        <ArticleComponents v-if="state.contents" class="c-concept-modal__contents" :contents="state.contents" />
      </template>
    </template>
  </ModalLayout>
</template>

<script lang="ts" setup>
import ArticleComponents from '@/components/content-blocks/AsyncContentBlocks.vue';
import TabsBlock from '@/components/tabs-block/TabsBlock.vue';
import LocalLanguageSelect from '@/components/language-select/LocalLanguageSelect.vue';
import Loader from '@/elements/loader/BaseLoader.vue';
import ModalLayout from '@/layout/modal/ModalLayout.vue';
import useConcept from '@/services/useConcept';
import useModal, { ModalSizeObject } from '@/services/useModal';
import { computed } from 'vue';

const concept = useConcept();
const { getModalSizeFromContentLength, registerModalHeightChange } = useModal();

const state = computed(() => concept.state);

const activeIndex = computed({
  get: () => state.value.activeTab,
  set: (index) => concept.switchTab(index),
});

const handleLanguageChange = (locale: string) => {
  concept.changeLanguage(locale);
};

const contents = computed(() => {
  const tabs = concept.state?.tabs?.map((tab) => tab.contents);
  return tabs.length ? tabs : [concept.state.contents];
});

registerModalHeightChange(contents);
const modalSize = computed<ModalSizeObject>(() => getModalSizeFromContentLength(contents.value));
</script>
