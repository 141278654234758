import { fetchWithAuth } from './api'

const fetchAuthorizationToken = (callback) => {
  return fetchWithAuth('cognitive/token')
    .then((res) => res.json())
    .then((res) => {
      const token = res.token
      const parsedToken = JSON.parse(atob(token.split('.')[1]))

      return callback(token, parsedToken.region)
    })
}

const fetchVoiceList = (locale, callback) => {
  return fetchWithAuth(`cognitive/speech/voices/${locale}`)
    .then((res) => res.json())
    .then((res) => callback(res.name))
}

export default { fetchAuthorizationToken, fetchVoiceList }
