<template>
  <span :class="['e-badge', `e-badge--${variant}`, `e-badge--size-${size}`]" :disabled="disabled">
    <slot />
  </span>
</template>

<script lang="ts">
export const Sizes = {
  X_SMALL: 'x-small',
  SMALL: 'small',
  DEFAULT: 'default',
  LARGE: 'large',
  X_LARGE: 'x-large',
} as const;

export const Variants = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;
</script>

<script lang="ts" setup>
interface BaseBadgeProps {
  disabled?: boolean;
  variant?: (typeof Variants)[keyof typeof Variants];
  size?: (typeof Sizes)[keyof typeof Sizes];
}

withDefaults(defineProps<BaseBadgeProps>(), {
  variant: Variants.LIGHT,
  size: Sizes.DEFAULT,
});
</script>
