import { CategoryResponse, NavTreeStructureType } from '@/model/response';
import { CategoryModel, TreeFilterType } from '@/model/page/Category';
import { GenericContent } from '@/model/page/Content';
import { applyTranslations } from '@/mapper/utils/applyTranslations';
import { mapPage } from '@/mapper/Page';
import { mapContent } from '@/mapper/Content';

const validateCategory = (response: CategoryResponse) => !response?.content || !!response?.content?.data;

export const mapFilterType = (erudioType?: NavTreeStructureType): TreeFilterType =>
  erudioType === 'single-select' ? 'single' : 'multi';

export const mapCategory = (pageResponse: CategoryResponse): CategoryModel | GenericContent => {
  const { contentType, slug, slugPath, title } = mapPage(pageResponse);

  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.content?.data?.content, response.content?.data?.localization);

  if (!validateCategory(response)) {
    return {
      id: '500',
      contentType: 'error',
    };
  }

  return {
    // TODO: should use id from mapPage
    id: response?.content?.data?.id,
    contentType,
    slug,
    slugPath,
    title,
    cover: response?.cover,
    // TODO: should be replaced with namespaceId, from mapPage
    namespace: response?.namespace,
    // TODO: not needed, can be removed
    order: response?.order,
    // TODO: not needed, can be removed
    contentId: response?.contentId,
    articles: response?.children?.data?.map(mapContent),
    levels: content?.levels,
    filterType: mapFilterType(content?.navTreeStructure?.value),
    filters: content?.predefinedFilters?.isEnabled ? content?.predefinedFilters?.filters?.list : undefined,
    categories: content?.predefinedFilters?.isEnabled ? content?.predefinedFilters?.categories?.list : undefined,
    mainCategoryTag: content?.mainCategory?.value?.tag,
    parentCategoryId: content?.parent?.id,
  };
};
