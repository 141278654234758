<template>
  <Tooltip v-model="isTooltipOpen" click keydown placement="bottom-end" class="e-user-profile__tooltip">
    <template #button>
      <div class="e-user-profile" data-test="standard-header-user">
        <Tooltip
          v-if="isLoggedIn"
          v-model="isSmallTooltipOpen"
          hover
          placement="bottom"
          :disabled="isTooltipOpen"
          class="e-user-profile__user-name-tooltip"
        >
          <template #button>
            <div class="e-user-profile__avatar">
              <span class="e-user-profile__initials">{{ initials }}</span>
            </div>
          </template>
          <template #content>
            <div class="e-user-profile__user-name-tooltip-text">{{ userName }}</div>
          </template>
        </Tooltip>
        <div class="e-user-profile__name-container">
          <SettingsIcon class="e-user-profile__settings-icon" />
          <span class="e-user-profile__name">{{ $t('HEADER_BAR.TITLE') }}</span>

          <span class="e-user-profile__icon"><DropdownArrowIcon /></span>
        </div>
      </div>
    </template>
    <template #content>
      <span v-if="isLoggedIn" class="e-user-profile-tooltip__user-info">{{ userName }}</span>
      <h3 class="e-user-profile-tooltip__title">
        {{ $t('HEADER_BAR.MAIN_LANGUAGE') }}
      </h3>
      <div class="e-user-profile-tooltip__subtitle">{{ $t('HEADER_BAR.MAIN_LANGUAGE_SUBTEXT') }}</div>
      <LanguageSwitch
        :language="localeUi"
        :languages="languages"
        class="e-user-profile-tooltip__language-switch"
        @set-language="setLocaleUi"
      />
      <h3 v-if="showMotherTongue" class="e-user-profile-tooltip__title">
        {{ $t('HEADER_BAR.MOTHER_TONGUE') }}
      </h3>
      <div v-if="showMotherTongue" class="e-user-profile-tooltip__subtitle">
        {{ $t('HEADER_BAR.MOTHER_TONGUE_SUBTEXT') }}
      </div>
      <LanguageSelect v-if="showMotherTongue" class="e-user-profile-tooltip__language-select" />
      <BaseButton
        v-if="isLoggedIn"
        fluid
        outline
        class="e-user-profile-tooltip__button e-user-profile-tooltip__button--logout"
        data-test="standard-header-logout"
        @keydown.enter="onLogoutButtonClick"
        @click="onLogoutButtonClick"
      >
        {{ $t('LOGOUT.TEXT') }}
      </BaseButton>
      <BaseButton
        v-else
        fluid
        outline
        class="e-user-profile-tooltip__button e-user-profile-tooltip__button--logout"
        @keydown.enter="onLoginButtonClick"
        @click="onLoginButtonClick"
      >
        {{ $t('LOGIN.TEXT') }}
      </BaseButton>
      <BaseButton
        fluid
        class="e-user-profile-tooltip__button e-user-profile-tooltip__button--close"
        @keydown.enter="closeTooltip"
        @click="closeTooltip"
      >
        <CloseIcon class="e-user-profile-tooltip__button-icon" />
        {{ $t('CONTROLS.CLOSE') }}
      </BaseButton>
    </template>
  </Tooltip>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import DropdownArrowIcon from '@/assets/icons/DropdownArrowIcon.vue';
import { useAuth } from '@/core';
import Tooltip from '@/elements/tooltip/BaseTooltip.vue';
import LanguageSwitch from '@/components/language-switch/LanguageSwitch.vue';
import SettingsIcon from '@/assets/icons/SettingsIcon.vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import useLocalization from '@/services/useLocalization';
import auth from '@/core/auth';
import useNamespace from '@/services/useNamespace';
import { PageName } from '@/constants/PageName';
import { useRouter } from 'vue-router';
import { USER_MODE_KEY } from '@/services/useUserMode';
import LanguageSelect from '@/components/language-select/HeaderLanguageSelect.vue';
import { Language } from '@/i18n';
import CloseIcon from '@/assets/icons/CloseIcon.vue';
import { getInitials, prepareDisplayName } from '@/utils/user';

const router = useRouter();
const { user, logout } = useAuth();
const isTooltipOpen = ref(false);
const isSmallTooltipOpen = ref(false);
const isLoggedIn = computed(() => !!user.value);
const userName = computed(() => prepareDisplayName(user.value));
const initials = computed(() => getInitials(userName.value));
const { localeUi, setLocaleUi, availableLocale } = useLocalization();
const { namespace } = useNamespace();

const isBaseLanguage = (language: Language) => Object.values(Language).includes(language);

const languages = computed(() =>
  namespace.languages.filter(isBaseLanguage).map((code) => ({
    title: `LANGUAGE.${code}`,
    code,
  })),
);

const closeTooltip = () => (isTooltipOpen.value = false);

const onLogoutButtonClick = () => {
  const logoutRedirectURL = namespace.settings.logoutURL;
  localStorage.removeItem(USER_MODE_KEY);
  if (logoutRedirectURL) {
    const addDev = () => (process.env.NODE_ENV === 'development' ? '.dev' : '');
    const logoutURL = `https://auth-proxy${addDev()}.edtech.fagbokforlaget.no/auth/eportaloidc/edtech/logout?redirect_url=${encodeURIComponent(
      logoutRedirectURL,
    )}`;
    auth.logout(`${logoutURL}&token=${encodeURIComponent(auth.token)}`);
  } else {
    logout();
  }
};

const onLoginButtonClick = () => {
  router.push({ name: PageName.LOGIN });
};

const showMotherTongue = computed(() => availableLocale.value.length > 0);

watch([isTooltipOpen, isSmallTooltipOpen], ([isTooltipOpen]) => {
  if (isTooltipOpen) {
    isSmallTooltipOpen.value = false;
  }
});
</script>
