<template>
  <div class="e-level-rating">
    <div v-if="showLabel" class="e-level-rating__label">
      {{ $t('LEVEL_RATING.LABEL') }}
    </div>
    <div class="e-level-rating__items">
      <template v-if="isAutoLevel">
        <component
          :is="levelNumber >= index ? StarFull : StarIcon"
          v-for="index in maxLevel"
          :key="index"
          :class="{ full: levelNumber >= index }"
        />
      </template>
      <div v-else class="e-level-rating__item">
        {{ level }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import StarIcon from '@/assets/icons/StarIcon.vue';
import StarFull from '@/assets/icons/StarFull.vue';

interface LevelRatingProps {
  level?: string;
  maxLevel?: number;
  showLabel?: boolean;
}

const props = withDefaults(defineProps<LevelRatingProps>(), {
  maxLevel: 3,
  showLabel: true,
});

const RATING_CHAR = '★';
const ratingCharacterRegex = new RegExp(`${RATING_CHAR}`, 'g');

const extractLevelNumber = (levelValue: string): number => levelValue?.match(ratingCharacterRegex)?.length;

const isAutoLevel = computed<boolean>(() => props.level && Boolean(props.level.match(ratingCharacterRegex)?.length));
const levelNumber = computed(() => extractLevelNumber(props.level));
</script>
