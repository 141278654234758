import { TextToSpeechContext, TextToSpeechMapper } from '@/services/useContent/textToSpeechMapper';
import { ImageModel } from '@/model/content';
import { concatContent } from '@/services/useContent/helper/concatContent';

class ImageMapper implements TextToSpeechMapper {
  mapContent(content: ImageModel, context: TextToSpeechContext): string {
    return concatContent([context.translate('CONTENT_TYPE.IMAGE'), content?.altText, content?.caption]);
  }
}

export const imageMapper = new ImageMapper();
