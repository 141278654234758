type GtmEventHandler = (eventType: GtmEvent, eventData?: string) => void;

interface UseGTM {
  pushGtmEvent: GtmEventHandler;
}

export enum GtmEvent {
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  LEVEL_START = 'level_start',
  LEVEL_END = 'level_end',
  TUTORIAL_BEGIN = 'tutorial_begin',
  TUTORIAL_COMPLETE = 'tutorial_complete',
  AUDIO_PLAY = 'audio_play',
}

window.dataLayer = window.dataLayer || [];

const onSearch = (eventType: GtmEvent, eventData?: string) =>
  window.dataLayer.push({
    event: eventType,
    search_term: eventData,
  });

const onSelectContent = (eventType: GtmEvent, eventData?: string) =>
  window.dataLayer.push({
    event: eventType,
    content_type: 'modal',
    content_id: eventData,
  });

const onLevelStart = (eventType: GtmEvent, eventData?: string) =>
  window.dataLayer.push({
    event: eventType,
    level_name: eventData,
  });

const onLevelEnd = (eventType: GtmEvent, eventData?: string) =>
  window.dataLayer.push({
    event: eventType,
    level_name: eventData,
    success: true,
  });

const onGenericEvent = (eventType: GtmEvent) =>
  window.dataLayer.push({
    event: eventType,
  });

const gtmEventHandlersMap: Map<GtmEvent, GtmEventHandler> = new Map([
  [GtmEvent.SEARCH, onSearch],
  [GtmEvent.SELECT_CONTENT, onSelectContent],
  [GtmEvent.LEVEL_START, onLevelStart],
  [GtmEvent.LEVEL_END, onLevelEnd],
  [GtmEvent.TUTORIAL_BEGIN, onGenericEvent],
  [GtmEvent.TUTORIAL_COMPLETE, onGenericEvent],
  [GtmEvent.AUDIO_PLAY, onGenericEvent],
]);

const pushGtmEvent = (eventType: GtmEvent, eventData: string) => {
  const eventHandler = gtmEventHandlersMap.get(eventType);
  eventHandler(eventType, eventData);
};

export default (): UseGTM => ({
  pushGtmEvent,
});
