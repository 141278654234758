export type ProgressionOverviewItem = [string, number];

export interface ProgressionItemInterface {
  overviewItems?: ProgressionOverviewItem[];
  doneTasks: number;
  totalTasks: number;
}

export interface ProgressionChapterInterface<T = ProgressionItemInterface> {
  name?: string;
  data: T;
}

export enum LearningPathProgress {
  STARTED = 'Started',
  FINISHED = 'Finished',
  NONE = 'None',
}
