import { mapContent } from '@/mapper/Content';
import { ModuleModel } from '@/model/page/Module';
import { CategoryModel } from '@/model/page/Category';
import { PageResponse } from '@/model/response';
import { sortItemsByOrder } from '@/utils/sortItemsByOrder';
import { mapPage } from '@/mapper/Page';

export const mapModule = (response: PageResponse): ModuleModel => {
  const { id, contentType, slug, slugPath, title, description } = mapPage(response);
  return {
    id,
    contentType,
    slug,
    slugPath,
    // TODO: should be changed to image and taken from pageMapper
    cover: response?.cover,
    title,
    description,
    children: response.children?.data
      .sort(sortItemsByOrder)
      .map((item) => mapContent(item as PageResponse)) as CategoryModel[],
  };
};
