<template>
  <BaseButton :fluid="fluid" :class="['c-arrow-button', `c-arrow-button--${variant}`]">
    <span class="c-arrow-button__text">
      <slot />
    </span>
    <component :is="ArrowIcon" :class="['c-arrow-button__icon', `c-arrow-button__icon--${arrowDirection}`]" />
  </BaseButton>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import BoldArrowIcon from '@/assets/icons/BoldArrowIcon.vue';
import ChevronIcon from '@/assets/icons/ChevronIcon.vue';
import { ArrowButton, ArrowButtonDirection, ArrowButtonIconVersion, ArrowButtonVariant } from '@/model/ArrowButton';

const props = withDefaults(defineProps<ArrowButton>(), {
  fluid: true,
  arrowDirection: ArrowButtonDirection.RIGHT,
  iconVersion: ArrowButtonIconVersion.ARROW,
  variant: ArrowButtonVariant.DEFAULT,
});

const ArrowButtonIconVersionMap = {
  [ArrowButtonIconVersion.ARROW]: BoldArrowIcon,
  [ArrowButtonIconVersion.CHEVRON]: ChevronIcon,
};

const ArrowIcon = computed(() => ArrowButtonIconVersionMap[props.iconVersion]);
</script>
