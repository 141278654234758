import { useI18n } from 'vue-i18n';
import useLocalStorage from '@/services/useLocalStorage';
import { inject, reactive, Ref, toRefs, WritableComputedRef } from 'vue';
import { i18n, Language } from '@/i18n';
import useNamespace from '@/services/useNamespace';
import availableLanguages from '@/core/constants/available-languages';

interface UseLocalization {
  locale: Ref<Language>;
  localeUi: Ref<Language>;
}

enum TextDirection {
  RIGHT_TO_LEFT = 'rtl',
  LEFT_TO_RIGHT = 'ltr',
}

const rightToLeftLocales = availableLanguages
  .filter((language) => language.dir === TextDirection.RIGHT_TO_LEFT)
  .map((rtlLanguage) => rtlLanguage.value);

interface UseLocalization {
  locale: Ref<Language>;
  direction?: (locale?: Language) => TextDirection;
  getCurrentLocale: () => Language;
  setLocale: (string) => void;
  setLocaleUi: (string) => void;
  setLangAttr: (string) => void;
  availableLocale: Ref<string[]>;
  availableLocaleUi: Ref<string[]>;
}

const { getCurrentLocale, setCurrentLocale, setUiLocale } = useLocalStorage();

const setup = () => {
  const { namespace } = useNamespace();
  const { availableLocales: useI18nAvailableLocales } = useI18n({ useScope: 'global' });
  const state = reactive({
    locale: inject('locale') as WritableComputedRef<Language>,
    localeUi: inject('localeUi') as WritableComputedRef<Language>,
    availableLocaleUi: namespace.languages.filter((language) => useI18nAvailableLocales.includes(language)),
    availableLocale: namespace.languages.filter((language) => !useI18nAvailableLocales.includes(language)),
  });

  const setLocale = (lang: Language) => {
    state.locale = lang;
    setCurrentLocale(lang);
  };

  const setLocaleUi = (lang: Language) => {
    if (useI18nAvailableLocales.includes(lang)) {
      state.localeUi = lang;
      setLangAttr(lang);
      setUiLocale(lang);
      i18n.global.locale.value = lang;

      // if here are no "Mother Tongue" languages, or selected language is not a "Mother Tongue" (Mother Tongue is not selected)
      if (state.availableLocale.length === 0 || state.availableLocaleUi.includes(state.locale)) {
        setLocale(lang);
      }
    }
  };

  const setLangAttr = (lang: Language) => {
    document.documentElement.setAttribute('lang', lang);
  };

  const direction = (locale: Language = state.locale) =>
    rightToLeftLocales.includes(locale) ? TextDirection.RIGHT_TO_LEFT : TextDirection.LEFT_TO_RIGHT;

  return {
    ...toRefs(state),
    direction,
    getCurrentLocale,
    setLocale,
    setLocaleUi,
    setLangAttr,
  };
};

let instance = undefined;

export default (): UseLocalization => {
  if (instance === undefined) {
    instance = setup();
  }

  return instance;
};
