<template>
  <Transition name="search">
    <div v-if="isOpen" ref="target" class="c-search-dropdown">
      <div v-if="!loading" class="c-search-dropdown__wrapper">
        <div class="c-search-dropdown__cards">
          <template v-for="item in items" :key="item.id">
            <SearchDropdownItem :card="item" tabindex="-1" />
          </template>
        </div>
        <div class="c-search-dropdown__footer">
          <div class="c-search-dropdown__footer--total">
            <label for="total">{{ $t('SEARCH.TOTAL') }}:</label>
            <span id="total" data-test="search-dropdown-total">{{ totalHits }}</span>
          </div>
          <BaseButton @click="onShowAllResults" @keyup.enter="onShowAllResults">
            <span class="c-search-dropdown__footer--button-text">{{ $t('SEARCH.EXPAND') }}</span>
          </BaseButton>
        </div>
      </div>
      <Loader v-else />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import SearchDropdownItem from '@/components/search/dropdown/item/SearchDropdownItem.vue';
import BaseButton from '@/elements/button/BaseButton.vue';
import { CardInterface } from '@/model/Card';
import { onClickOutside } from '@vueuse/core';
import Loader from '@/elements/loader/BaseLoader.vue';

interface SearchDropdownProps {
  items: CardInterface[];
  isOpen?: boolean;
  total: number | null;
  loading?: boolean;
}

const props = defineProps<SearchDropdownProps>();

const emit = defineEmits(['onSelectedItem', 'onAllResults', 'closeDropdown']);

const target = ref<HTMLElement>();

const totalHits = computed(() => {
  return isNaN(props.total) ? 0 : props.total;
});

const onShowAllResults = () => {
  emit('onAllResults');
};

onClickOutside(target, () => emit('closeDropdown'));
</script>
