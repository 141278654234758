import {fetchSH} from '../api'

/* 
  Graphql Query example
  {
    query: `query queryContent($objectId: String!) {
      content(objectId: $objectId) {
        data {
          id
          namespace
          content
        }
        error {
          message
          status
        }
      }
    }`,
    variables: {
      "objectId": "example-id"
    }
  }
*/
const postGql = (namespace, query, endpoint = 'compositions') =>
  fetchSH(`${endpoint}/${namespace}/graphql`, {
    method: 'POST',
    body: JSON.stringify(query),
  })

export { postGql }
